import currency from '../helpers/currency'

const renderFull = (productsList, spei, paynet, total, name, email, streetNNumber, col, zip, city, state, orderId, method, shipping) => {
  return `
  <!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">

<head>
  <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Recibo de pago</title>
  <style>
    body {
      font-family: 'Open Sans' !important;
    }
  </style>
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap" rel="stylesheet">
</head>

<body>
  <table style="line-height:16px; font-size:16px; height:16px;
      border-collapse:collapse; border-spacing:0; border:0; width:100%;">
    <tbody>
      <tr>
        <td style="font-size: 0; line-height: 0;" height="40px">&nbsp;</td>
      </tr> <!-- Vertical Spacer-->
      <tr>
        <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
        <td colspan="2">
          <!-- Nota-->

        <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10">&nbsp;</td>
        <!-- Spacer  right-->
      </tr>
      <tr>
        <td style="font-size: 0; line-height: 0;padding-left: 10%;" height="10">&nbsp;</td><!-- Spacer  left-->
        <td colspan="2">
          <!-- LOGO y No ORDEN -->
          <table cellspacing="0" cellpadding="0" height="16px" width="100%" style="line-height:16px; font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;border-bottom: 1px solid #ddd;">
            <tbody>
              <tr>
                <td colspan="1" style="text-align: center; padding-top:20px; padding-bottom:15px;
                     vertical-align: center;">
                  <img height="120px"
                    src="https://firebasestorage.googleapis.com/v0/b/asadores-fd5ac.appspot.com/o/thumb%401100_descarga.png?alt=media&token=127f9b74-8d31-4792-9b98-17b2de7909a3"
                    alt="Logo ADN" />
                </td>
                ${method !== 'paypal' && method !== 'Paypal' ? `
                <td colspan="1" style="text-align: center; padding-top:20px; padding-bottom:15px; vertical-align: center;">
                <img height="70px"
                  src="https://firebasestorage.googleapis.com/v0/b/asadores-fd5ac.appspot.com/o/thumb%401100_logo-OP-BNa.png?alt=media"
                  alt="Logo IML" />
                </td>
                ` : ''}
              </tr>
              <tr>
                <td style="text-align: left;font-size: 20px; padding-bottom:40px; padding-top:20px;
                         vertical-align: center; color: grey;">
                  <b>Tu número de orden</b>
                </td>
                <td style="text-align: right;font-size: 30px;font-weight: bold; vertical-align: center; color:#000;padding-top:20px;
                     padding-bottom:40px">
                  <span>${orderId}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10">&nbsp;</td>
        <!-- Spacer  right-->
      </tr>
      <tr>
        <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
        <td colspan="2">
          <!-- Details -->
          <table cellspacing="0" cellpadding="0" height="16px" width="100%" style="line-height:16px; font-size:16px; border-collapse:collapse; border-spacing:0; border:0;;
                margin:0;">
            <tbody>
              <tr>
                <td style="text-align:left; color: #000; font-weight: 700; font-size: 20px;" width="100%"
                  height="50px; padding:0;">
                  <span>Detalle del pedido</span>
                </td>
              </tr>
              <tr>
                <td style="font-size: 0; line-height: 0;" height="10px">&nbsp;</td>
              </tr> <!-- Vertical Spacer-->
            </tbody>
          </table>
        </td>
        <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10">&nbsp;</td>
        <!-- Spacer  right-->
      </tr>
      <tr>
        <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
        <td colspan="2">
          <!-- products table-->
          <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
               font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
            <tbody>
              <tr style="text-align: center;">

                <td height="200px" style="background-color: #ededed;
                   border-radius: 15px;">
                  <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
                     font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
                    <tbody>
                   ${productsList}
            </tbody>
          </table>
        </td>

      </tr>
      <tr>
        <td style="font-size: 0; line-height: 0;" height="10px">&nbsp;</td>
      </tr> <!-- Vertical Spacer-->
    </tbody>
  </table>
  </td>
  <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10">&nbsp;</td>
  <!-- Spacer  right-->
  </tr>
  <tr>
    <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
    <td colspan="2">
    <!-- shipping -->
    <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
             font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
      <tbody>
        <tr style="text-align: center;">

          <td height="60px" style="background-color:#ededed;
                 border-radius: 15px;">
            <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
                   font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
              <tbody>
                <tr>
                  <td
                    style="text-align: left;padding-left: 60px; padding-top: 15px;padding-bottom: 15px; color: #000; font-weight: lighter; vertical-align: middle;">
                      Envío
                    </td>
                  <td
                    style="font-size: 20px; text-align: center;padding-right: 15px; padding-top: 15px; padding-bottom: 15px;color: #000; font-weight: bold;vertical-align: middle;">
                    $${currency.formatMoney(shipping)}</td>
                </tr>
              </tbody>
            </table>
          </td>

        </tr>
      </tbody>
    </table>
    <br />
    <br />
    </td>

    <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10px">&nbsp;</td>
    <!-- Spacer  right-->
  </tr>
  <tr>
    <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
    <td colspan="2">
      <!-- total a pagar-->
      <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
               font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
        <tbody>
          <tr style="text-align: center;">

            <td height="60px" style="background-color:grey;
                   border-radius: 15px;">
              <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
                     font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
                <tbody>
                  <tr>
                    <td
                      style="text-align: left;padding-left: 60px; padding-top: 15px;padding-bottom: 15px; color: #fff; font-weight: lighter; vertical-align: middle;">
                        ${method === 'spei' || method === 'cash' ? 'Total a pagar' : 'Total'}
                      </td>
                    <td
                      style="font-size: 20px; text-align: center;padding-right: 15px; padding-top: 15px; padding-bottom: 15px;color: #fff; font-weight: bold;vertical-align: middle;">
                      $${currency.formatMoney(total)}</td>
                  </tr>
                </tbody>
              </table>
            </td>

          </tr>
        </tbody>
      </table>
    </td>

    <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10">&nbsp;</td>
    <!-- Spacer  right-->
  </tr>
  <tr>
    <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
    <td colspan="2">
      <!-- Details -->
      <table cellspacing="0" cellpadding="0" height="16px" width="100%" style="line-height:16px; font-size:16px; border-collapse:collapse; border-spacing:0; border:0;;
                margin:0;">
        <tbody>
          <tr>
            <td style="text-align:left; color: #000; font-weight: 700; font-size: 20px;" width="100%"
              height="50px; padding:0;">
              <span>Datos de la cuenta</span>
            </td>
          </tr>
          <tr>
            <td style="font-size: 0; line-height: 0;" height="10px">&nbsp;</td>
          </tr> <!-- Vertical Spacer-->
        </tbody>
      </table>
    </td>
    <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10">&nbsp;</td>
    <!-- Spacer  right-->
  </tr>

  <tr>
    <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
    <td colspan="2">
      ${spei}
    </td>
    <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10">&nbsp;</td>
    <!-- Spacer  right-->
  </tr>
  <tr>
    <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
    <td colspan="2">
      <!--oxxo transfer table-->
      ${paynet}
    <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10">&nbsp;</td>
    <!-- Spacer  right-->
  </tr>
  <tr>
    <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
    <td colspan="2">
      <!-- Details -->
      <table cellspacing="0" cellpadding="0" height="16px" width="100%" style="line-height:16px; font-size:16px; border-collapse:collapse; border-spacing:0; border:0;;
                margin:0;">
        <tbody>
          <tr>
            <td style="text-align:left; color: #000; font-weight: 700; font-size: 20px;" width="100%"
              height="50px; padding:0;">
              <span>Tus datos</span>
            </td>
          </tr>
          <tr>
            <td style="font-size: 0; line-height: 0;" height="10px">&nbsp;</td>
          </tr> <!-- Vertical Spacer-->
        </tbody>
      </table>
    </td>
    <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10">&nbsp;</td>
    <!-- Spacer  right-->
  </tr>
  <tr>
    <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
    <td colspan="2">
      <!-- username table-->
      <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
               font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
        <tbody>
          <tr style="text-align: center;">

            <td height="70px" style="background-color: #ededed;
                   border-radius: 15px;">
              <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
                     font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
                <tbody>
                  <tr>
                    <td width="98px"
                      style="text-align: right;  padding-left: 40px; color: grey; font-weight: bold; vertical-align: middle;">
                      Nombre: </td>
                    <td
                      style="text-align: left;padding-left: 20px;color: #000; font-weight:600;vertical-align: middle;">
                      ${name}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style="font-size: 0; line-height: 0;" height="10px">&nbsp;</td>
          </tr> <!-- Vertical Spacer-->
        </tbody>
      </table>
    </td>
    <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10">&nbsp;</td>
    <!-- Spacer  right-->
  </tr>
  <tr>
    <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
    <td colspan="2">
      <!-- usermail table-->
      <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
               font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
        <tbody>
          <tr style="text-align: center;">

            <td height="70px" style="background-color: #ededed;
                   border-radius: 15px;">
              <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
                     font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
                <tbody>
                  <tr>
                    <td width="98px"
                      style="text-align: right;  padding-left: 40px; color: grey; font-weight: bold; vertical-align: middle;">
                      Email: </td>
                    <td
                      style="text-align: left;padding-left: 20px;color: #000; font-weight:600;vertical-align: middle;">
                      ${email}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style="font-size: 0; line-height: 0;" height="10px">&nbsp;</td>
          </tr> <!-- Vertical Spacer-->
        </tbody>
      </table>
    </td>
    <td style="font-size: 0; line-height: 0;padding-right: 20%;" height="10">&nbsp;</td>
    <!-- Spacer  right-->
  </tr>

  <tr>
    <td style="font-size: 0; line-height: 0;padding-left: 20%;" height="10">&nbsp;</td><!-- Spacer  left-->
    <td colspan="2">
      <!-- address table-->
      <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
               font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
        <tbody>
          <tr style="text-align: center;">

            <td height="70px" style="background-color: #ededed;
                   border-radius: 15px;">
              <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
                     font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
                <tbody>
                  <tr>
                    <td width="98px"
                      style="text-align: right;  padding-left: 40px; color: grey; font-weight: bold; vertical-align: middle;">
                      Dirección: </td>
                    <td
                      style="text-align: left;padding-left: 20px;color: #000; font-weight:600;vertical-align: middle;">
                      ${streetNNumber}, Colonia ${col}, C.P. ${zip},
                      ${city}, ${state}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style="font-size: 0; line-height: 0;" height="10px">&nbsp;</td>
          </tr> <!-- Vertical Spacer-->
        </tbody>
      </table>
    </td>
    <td style="font-size: 0; line-height: 0;padding-right: 10%;" height="10">&nbsp;</td>
    <!-- Spacer  right-->
  </tr>
  </tbody>
  </table>
</body>

</html>
  `
}

export default renderFull
