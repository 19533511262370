/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react'
import { getList } from '../modules/shipping'

const useShippingCost = () => {
  const [list, setList] = useState(null)

  const handleFetch = async () => {
    const data = await getList()
    setList(data)
  }

  useEffect(() => {
    handleFetch()
  }, [])

  // specific

  return (postalCode = '', isSmall = false) => {
    if (!list || !list.length) return false

    // calculate specific cp
    const matchedSpecificCp = list.filter(oneRegister => oneRegister.cp == postalCode)[0]
    if (matchedSpecificCp) {
      console.log('matchedSpecificCp')
      const costParsed = parseInt(matchedSpecificCp.cost)
      const smallCostParsed = parseInt(matchedSpecificCp.smallCost)
      console.log('smallCostParsed', smallCostParsed)
      if (isSmall && !isNaN(smallCostParsed)) return !isNaN(smallCostParsed) ? smallCostParsed : 0
      return !isNaN(costParsed) ? costParsed : 0
    }

    // // calculate cost based on state
    // const matchedState = list.filter(oneRegister => {
    //   const codeState = oneRegister.cp.toString().slice(0, 2)
    //   const userCodeState = postalCode.toString().slice(0, 2)
    //   return codeState == userCodeState
    // })[0]

    // if (matchedState) {
    //   console.log(matchedState)
    //   const costParsed = parseInt(matchedState.cost)
    //   const smallCostParsed = parseInt(matchedState.smallCost)
    //   if (isSmall && !isNaN(smallCostParsed)) return !isNaN(smallCostParsed) ? smallCostParsed : 0
    //   return !isNaN(costParsed) ? costParsed : 0
    // }

    return false
  }
}

export default useShippingCost
