import { db } from './firebase'
import filterObject from '../helpers/filterobject'
import response from '../helpers/response'

const collectionRef = db.collection('dealers')

const mekeSecureData = (data) => {
  const allows = ['username', 'place', 'phone', 'email', 'priority']
  const secureData = filterObject(data, allows)
  if (secureData.priority) secureData.priority = Number.parseFloat(secureData.priority)
  return secureData
}

const createDealer = async (data) => {
  try {
    const secureData = mekeSecureData(data)
    const ref = await collectionRef.add(secureData)
    return response.success('Distribuidor creado correctamente', { id: ref.id })
  } catch (error) {
    console.error(error)
    return response.error('error interno del servidor')
  }
}

const updateDealer = async (id, newData) => {
  try {
    const secureData = mekeSecureData(newData)
    await collectionRef.doc(id).update(secureData)
    return response.success('Distribuidor actualizado correctamente')
  } catch (error) {
    console.error(error)
    return response.error('error interno del servidor')
  }
}

const deleteDealer = async (id) => {
  try {
    await collectionRef.doc(id).delete()
    return response.success('distribuidor eliminado correctamente')
  } catch (error) {
    console.error(error)
    return response.error()
  }
}
const getAllDealers = async () => {
  try {
    const querySnapshot = await collectionRef.orderBy('priority', 'desc').get()
    const data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
    return data
  } catch (error) {
    console.error(error)
    return []
  }
}

const getOneDealer = async (id) => {
  try {
    const snapshot = await collectionRef.doc(id).get()
    return {
      ...snapshot.data(),
      id: snapshot.id
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

const dealers = {
  create: createDealer,
  update: updateDealer,
  delete: deleteDealer,
  getAll: getAllDealers,
  getOne: getOneDealer,
  mekeSecureData
}

export default dealers
