import React, { useState } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { Container, Title, ListContainer, CardAddress, DatatextVideo, SubTituloAddes } from './styles'
import useFetch from '../../../hooks/useFetch'
import dealers from '../../../modules/dealers'
import formatPhone from '../../../helpers/formatPhone'

const Dealers = () => {
  const [items, setItems] = useState(null)
  const isMobile = useMediaQuery('(max-width:959px)')

  // fetch items
  useFetch(async () => {
    const responseItems = await dealers.getAll()
    if (responseItems && responseItems.length) setItems(responseItems)
  })

  if (!items || !items.length) return null

  return (
    <Container>
      <Box pt={{ xs: 1, md: 1, lg: 5 }} />
      <Title style={{ textAlign: isMobile ? 'center' : 'left', marginBottom: isMobile ? '20px' : '80px' }}>DISTRIBUIDORES AUTORIZADOS</Title>
      <Box pt={{ xs: 2, md: 3, lg: 4 }} />
      <ListContainer style={{ flexWrap: 'wrap' }}>
        {items.map((currentItem, index) => (
          <CardAddress style={{ marginBottom: isMobile ? '25px' : '50px' }} key={index}>
            <SubTituloAddes>{currentItem.username}</SubTituloAddes>
            <DatatextVideo style={{ padding: '0px' }}>
              Distribuidor {currentItem.place}
            </DatatextVideo>
            <DatatextVideo style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
              T. {formatPhone(currentItem.phone)} <br />
              M. {currentItem.email}
            </DatatextVideo>
          </CardAddress>
        ))}
        <CardAddress />
      </ListContainer>
    </Container>
  )
}

export default Dealers
