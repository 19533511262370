import React, { useState } from 'react'
import View from './view'
import useFetch from '../../../hooks/useFetch'
import pointOfSale from '../../../modules/pointsOfSale'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../../flux/alert'
import { setNotification } from '../../../flux/notification'

const PointOfSale = () => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [editorOpen, setEditorOpen] = useState(false)
  const [itemEditing, setItemEditing] = useState(null)
  const dispatch = useDispatch()

  const handleFetch = async () => {
    setLoading(true)
    const response = await pointOfSale.getAll()
    setLoading(false)
    setItems(response)
  }

  const handleEditorClose = () => {
    setEditorOpen(false)
    setItemEditing(null)
  }

  const handleEditItem = (id) => {
    setItemEditing(id)
    setEditorOpen(true)
  }

  const handleDeleteItem = (id) => {
    dispatch(setAlert({
      title: '¿Seguro que quieres borrar este punto de venta?',
      message: 'Una vez realizada esta acción  no podras recurar la información',
      action: async () => {
        const response = await pointOfSale.delete(id)
        dispatch(setNotification(response))
        handleFetch()
      },
      textAction: 'Eliminar'
    }))
  }

  useFetch(handleFetch)

  return (
    <View
      items={items}
      loading={loading}
      editorOpen={editorOpen}
      itemEditing={itemEditing}
      onEditorClose={handleEditorClose}
      onEditorSuccess={handleFetch}
      onEditItem={handleEditItem}
      onDeleteItem={handleDeleteItem}
      onCreate={() => setEditorOpen(true)}
    />
  )
}

export default PointOfSale
