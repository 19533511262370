const paynet = (barcode, urlbarcode) => {
  return `
  <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
  font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
<tbody>
 <tr style="text-align: center;">

   <td height="200px" style="background-color: #ededed;
      border-radius: 15px;">
     <table cellspacing="0" cellpadding="0" width="100%"
       style="line-height:16px;
        font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
       <tbody>
         <tr>
           <td colspan="2" width="98px"
             style="text-align: center;padding-top: 20px; padding-bottom: 30px;  color: #000; font-weight: bolder; vertical-align: middle;">
             Pago en tiendas de autoservicio </td>
         </tr>
         <tr>
           <td width="98px"
             style="text-align: right;padding-bottom: 10px;  padding-left: 40px; color: grey; font-weight: bold; vertical-align: middle;">
             Servicio a pagar: </td>
           <td
             style="text-align: left;padding-bottom: 10px;padding-left: 20px;color: #000; font-weight:600;vertical-align: middle;">
             Paynet</td>
         </tr>
         <tr>
           <td width="98px"
             style="text-align: right;padding-bottom: 10px; padding-top: 20px; padding-left: 40px;  color: grey; font-weight: bold; vertical-align: middle;">
             Codigo: </td>
           <td
             style="text-align: left;padding-bottom: 10px;padding-left: 20px;padding-top: 20px;color: #000; font-weight:600;vertical-align: middle;">
             ${barcode}</td>
         </tr>
         <tr>
           <td colspan="2" style="text-align: center; padding-top:20px; padding-bottom:15px;
                vertical-align: center;">
             <img height="120px"
               src="${urlbarcode}"
               alt="barcode" />
           </td>
         </tr>
         <tr>
           <td style="font-size: 0; line-height: 0;" height="60px">&nbsp;</td>
         </tr> <!-- Vertical Spacer-->
       </tbody>
     </table>
   </td>
 </tr>
 <tr>
   <td style="font-size: 0; line-height: 0;" height="10px">&nbsp;</td>
 </tr> <!-- Vertical Spacer-->
</tbody>
</table>
</td>
  `
}

export default paynet
