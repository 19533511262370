import styled from 'styled-components'
import InputBase from '../../../../../components/inputs/GroupInput'
import ButtonBase from '../../../../../components/inputs/Button'

export const Button = styled(ButtonBase)`
  width: 100%;
`
export const SelectStyled = styled(InputBase)`
  margin-right: 0px;
  background: #fff;
  border-radius: 5px;
`
export const Row = styled.div`
  margin-bottom: 15px;
`
export const Form = styled.form`
  margin: auto;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
`
export const Input = styled(InputBase)`
  background: #fff;
  border-radius: 5px;
`
export const Flex = styled.div`
  display: flex;
  justify-content: center;
`
export const FlexItem = styled.div`
  width: 33.3%;
`
export const Container = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 50px 20px;
  @media screen and (max-width:1300px) {
    font-size: .8em;
  }
`
