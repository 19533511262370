import React from 'react'
import LayoutUser from '../../components/layout_user'
import { CircularProgress, Grid, Box, Divider } from '@material-ui/core'
import styled from 'styled-components'
import Empty from '../../components/empty'
import useHeaderheight from '../../../hooks/useHeaderheight'
import BackBread from '../../components/backdream'
import Menu from './menu'
import Skeleton from './skeleton'
import Item from '../../components/item'
import { categories } from '../../../constants'
import Footer from '../../components/footer'
import Footerinfo from '../../components/footerInfo'
import { array, bool, string } from 'prop-types'

const View = (props) => {
  const top = useHeaderheight()

  return (
    <LayoutUser>
      <div name='wrapper' style={{ marginTop: top }}>
        <BackBread
          steps={[
            { label: 'Home', to: '/' },
            { label: props.category ? categories[props.category] : 'Todos' }
          ]}
        />
        <Container style={{ justifyContent: 'flex-end', marginTop: '45px' }}>
          <Header>
            {props.category ? categories[props.category] : 'TODOS'}
          </Header>
        </Container>
        <Container>
          <MenuContainer>
            <Menu currentCategory={props.category} />
          </MenuContainer>
          <Body>
            {!props.items.length && props.loading && (
              <Skeleton />
            )}
            {!!props.items.length && (
              <Grid container spacing={3}>
                {props.items.map(item => (
                  <Grid key={item.id} item xs={12} sm={6} lg={4}>
                    <Item {...item} />
                  </Grid>
                ))}
                {props.loading && (
                  <Grid item xs={12} container justify='center'>
                    <Box p={5}>
                      <CircularProgress />
                    </Box>
                  </Grid>
                )}
              </Grid>
            )}
            {!props.items.length && !props.loading && (
              <Empty
                message='Aun no hay publicaciones'
              />
            )}
            <Box pt={5} pb={5}>
              <Divider />
            </Box>
            <Footerinfo />
          </Body>
        </Container>
      </div>
      <Footer />
    </LayoutUser>
  )
}

View.propTypes = {
  category: string.isRequired,
  items: array.isRequired,
  loading: bool.isRequired
}

const Container = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  @media screen and (max-width:1600px) {
    font-size: .9em;
  }
  @media screen and (max-width:1350px) {
    font-size: .8em;
  }
  @media screen and (max-width:1000px) {
    flex-direction: column;
  }
`
const MenuContainer = styled.div`
  width: 30%;
  @media screen and (max-width:1500px) {
    width: 25%;
  }
  @media screen and (max-width:1000px) {
    width: 100%;
  }
`
const Body = styled.div`
  width: 70%;
  @media screen and (max-width:1500px) {
    width: 75%;
  }
  @media screen and (max-width:1000px) {
    width: 100%;
  }
`
const Header = styled.div`
  width: 70%;
  font-family: 'bebas';
  font-size: 7em;
  @media screen and (max-width:1500px) {
    width: 75%;
  }
  @media screen and (max-width:1000px) {
    width: 100%;
    text-align: center;
    font-size: 58px;
  }
  @media screen and (max-width:600px) {
    width: 100%;
    text-align: center;
    font-size: 50px;
  }
`

export default View
