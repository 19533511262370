import { func, oneOfType, element, number, bool, string, array } from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({ className, children, onSelect, multiple, accept }) => {
  const handleDrop = (filesAcepted) => {
    if (multiple && !!filesAcepted && !!filesAcepted.length) onSelect(filesAcepted);
    else if (!multiple && !!filesAcepted && !!filesAcepted.length) {
      onSelect(filesAcepted[0]);
    }
  };

  const { getInputProps, getRootProps } = useDropzone({
    accept,
    onDrop: handleDrop,
    multiple,
  });

  return (
    <div {...getRootProps()} className={className}>
      {children}
      <input {...getInputProps()} />
    </div>
  );
};

Dropzone.defaultProps = {
  className: '',
  multiple: false,
  children: '',
  accept: 'image/jpeg, image/png',
};

Dropzone.propTypes = {
  className: string,
  multiple: bool,
  children: oneOfType([string, element, number]),
  onSelect: func.isRequired,
  accept: oneOfType([string, array]),
};

export default Dropzone;
