import React, { useState } from 'react'
import ViewDiscountCodes from './view'
import discounts from '../../../modules/discounts'
import useFetch from '../../../hooks/useFetch'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../../flux/alert'
import { setNotification } from '../../../flux/notification'
import Creator from './creator'

const DiscountCodes = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [creatorOpen, setCreatorOpen] = useState(false)

  const handleFetch = async () => {
    setLoading(true)
    const fetchedItems = await discounts.getAll()
    setItems(fetchedItems)
    setLoading(false)
  }

  const handleDelete = async (id) => {
    dispatch(setAlert({
      title: '¿Seguro quieres eliminar este código?',
      action: async () => {
        const response = await discounts.delete(id)
        dispatch(setNotification(response))
        handleFetch()
      }
    }))
  }

  useFetch(handleFetch)

  return (
    <>
      {creatorOpen && (
        <Creator
          onClose={() => setCreatorOpen(false)}
          onCreated={handleFetch}
        />
      )}
      <ViewDiscountCodes
        loading={loading}
        items={items}
        onDelete={handleDelete}
        onCreate={() => setCreatorOpen(true)}
      />
    </>
  )
}

export default DiscountCodes
