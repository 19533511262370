/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import Picture from '../../../components/picture'
import { Text } from './main'
import currency from '../../../helpers/currency'

const Content = styled.div`
  display: flex;
  align-items: center;
`

const PictureContainer = styled.div`
  width: 120px;
  position: relative;
  margin-right: 15px;
  @media screen and (max-width:800px) {
    width: 80px;
  }
`
const Counter = styled.div`
  background: #000;
  color: #fff;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -10px;
  top: -10px;
`
const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`
const Item = props => {
  return (
    <Content>
      <PictureContainer>
        <Counter>
          {props.quantity}
        </Counter>
        <Picture src={props.picture} />
      </PictureContainer>
      <DataContainer>
        <Text>{props.title}</Text>
        <Text>$ {currency.formatMoney(props.price)}</Text>
      </DataContainer>
    </Content>
  )
}

export default Item
