/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import Layout from '../../components/layout_user'
import CoverWithSteps from './cover'
import Categories from './categories'
import Contact from './contact'
import VideoSection from './videoSection'
import PointsOfSale from './pointSales'
import Dealers from './delaers'
import Social from './social'

function Home () {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout headerColorWhite>
      <CoverWithSteps />
      <Categories />
      <VideoSection />
      <Social />
      <PointsOfSale />
      <Dealers />
      <Contact />
    </Layout>
  )
}

export default Home
