import React from 'react'
import styled from 'styled-components'
import Picture from './picture'
import propTypes from 'prop-types'
import { Divider, Box, Grid, TextField, useMediaQuery } from '@material-ui/core'
import ButtonBase from '../../../components/link'
import InputQuiantity from '../../components/input_quantity'
import currency from '../../../helpers/currency'
import PictureResponsive from '../../../components/picture'

const Data = props => {
  const IsMobile = useMediaQuery('(max-width:700px)')

  return (
    <ItemContent>
      <PictureBox>
        <TitleMobileContainer>
          <Title style={{ textAlign: 'center' }}>{props.title}</Title>
        </TitleMobileContainer>
        <Picture
          pictures={[props.picture, ...props.pictures]}
          videos={props.videos || []}
        />
      </PictureBox>
      <DataBox>
        <Header>
          <Title>{props.title}</Title>
        </Header>
        <Divider style={{ backgroundColor: '#000' }} />
        <Description>
          <SubTitle>Descripción</SubTitle>
          <div dangerouslySetInnerHTML={{ __html: props.description }} />
        </Description>
        <Divider style={{ backgroundColor: '#000' }} />

        {(props.medium_price && props.big_price && !props.price) && (
          <Box pt={2} pb={2}>
            <SubTitle>TAMAÑO</SubTitle>
            <Grid container justify='flex-start'>
              <BoxSizeStyled onClick={event => props.setSize('medium')} style={{ marginRight: '20px' }} $selected={props.size === 'medium'}>
                <div className='size'>Mediano</div>
                <div className='price'>${currency.formatMoney(props.medium_price)}</div>
                {props.category === 'steakhouses' && (
                  <>
                    <div className='med'>Asador: {props.medium_rotisserie}</div>
                    <div className='med'>Parrilla: {props.medium_grill}</div>
                  </>
                )}
              </BoxSizeStyled>
              <BoxSizeStyled $selected={props.size === 'big'} onClick={event => props.setSize('big')}>
                <div className='size'>Grande</div>
                <div className='price'>${currency.formatMoney(props.big_price)}</div>
                {props.category === 'steakhouses' && (
                  <>
                    <div className='med'>Asador: {props.big_rotisserie}</div>
                    <div className='med'>Parrilla: {props.big_grill}</div>
                  </>
                )}
              </BoxSizeStyled>
            </Grid>
          </Box>
        )}

        {!!props.price && !props.big_price && !props.medium_price && (
          <Box pt={2} pb={2}>
            <SubTitle>PRECIO</SubTitle>
            <Grid container justify='flex-start'>
              <BoxSizeStyled $selected>
                <div className='price'>${currency.formatMoney(props.price)}</div>
                {props.category === 'steakhouses' && (
                  <>
                    <div className='med'>Asador: {props.rotisserie}</div>
                    <div className='med'>Parrilla: {props.grill}</div>
                  </>
                )}
              </BoxSizeStyled>
            </Grid>
          </Box>
        )}

        {props.category === 'steakhouses' && props.colors && props.colors.length && (
          <>
            <Divider style={{ backgroundColor: '#000' }} />
            <Box pt={2} pb={2}>
              <SubTitle>COLOR</SubTitle>
              <Grid container justify='flex-start' spacing={IsMobile ? 2 : 4}>
                {props.colors.map((color, index) => (
                  <Grid item xs={3} key={index} style={{ maxWidth: '100px' }}>
                    <PictureResponsiveStyled src={color.picture} $selected={JSON.stringify(color) === JSON.stringify(props.color)} onClick={event => props.onSelectColor(color)} />
                    <ColorName>{color.name}</ColorName>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}
        {props.category === 'steakhouses' && props.availableSignature && (
          <>
            <Divider style={{ backgroundColor: '#000' }} />
            <Box pt={2} pb={2}>
              <SubTitle>GRABADO PERSONALIZADO</SubTitle>
              <Box pt={2}>
                <TextField value={props.firm} onChange={event => props.setFirm(event.target.value)} placeholder='Familia Garcia' variant='outlined' color='primary' focused fullWidth />
              </Box>
            </Box>
          </>
        )}
        <Divider style={{ backgroundColor: '#000' }} />
        <Box pt={2} pb={2}>
          <FlexBetween>
            <SubTitle>CANTIDAD</SubTitle>
            <InputQuiantity
              style={{ fontSize: IsMobile ? '23px' : '40px', border: '2px  solid #000' }}
              onQuantityChange={props.onQuantityChange}
              quantity={props.quantity}
              setQuantity={props.setQuantity}
            />
          </FlexBetween>
        </Box>
        <ButtonContainer>
          <ButtonSecondary to='/productos' variant='contained'>Seguir comprando</ButtonSecondary>
          <ButtonPrimary handleClick={props.AddToCart} variant='other'>Agregar al carrito</ButtonPrimary>
        </ButtonContainer>
      </DataBox>
    </ItemContent>
  )
}
Data.propTypes = {
  picture: propTypes.string,
  pictures: propTypes.array,
  description: propTypes.string,
  title: propTypes.string,
  AddToCart: propTypes.func,
  onQuantityChange: propTypes.func,
  quantity: propTypes.oneOfType([propTypes.number, propTypes.string]),
  setQuantity: propTypes.func
}

const PictureResponsiveStyled = styled(PictureResponsive)`
  ${props => props.$selected ? 'border: 2px solid #000' : ''};
`

const ColorName = styled.div`
  text-align: center;
  font-family: 'bebas';
  font-size: 22px;
  @media screen and (max-width:600px) {
    font-size: 18px;
  }
`

const TitleMobileContainer = styled.div`
  display: none;
  @media screen and (max-width:1000px) {
    display: block;
  }
`

const BoxSizeStyled = styled.div`
  
  cursor: pointer;
  line-height: initial;
  font-family: 'bebas';
  padding: 15px;
  border: 2px solid #000;
  color: ${props => props.$selected ? '#fff' : '#000'};
  background-color: ${props => props.$selected ? '#000' : '#fff'};
  text-align: center;
  .size {
    font-size: 22px;
    @media screen and (max-width:1500px) {
      font-size: 18px;
    }
    @media screen and (max-width:700px) {
      font-size: 14px;
    }
  }
  .price {
    font-size: 49px;
    @media screen and (max-width:1500px) {
      font-size: 30px;
    }
    @media screen and (max-width:700px) {
      font-size: 33px;
    }
  }
  .med {
    font-size: 22px;
    @media screen and (max-width:1500px) {
      font-size: 18px;
    }
    @media screen and (max-width:700px) {
      font-size: 14px;
    }
  }
  @media screen and (max-width:700px) {
    padding: 5px;
  }
`

const SubTitle = styled.div`
  font-size: 35px;
  font-family: 'bebas';
  @media screen and (max-width:1500px) {
    font-size: 25px;
  }
  
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  @media screen and (max-width:700px) {
    flex-direction: column;
    align-items: flex-end
  }
`
const Button = styled(ButtonBase)`
  width: 48%;
  font-size: 35px;
  font-family: 'bebas';
  padding: 15px 0px;
  color: ${props => props.variant === 'contained' ? '#fff' : '#000'};
  background: ${props => props.variant === 'contained' ? '#000' : 'var(--user-black-light)'};
  @media screen and (max-width:1500px) {
    font-size: 25px;
  }
  @media screen and (max-width:700px) {
    width: 70%;
    max-width: 300px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 27px;
  }
`
const ItemContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  margin-top: 90px;
  @media screen and (max-width:1700px) {
    margin-top: 80px;
  }
  @media screen and (max-width:1500px) {
    margin-top: 70px;
  }
  @media screen and (max-width:1200px) {
    width: 95%;
    margin-top: 50px;
  }
  @media screen and (max-width:1000px) {
    width: 80%;
    margin-top: 30px;
  }
`
const PictureBox = styled.div`
  width: 55%;
  @media screen and (max-width:1000px) {
    width: 100%;
    padding: 0px;
  }
`
const DataBox = styled.div`
  width: 40%;
  @media screen and (max-width:1000px) {
    width: 100%;
    padding-top: 25px;
  }
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width:1000px) {
    display: none
  }
`
const Title = styled.h1`
  font-size: 106px;
  font-family: 'bebas';
  line-height: 1em;
  margin: 0px;
  @media screen and (max-width:1500px) {
    font-size: 80px;
  }
  @media screen and (max-width:1000px) {
    font-size: 58px;
  }
`
const Description = styled.div`
  font-size: 1.2em;
  padding: 10px 0px;
`
const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ButtonSecondary = styled(Button)`
  background: #fff!important;
  border: 2px solid #000;
  color: #000;
  transition: all 200ms linear;
  :hover {
    background: #000!important;
    color: #fff;
  }
`
const ButtonPrimary = styled(Button)`
  background: #000!important;
  border: 2px solid #000;
  color: #fff;
  transition: all 200ms linear;
  :hover {
    background: var(--user-black-light)!important;
    color: #fff;
    border-color: var(--user-black-light);
  }
`

export default Data
