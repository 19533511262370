const filterdata = (data, allows) => {
  const dataAllowed = {}
  const keynames = Object.keys(data)
  keynames.map(keyname => {
    if (allows.includes(keyname)) {
      dataAllowed[keyname] = data[keyname]
    }
  })
  return dataAllowed
}

export default filterdata
