import React from 'react'
import styled from 'styled-components'
import { string, number, oneOfType, object, bool } from 'prop-types'
import CurrencyParser from '../../../helpers/currency'
import { sections } from '../../../constants'

const Item = props => {
  return (
    <Content $padding={props.category === 'buildyourgrill'}>
      <Picture src={props.picture} />
      <Data>
        <Title>{props.title}</Title>
        {props.firm && (
          <Row>
            <span>Grabado: </span>
            {props.firm}
          </Row>
        )}
        {(!!props.size) && (
          <Row>
            <span>Tamaño: </span>
            {props.size === 'medium' ? 'Mediano' : 'Grande'}
          </Row>
        )}
        {props.color && (
          <Row>
            <span>Color: </span>
            {typeof props.color === 'string' ? props.color : props.color.title || props.color.name}
          </Row>
        )}
        <Row>
          <span>Cantidad: </span>
          {props.quantity}
        </Row>
        {props.category === 'buildyourgrill' && (
          <>
            {Object.keys(sections).map(keyname => {
              if (!props[keyname]) return null
              return (
                <Row key={keyname}>
                  <span>{sections[keyname]}:</span>
                  {props[keyname].title}
                </Row>
              )
            })}
          </>
        )}
        {props.category === 'buildyourgrill' && (
          <Row>
            <span>Precio total:</span>
            $ {CurrencyParser.formatMoney(props.price)}
          </Row>
        )}
        {props.category !== 'buildyourgrill' && (
          <Row>
            <span>Precio:</span>
            $ {CurrencyParser.formatMoney(props.price)}
          </Row>
        )}
      </Data>
    </Content>
  )
}

Item.propTypes = {
  picture: string,
  price: oneOfType([string, number]),
  quantity: number,
  title: string,
  firm: string,
  color: oneOfType([string, object]),
  size: string,
  category: string,
  multiplePrice: bool
}

const Content = styled('div')`
  margin: auto;
  margin-bottom: 10px;
  box-shadow: 2px 2px 5px #0e3f6782;
  display: flex;
  font-size: 1.1em;
  align-items: center;
  padding: ${props => props.$padding ? '10px' : '0px'};
`
const Picture = styled.img`
  width: 200px;
  height: 150px;
  min-width: 200px;
  min-height: 150px;
  object-fit: cover;
  @media screen  and (max-width:500px){
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
`
const Data = styled.div`
  width: 100%;
  padding: 10px;
`
const Title = styled.h4`
  color: var(--main-blue);
  margin: 0px;
  margin-bottom: 10px;
`

const Row = styled.div`
  color: var(--main-blue-dark);
  font-weight: bold;
  & span {
    color: #000;
    margin-right: 10px;
    font-weight: normal;
  }
`
export default Item
