import React, { useEffect, useMemo, useState } from 'react'
import useObjectState from '../../../hooks/useObjectState'
import View from './view'
import articlesModule from '../../../modules/article'
import { getList as getSizes } from '../../../modules/sizes'
import { sections } from '../../../constants'
import { getList as getColors } from '../../../modules/colors'
import { useDispatch, useSelector } from 'react-redux'
import { addItem, RemoveItem } from '../../../flux/cart'
import areEqual from '../../../helpers/areEqual'
import iconSrc from '../../../assets/3.png'
import { setNotification } from '../../../flux/notification'
import response from '../../../helpers/response'
import { useLocation } from 'react-router-dom'

const BuildYourGrill = (props) => {
  const [state, setState] = useObjectState({ loading: true, items: [], sizes: [], accesories: [] })
  const [data, setData] = useObjectState({})
  const itemsOnCart = useSelector(state => state.cart)
  const dispatch = useDispatch()
  const location = useLocation()
  const [editing, setEditing] = useState(location.state && location.state.payload)
  const [currentStep, setStep] = useState(1);

  const getBuild = useMemo(() => articlesModule.getListPaginated(1000, { category: 'buildyourgrill' }), [])
  const getAccesories = useMemo(() => articlesModule.getListPaginated(1000, { category: 'accesories' }), [])

  const handleFetch = async () => {
    setState({ loading: true })
    let buildParts = await getBuild()
    buildParts = buildParts.filter(item => !!item.isActive)
    let accesories = await getAccesories()
    accesories = accesories.filter(accesorie => accesorie.isActive && accesorie.doShowAtBuild)
    const sizes = await getSizes()
    const colors = await getColors()

    setState({ loading: false, items: buildParts || [], sizes: sizes || [], accesories: accesories || [], colors: colors || [] })
  }

  // fetch initial data
  useEffect(() => {
    window.scrollTo(0, 0)
    handleFetch(true)
  }, [])

  const handleSelecteSize = value => {
    setData({ size: value })
  }

  const handleSelectSection = (section, value) => {
    setData({ [section]: value })
  }

  // calculate initialSections
  useEffect(() => {
    const procesInitialState = () => {
      const initialState = {}
      if (state.sizes.length) initialState.size = state.sizes[0].keyname
      if (state.colors.length) initialState.color = state.colors[0].id
      Object.keys(sections).forEach(section => {
        if (state.items.filter(i => i.section === section)[0]) {
          const val = state.items.filter(i => i.section === section)[0].id
          initialState[section] = val
        }
      })
      setData(initialState)
    }

    if (!state.loading) {
      if (editing) {
        try {
          const initialState = {}
          if (state.sizes.length) initialState.size = location.state.payload.size
          if (state.colors.length) initialState.color = location.state.payload.color.id
          initialState.firm = !!location.state.payload.firm
          initialState.firmText = location.state.payload.firm || ''
          initialState.doorDesign = location.state.payload.doorDesign.id
          initialState.grillType = location.state.payload.grillType.id
          initialState.sideTables = location.state.payload.sideTables.id
          initialState.tiresType = location.state.payload.tiresType.id
          initialState.interiorType = location.state.payload.interiorType.id
          setData(initialState)
        } catch (error) {
          procesInitialState()
        }
      } else {
        procesInitialState()
      }
    }
  }, [state.loading])

  // handle selecte accesories
  const handleAccesorieSelect = item => {
    const dataParsed = { 
      ...item, 
      quantity: 1,
      size: data.size,
      price: data.size === 'big' && item.multiplePrice ? item.big_price : data.size === 'medium' && item.multiplePrice ? item.medium_price : item.price 
    }
    const isOnCart = !!itemsOnCart.filter(oneItem => areEqual(oneItem, dataParsed)).length
    if (isOnCart) return dispatch(RemoveItem(dataParsed))
    dispatch(addItem(dataParsed))
  }

  // calculate same accesorie in te cart
  const isThisItemOnCart = item => {
    const dataParsed = { ...item, quantity: 1, size: data.size, price: data.size === 'big' && item.multiplePrice ? item.big_price : data.size === 'medium' && item.multiplePrice ? item.medium_price : item.price }
    const isOnCart = !!itemsOnCart.filter(oneItem => areEqual(oneItem, dataParsed)).length
    return isOnCart
  }

  // mke object to cart
  const handleAddToCart = event => {
    // remove prev custom grill if is editing
    if (editing) {
      dispatch(RemoveItem(location.state.payload))
    }
    var initialPrice = 0
    const initialState = {}
    initialState.size = state.sizes.filter(size => size.keyname === data.size)[0]
    if (state.colors.length) initialState.color = state.colors.filter(color => color.id === data.color)[0]

    // transform ids into data
    Object.keys(data).forEach(keyname => {
      if (keyname !== 'size' && keyname !== 'color' && keyname !== 'firm' && keyname !== 'firmText' && keyname !== 'sizeInfo') {
        initialState[keyname] = state.items.filter(item => item.id === data[keyname])[0]
      }
    })

    // sumePrice
    Object.keys(initialState).forEach(keyname => {
      const currentData = initialState[keyname]
      var currentPrice = data.size === 'big' ? parseInt(currentData.big_price) : parseInt(currentData.medium_price)
      if (!currentPrice) currentPrice = parseInt(currentData.price) || 0
      initialPrice = initialPrice + currentPrice
      currentData.priceCalculated = currentPrice
      currentData.sizeCalculated = data.size
      console.log(keyname, initialPrice, currentPrice)
    })

    // checkFirm
    if (data.firm && data.firmText) {
      initialPrice = initialPrice + 200
      initialState.firm = data.firmText
    }

    initialState.price = initialPrice
    initialState.id = `custom_${Math.random().toString(36).substring(7)}`
    initialState.quantity = 1
    initialState.size = data.size
    initialState.category = 'buildyourgrill'
    initialState.sizeInfo = state.sizes.filter(item => item.keyname === data.size)[0]
    initialState.title = 'Asador personalizado'
    initialState.picture = iconSrc
    dispatch(addItem(initialState))
    if (editing) {
      dispatch(setNotification(response.success('Asador actualizado')))
      setEditing(false)
      setStep(1)
    } else {
      dispatch(setNotification(response.success('Asador agregado al carrito')))
      setStep(1)
    }
  }

  const handleNextStep = () => setStep((prevStep) => prevStep + 1)
  const handlePrevStep = () => setStep((prevStep) => prevStep - 1)

  return (
    <View
      onAddToCart={handleAddToCart}
      isThisItemOnCart={isThisItemOnCart}
      onAccesorieSelect={handleAccesorieSelect}
      onSizeSelect={handleSelecteSize}
      onSectionSelect={handleSelectSection}
      data={data}
      setData={setData}
      items={state.items}
      loading={state.loading}
      sizes={state.sizes}
      accesories={state.accesories}
      colors={state.colors}
      editing={editing}
      currentStep={currentStep}
      onNextStep={handleNextStep}
      onPrevStep={handlePrevStep}
    />
  )
}

export default BuildYourGrill
