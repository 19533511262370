import { TrendingFlat } from '@material-ui/icons'
import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const Container = styled.section`
  width: 70%;
  margin: auto;
  padding: 50px 0px;
  @media screen and (max-width:959px) {
    width: 80%;
    padding: 25px 0px;
  }
`

export const Title = styled.div`
  font-size: 106px;
  font-family: 'bebas';
  font-weight: bold;
  text-align: center;
  margin: 30px auto;
  line-height: 1em;
  @media screen and (max-width:1500px) {
    font-size: 90px;
  }
  @media screen and (max-width:1400px) {
    font-size: 75px;
  }
  @media screen and (max-width:959px) {
    font-size: 59px;
  }
`
export const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media screen and (max-width:959px) {
    flex-wrap: wrap;
  }
`
export const Card = styled.div`
  width: 30%;
  @media screen and (max-width:959px) {
    width: 100%;
    margin-bottom: 30px;
  }
`
export const CardAddress = styled(Card)`
  @media screen and (max-width:1220px) {
    width: 50%
  }
  @media screen and (max-width:818px) {
    width: 100%;
    margin-bottom: 30px;
  }
`
export const Picture = styled.img`
  width: 100%;
  background-color: #d1d1d1;
  height: 280px;
  object-fit: cover;
  @media screen and (max-width:1600px) {
    height: 230px;
  }
  @media screen and (max-width:600px) {
    height: 200px;
  }
`
export const Data = styled.div`
  width: 80%;
  padding: 15px 0px;
  text-align: center;
  margin: auto;
  font-family: 'gotham';
  font-size: 16px;
`
export const DataCategory = styled(Data)`
  @media screen and (max-width:1600px) {
    width: 90%;
  }
  @media screen and (max-width:1250px) {
    font-size: 14px;
    width: 95%;
  }
`
export const SubTitulo = styled.div`
  font-size: 50px;
  font-family: 'bebas';
  line-height: 1em;
  margin-top: 12px;
  margin-bottom: 10px;
  @media screen and (max-width:1500px) {
    font-size: 50px;
  }
  @media screen and (max-width:1400px) {
    font-size: 40px;
  }
  @media screen and (max-width:959px) {
    font-size: 59px;
  }
`
export const SubTituloAddes = styled(SubTitulo)`
  margin: 0px;
  @media (max-width:959px) {
    text-align: center;
    font-size: 48px;
  }
`

export const ButtonStyled = styled(Button)`
  display: flex;
  margin: auto;
  margin-top: 20px;
  font-family: 'bebas';
  font-size: 27px;
  border-radius: 0px;
  padding: 0px 20px;
  border: 1px solid #000;
  img {
    width: 40px;
    margin-left: 15px;
    @media screen and (max-width:1168px) {
      width: 25px;
      margin-left: 8px;
    }
    @media screen and (max-width:959px) {
      width: 30px;
      margin-left: 15px;
    }
  }
  @media screen and (max-width:1500px) {
    font-size: 20px;
    padding: 0px 12px;
  }
  @media screen and (max-width:1168px) {
    font-size: 18px;
  }
  @media screen and (max-width:959px) {
    font-size: 25px;
    padding: 0px 20px;
  }

  :hover {
    background-color: #000;
    color: #fff;
  }
  svg {
    margin-left: 10px;
    font-size: 30px;
  }
`

export const DatatextVideo = styled(Data)`
  text-align: left;
  width: 100%;
  @media screen and (max-width:959px) {
    text-align: center;
  }
`

export const Video = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  object-fit: cover;
  @media screen and (max-width:959px) {
    width: 100%;
  }
`
export const DataVideo = styled.div`
  width: 30%;
  @media screen and (max-width:1180px) {
    width: 36%;
  }
  @media screen and (max-width:959px) {
    width: 100%;
  }
`

export const TrendingFlatStyled = styled(TrendingFlat)`
  @media screen and (max-width:1500px) {
    display: none;
  }
`
