import { useState } from 'react'

const useObjectState = initialState => {
  const [state, setState] = useState(initialState || {})

  const handleSetState = (newSTate) => {
    setState((prevState) => (
      {
        ...prevState,
        ...newSTate
      }
    ))
  }

  return [state, handleSetState, setState]
}

export default useObjectState
