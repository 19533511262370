import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Layout from '../../components/layout_admin'
import Title from '../../components/page_title'
import { Grid, Box, Typography, Button } from '@material-ui/core'
import { db } from '../../../modules/firebase'
import snapshotParser from '../../../helpers/snapshotparser'
import Picture from '../../../components/picture'
import { Link } from 'react-router-dom'
import admin from '../../hoc/admin'
import Empty from '../../../user/components/empty'
import { ColorLens } from '@material-ui/icons'
import { setAlert } from '../../../flux/alert'
import { useDispatch } from 'react-redux'

const Item = styled.div`
  overflow: hidden;
`
const View = props => {
  const [state, setState] = useState({ loading: true, items: [] })
  const dispatch = useDispatch()

  const handlefetch = async () => {
    const snapshot = await db.collection('colors').get()
    const items = snapshotParser(snapshot)
    setState({ items, loading: false })
  }

  useEffect(() => {
    handlefetch()
  })

  const handleDelete = id => {
    dispatch(setAlert({
      title: '¿Seguro que quieres borrar este color?',
      message: 'Una vez realizada esta acción no podras recurar la información',
      action: async () => {
        await db.doc(`colors/${id}`).delete()
        await handlefetch()
      },
      textAction: 'Eliminar'
    }))
  }

  return (
    <Layout title='Colores'>
      <Title>Colores</Title>
      <Box>
        <Link to='/edit-color'>
          <Button color='secondary' variant='contained'>Crear nuevo</Button>
        </Link>
      </Box>
      <Box mt={2}>
        {!state.loading && !!state.items.length && (
          <Grid container spacing={2}>
            {state.items.map((item, index) => (
              <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                <Item>
                  <Picture height={80} src={item.picture} />
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Link to={{ pathname: '/edit-color', state: item }}>
                        <Button style={{ textTransform: 'none' }} fullWidth variant='outlined' color='secondary' size='small'>Editar</Button>
                      </Link>
                    </Grid>
                    <Grid item xs={6}>
                      <Button style={{ textTransform: 'none' }} onClick={event => handleDelete(item.id)} fullWidth variant='outlined' color='secondary' size='small'>Eliminar</Button>
                    </Grid>
                  </Grid>
                  <Typography color='primary' align='center' variant='h6'>{item.title}</Typography>
                </Item>
              </Grid>
            ))}
          </Grid>
        )}
        {!state.loading && !state.items.length && (
          <Empty
            icon={ColorLens}
            message='Aun no tienes colores creados'
          />
        )}
      </Box>
    </Layout>
  )
}

View.propTypes = {

}

export default admin(View)
