import React, { useState } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { Container, DatatextVideo, Title, ListContainer, SubTituloAddes, Card, ButtonStyled } from './styles'
import { TrendingFlat } from '@material-ui/icons'
import useFetch from '../../../hooks/useFetch'
import pointsOfSale from '../../../modules/pointsOfSale'

const PointSales = () => {
  const [items, setItems] = useState(null)
  const isMobile = useMediaQuery('(max-width:959px)')

  // fetch items
  useFetch(async () => {
    const itemsResponse = await pointsOfSale.getAll()
    if (itemsResponse && itemsResponse.length) setItems(itemsResponse)
  })

  if (!items || !items.length) return null

  return (
    <Container id='pointsales'>
      <Box pt={{ xs: 1, md: 2 }} />
      <Title style={{ textAlign: isMobile ? 'center' : 'left', marginBottom: isMobile ? '20px' : '80px' }}>Puntos de venta</Title>
      <Box pt={{ xs: 1, md: 2, lg: 3 }} />
      <ListContainer style={{ flexWrap: 'wrap' }}>
        {items.map((currentItem, index) => (
          <Card key={index} style={{ marginTop: '2em' }}>
            <SubTituloAddes>{currentItem.title}</SubTituloAddes>
            <DatatextVideo>
              {currentItem.address}
            </DatatextVideo>
            <a target='_blank' rel='noreferrer' href={currentItem.mapLink}>
              <ButtonStyled style={{ margin: isMobile ? 'auto' : 'initial' }}>
                ¿Cómo llegar? <TrendingFlat />
              </ButtonStyled>
            </a>
          </Card>
        ))}
        <Card />
      </ListContainer>
    </Container>
  )
}

export default PointSales
