const success = (message, data = {}) => ({
  ...data,
  status: 'success',
  type: 'success',
  success: true,
  error: false,
  message: message || 'Error interno del servidor'
})

const error = (message, data = {}) => ({
  ...data,
  status: 'error',
  type: 'error',
  success: false,
  error: true,
  message: message || 'Error interno del servidor'
})

const response = { success, error }

export default response
