import React from 'react'
import styled from 'styled-components'
import { Facebook, Instagram, YouTube, Pinterest, WhatsApp, Mail } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

const FotterStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px;
  color: #fff;
`
const SocialContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Text = styled.div`
  font-size: 15px;
  font-family: 'gotham';
`
const IconButtonStyled = styled(IconButton)`
  padding: 5px;
  svg {
    width: 20px;
    color: #fff;
    transition: all 200ms linear;
    :hover {
      color: red;
    }
  }
`

const Line = styled.div`
  width: 30px;
  height: 0px;
  margin: auto 20px;
  border-bottom: 1px solid #fff;
`

const FullWidth = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
`
const Footer = props => {
  return (
    <FullWidth>
      <FotterStyled>
        <SocialContainer>
          <Text>
            Síguenos en redes
          </Text>
          <Line />
          <a href='https://www.facebook.com/asadoresdelnorte' target='_blank'>
            <IconButtonStyled>
              <Facebook />
            </IconButtonStyled>
          </a>

          <a href='https://www.instagram.com/asadoresnorte' target='_blank'>
            <IconButtonStyled>
              <Instagram />
            </IconButtonStyled>
          </a>

          <a href='https://www.youtube.com/user/AsadoresdelNorte' target='_blank'>
            <IconButtonStyled>
              <YouTube />
            </IconButtonStyled>
          </a>

          <a href='https://www.pinterest.com.mx/asadoresdelnorte/' target='_blank'>
            <IconButtonStyled>
              <Pinterest />
            </IconButtonStyled>
          </a>

        </SocialContainer>

        <SocialContainer style={{ border: '1px solid #fff', padding: '0px 15px' }}>
          <Text>
            Ponte en contacto con nosotros
          </Text>
          <Line />
          <a href='https://wa.me/message/V5E2YBZXZHLHM1' target='_blank'>
            <IconButtonStyled>
              <WhatsApp />
            </IconButtonStyled>
          </a>
          <a href='mailto:VENTAS@ASADORESDELNORTE.COM'>
            <IconButtonStyled>
              <Mail />
            </IconButtonStyled>
          </a>
        </SocialContainer>
      </FotterStyled>
    </FullWidth>
  )
}

export default Footer
