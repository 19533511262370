import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import currency from '../../../helpers/currency'
import { Box, Grid, Typography } from '@material-ui/core'
import { categories } from '../../../constants'
import PictureResponsive from '../../../components/picture'

const Summary = (props) => {
  // 'información general', 'Colores', 'Multimedia', 'Resumen'
  if (!props.steps || props.steps[props.currentStep] !== 'Resumen') return null

  return (
    <Container>
      <Title>{props.title}</Title>
      <Picture height={70} src={typeof props.picture === 'string' ? props.picture : props.picture.preview} />
      {!!props.colors.length && (
        <Box mt={2}>
          <Price><span>Colores:</span></Price>
          <Grid container spacing={1}>
            {props.colors.map((color, index) => (
              <Grid item xs={3} key={index}>
                <PictureResponsive src={typeof color.picture === 'string' ? color.picture : color.picture.preview} />
                <Typography color='secondary' variant='subtitle1'>{color.name}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Box mt={2}>
        {(props.category === 'buildyourgrill' || props.multiplePrice) && (
          <>
            <Price><span>Mediano:</span> $ {currency.formatMoney(props.medium_price)} MXN</Price>
            <Price><span>Grande:</span> $ {currency.formatMoney(props.big_price)} MXN</Price>
            <Price><span>Categoria:</span> {categories[props.category]}</Price>
          </>
        )}
        {props.category !== 'buildyourgrill' && !props.multiplePrice && (
          <>
            <Price><span>Precio:</span> $ {currency.formatMoney(props.price)} MXN</Price>
            <Price><span>Categoria:</span> {categories[props.category]}</Price>
          </>
        )}
      </Box>
      <Description>
        <div dangerouslySetInnerHTML={{ __html: props.description }} />
      </Description>
    </Container>
  )
}

Summary.propTypes = {
  currentStep: propTypes.number,
  title: propTypes.string,
  description: propTypes.string,
  picture: propTypes.oneOfType([propTypes.object, propTypes.string])
}

const Container = styled('div')`
  max-width: 700px;
  margin: auto;
  
`
const Title = styled('h2')`
  color: var(--main-blue-dark);
`
const Picture = styled(PictureResponsive)`
  width: 100%;
  display: block;
`
const Price = styled('h3')`
  color: var(--main-blue);
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  span {
    color: var(--main-blue-dark)
  }
`
const Description = styled('p')`
  color: var(--main-blue-dark);
  font-size: 1.2em;
`

export default Summary
