import { Button } from '@material-ui/core'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import styled from 'styled-components'

export const Content = styled.div`
  align-items: center;
  display: flex;
  padding: 10px;
  margin: 20px 0px;
  justify-content: space-between;
  font-size: 35px;
  line-height: 1em;
  @media screen and (max-width:1500px) {
    font-size: 25px;
  }
  font-family: 'bebas';
  font-weight: bold;
  background: #fff;
  border: 1px solid #000;
  @media screen and (max-width:1000px) {
    font-size: 35px;
    padding: 0px;
    flex-wrap: wrap;
    text-align: center;
  }
`

export const LimiterInte = styled.div`
  @media screen and (max-width:1000px) {
    width: 80%;
    margin: auto;
  }
`

export const PictureContent = styled.div`
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  @media screen and (max-width:1000px) {
    width: 80%;
    margin: 20px auto;
  }
`
export const PictureContentHead = styled(PictureContent)`
  min-height: 0px!important;
  height: 0px!important;
`
export const DeleteIcon = styled(DeleteOutlinedIcon)`
  background: red;
  border-radius: 50%;
  width: 43px;
  height: 43px;
  min-width: 43px;
  min-height: 43px;
  color: #fff;
  padding: 5px;
  cursor: pointer;
  margin-right: 20px;
  :hover {
    background: #000;
  }
  @media screen and (max-width:1000px) {
    margin-right: 0px;
    margin: 20px auto;
  }
`
export const DeleteIconHeder = styled(DeleteIcon)`
  height: 0px;
  background: none;
  opacity: 0;
`

export const DataContent = styled.div`
  width: 100%;
  margin: 0px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width:1000px){
    flex-wrap: wrap;
    margin: 0px;
  }
`
export const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Title = styled.div`
  width:40%;
  padding-left: 10px;
  @media screen and (max-width:1000px) {
    width: 80%;
    margin: auto;
  }
  @media screen and (max-width:500px) {
    width: 100%;
    margin: auto;
    font-size: .9em;
  }
`
export const Price = styled.div`
  width:20%;
  padding-left: 10px;
  @media screen and (max-width:1000px) {
    width: 80%;
    margin: auto;
    padding-left: 0px;
  }
`
export const QuantityControls = styled.div`
  width:30%;
  padding-left: 10px;
  @media screen and (max-width:1000px) {
    padding-left: 0px;
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const OnlyMobile = styled.div`
  display: none;
  @media screen and (max-width:1000px) {
    display: block;
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 25px;
  }
`
export const ButtonStyled = styled(Button)`
  background: #fff;
  border: 1px solid #000;
  padding: .3em 3em; 
`