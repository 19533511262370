import React from 'react'
import styled from 'styled-components'
import { categories } from '../../../constants'
import useHeaderheight from '../../../hooks/useHeaderheight'
import { string } from 'prop-types'
import { Box, useMediaQuery } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import slugify from 'slugify'

const Wrapper = styled.div`
  position: sticky;
  top: 30px;
  @media screen and (max-width: 1000px) {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
`

const Category = styled.div`
  margin-bottom: 18px;
  cursor: pointer;
  color: gray;
  box-sizing: border-box;
  font-family:'bebas';
  border-bottom: 2px solid #fff;
  ${props => props.$selected ? 'color: #000; border-bottom: 2px solid #000;' : ''};
  font-size: 4em;
  :hover {
    color: #000;
    border-bottom: 2px solid #000;
  }
  @media screen and (max-width:1500px) {
    font-size: 3.2em;
  }
  @media screen and (max-width:1000px) {
    font-size: 26px;
  }
  @media screen and (max-width:600px) {
    font-size: 20px;
  }
`
const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Menu = (props) => {
  const history = useHistory()
  const top = useHeaderheight()
  const isMobile = useMediaQuery('(max-width:1000px)')

  const handleCategorySelected = (category) => {
    const slug = slugify(categories[category], {
      lower: true,
      strict: true
    })
    history.replace(`/productos/${slug}`)
  }

  return (
    <Wrapper style={{ top: `calc(${top} + 50px)` }}>
      {!isMobile && (<Box />)}
      {Object.keys(categories).map(keyname => (
        <MenuItem key={keyname}>
          <Category
            onClick={() => handleCategorySelected(keyname)}
            $selected={keyname === props.currentCategory}
          >
            {categories[keyname]}
          </Category>
        </MenuItem>
      ))}
    </Wrapper>
  )
}

Menu.propTypes = {
  currentCategory: string
}
export default Menu
