import React from 'react'
import propTypes from 'prop-types'
import { Content, PictureContent, PictureContentHead, DeleteIcon, DeleteIconHeder, DataContent, Picture, Title, Price, QuantityControls, OnlyMobile, ButtonStyled } from './styled'
import InputQuantity from '../../../../components/input_quantity'
import Currency from '../../../../../helpers/currency'
import Responsive from '../../../../../components/responsive'
import { Box, useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'

const ItemOnCart = (props) => {
  const isMobile = useMediaQuery('(max-width:1000px)')

  const onQuantityChange = event => {
    props.handleChangeQuantity(props, parseInt(event.target.value))
  }

  const handleQuantityChange = value => {
    props.handleChangeQuantity(props, value)
  }

  const getPayload = () => ({
    doorDesign: props.doorDesign,
    id: props.id,
    grillType: props.grillType,
    sideTables: props.sideTables,
    tiresType: props.tiresType,
    interiorType: props.interiorType,
    firm: props.firm,
    size: props.size,
    firm: props.firm,
    color: props.color
  })

  if (props.header) {
    return (
      <Responsive rule='min-width:1001px'>
        <Content style={{ border: 'none', color: '#000', background: 'none' }}>
          <PictureContentHead />
          <DataContent>
            <Title>Producto</Title>
            <QuantityControls>
              Cantidad
            </QuantityControls>
            <Price>Precio</Price>
          </DataContent>
          <DeleteIconHeder />
        </Content>
      </Responsive>
    )
  }

  return (
    <Content>
      <OnlyMobile style={{ marginTop: '20px' }}>
        Producto
      </OnlyMobile>
      <PictureContent>
        <Picture src={props.picture} />
      </PictureContent>
      <DataContent>
        <Title>
          <Box>{props.title}</Box>
          {props.category === 'buildyourgrill' && (
            <Box component='ul' fontSize='.5em' lineHeight="1.4em" margin="0em" marginTop=".4em" textAlign="left">
              <Box component="li">
                <Box component="span" color="gray" marginRight=".5em">Tamaño:</Box>
                {props.size === 'medium' ? 'Mediano' : props.size === 'big' ? 'Grande' : ''}
              </Box>
              <Box component="li">
                <Box component="span" color="gray" marginRight=".5em">Diseño de puerta:</Box>
                {props.doorDesign.title}
              </Box>
              <Box component="li">
                <Box component="span" color="gray" marginRight=".5em">Tipo de parrilla:</Box>
                {props.grillType.title}
              </Box>
              <Box component="li">
                <Box component="span" color="gray" marginRight=".5em">Mesas laterales:</Box>
                {props.sideTables.title}
              </Box>
              <Box component="li">
                <Box component="span" color="gray" marginRight=".5em">Tipo de llantas:</Box>
                {props.tiresType.title}
              </Box>
              <Box component="li">
                <Box component="span" color="gray" marginRight=".5em">Tipo de interior:</Box>
                {props.interiorType.title}
              </Box>
              <Box component="li">
                <Box component="span" color="gray" marginRight=".5em">Color:</Box>
                {props.color.title}
              </Box>
              {!!props.firm && (
                <Box component="li">
                  <Box component="span" color="gray" marginRight=".5em">Grabado:</Box>
                  {props.firm}
                </Box>
              )}
              <Link to={{ pathname: '/arma-tu-asador', state: { payload: getPayload() } }}>
                <Box marginTop=".5em">
                  <ButtonStyled>
                    <Box fontFamily="bebas">Modificar</Box>
                  </ButtonStyled>
                </Box>
              </Link>
            </Box>
          )}
        </Title>
        <OnlyMobile>CANTIDAD</OnlyMobile>
        <QuantityControls>
          <InputQuantity
            $width={isMobile ? '150px' : '150px'}
            onQuantityChange={onQuantityChange}
            quantity={props.quantity}
            setQuantity={handleQuantityChange}
          />
        </QuantityControls>
        <OnlyMobile>PRECIO</OnlyMobile>
        <Price>$ {Currency.formatMoney(props.price)}</Price>
      </DataContent>
      <DeleteIcon
        onClick={event => props.handleRemoveItem(props)}
      />
    </Content>
  )
}

ItemOnCart.propTypes = {
  price: propTypes.number,
  title: propTypes.string,
  picture: propTypes.string,
  quantity: propTypes.oneOfType([propTypes.number, propTypes.string]),
  handleRemoveItem: propTypes.func,
  handleChangeQuantity: propTypes.func,
  header: propTypes.bool
}

export default ItemOnCart
