import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Youtube from 'react-youtube'

const VideoContainer = styled.div`
  width: 100%;
`

const Video = styled(Youtube)({
  border: 'none',
  outline: 'none'
})

const VideoPlayer = props => {
  const [containerVideoWidth, setVideoContainerWidth] = useState(720)
  const [video, setVideo] = useState({})

  useEffect(() => {
    const width_ = document.getElementById('videocontainermodal').offsetWidth
    if (width_ !== containerVideoWidth) setVideoContainerWidth(width_)
  }, [containerVideoWidth])

  const h = Math.ceil(containerVideoWidth * 0.562)

  const opt = {
    controls: 0,
    height: h,
    loop: 1,
    width: containerVideoWidth,
    playerVars: {
      controls: 0,
      showinfo: 0,
      autoplay: 1,
      loop: 1,
      mute: 1
    }
  }

  return (
    <VideoContainer id='videocontainermodal'>
      <Video
        videoId='A8pLwaAC1uE'
        opts={opt}
        onReady={event => { setVideo(event); event.target.playVideo() }}
        onEnd={event => video.target.playVideo()}
      />
    </VideoContainer>
  )
}

export default VideoPlayer
