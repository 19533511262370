/* eslint-disable react/jsx-handler-names */
/* eslint-disable camelcase */
import React, { useState } from 'react'
import validateForm from '../../../helpers/validateform'
import article from '../../../modules/article'
import propTypes from 'prop-types'
import Admin from '../../hoc/admin'
import styled from 'styled-components'
import Container from '../../components/layout_admin'
import { Box, Fade, Button, IconButton } from '@material-ui/core'
import CreateInput from '../../components/input'
import { Alert } from '@material-ui/lab'
import currencyParser from '../../../helpers/currency'
import Title from '../../components/page_title'
import Picture from '../../../components/picture'
import { Delete } from '@material-ui/icons'
import { db } from '../../../modules/firebase'
import DropZone from '../create_item/Dropzone'
import Loading from '../create_item/loading'
import Success from './success'

const CreateItem = (props) => {
  var preloadedState = props.location.state || {}
  const [data, setData] = useState(preloadedState)
  const [errors, setErrors] = useState([])
  const [picture, setPicture] = useState(preloadedState.picture || null)
  const [currentView, setCurrenView] = useState('form') // form loading success

  const handleUpdate = async () => {
    try {
      setCurrenView('loading')

      // upload new principal picture
      if (typeof picture !== 'string') {
        const fileToDelete = preloadedState.picture
        const urlPicture = await article.uploadPicture('sizes', picture)
        await db.doc(`sizes/${data.keyname}`).update({ picture: urlPicture })
        await article.deletePicture('sizes', [fileToDelete])
      }

      // update data
      await db.doc(`sizes/${data.keyname}`).update({
        rotisserie: data.rotisserie,
        grill: data.grill,
        price: data.price
      })
      setCurrenView('success')
    } catch (error) {
      console.error('error_description:', error)
    }
  }

  const handleChange = ({ value, name }) => {
    setData({
      ...data,
      [name]: value
    })
  }

  const removeError = ({ name }) => {
    if (errors.includes(name)) {
      setErrors(errors.filter(error => error !== name))
    }
  }

  const handleDeletePicture = () => {
    setPicture(null)
  }

  const handleSave = evenrt => {
    const errors = validateForm.requires(data, ['price', 'grill', 'rotisserie'])
    if (errors) return setErrors(errors)
    if (!picture) return setErrors('picture')
    handleUpdate()
  }

  const handleDropPicture = (ArrayOfpicture) => {
    setPicture(ArrayOfpicture ? ArrayOfpicture[0] : null)
    if (errors.length) setErrors([])
  }

  return (
    <Container title='Editar tamaño'>
      <ContentSecondary>
        {currentView === 'form' && (
          <Box>
            <Title>Editar tamaño</Title>
            <Fade in={errors.length !== 0}>
              <GroupForm>
                <Alert severity='error'>Todos los campos son requeridos</Alert>
              </GroupForm>
            </Fade>
            {!!picture && (
              <GroupForm>
                <GridStyled>
                  <IconDelete size='small' onClick={handleDeletePicture}>
                    <Delete />
                  </IconDelete>
                  <Picture height={80} src={typeof picture === 'string' ? picture : picture.preview} />
                </GridStyled>
              </GroupForm>
            )}
            {!picture && (
              <DropZone
                multiple={false}
                handleDrop={handleDropPicture}
              />
            )}
            <GroupForm>
              <Label>Precio</Label>
              <TitleInput
                removeError={removeError}
                type='text'
                size='large'
                value={data.price ? currencyParser.toCurrency(data.price) : ''}
                onChange={({ name, value }) => handleChange({ name, value: currencyParser.toNumber(value) })}
                name='price'
                errors={errors}
              />
            </GroupForm>
            <GroupForm>
              <Label>Tamaño del asador</Label>
              <TitleInput
                removeError={removeError}
                type='text'
                size='large'
                onChange={handleChange}
                {...data}
                name='rotisserie'
                errors={errors}
              />
            </GroupForm>
            <GroupForm>
              <Label>Parrilla</Label>
              <TitleInput
                removeError={removeError}
                type='text'
                size='large'
                onChange={handleChange}
                {...data}
                name='grill'
                errors={errors}
              />
            </GroupForm>
            <Button onClick={handleSave} color='primary' variant='contained' style={{ minWidth: '180px' }}>Guardar</Button>
          </Box>
        )}
        {currentView === 'loading' && (
          <Loading
            title='Guardando datos'
            numPicturesToUpload={1}
            numPicturesUploaded={1}
          />
        )}
        {currentView === 'success' && (
          <Success />
        )}
      </ContentSecondary>
    </Container>
  )
}

CreateItem.propTypes = {
  location: propTypes.object
}

export default Admin(CreateItem)

const ContentSecondary = styled('div')`
  max-width: 600px;
  margin: auto;
`
const GroupForm = styled('div')`
  margin: auto;
  margin-bottom: 20px;
  width: 100%;
`
const Label = styled('div')`
  color: var(--main-blue-dark);
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1.1em;
`
const TitleInput = styled(CreateInput)`
  width: 100%;
  .MuiTextField-root{
    color: red
  }
`
const GridStyled = styled('div')`
  position: relative;
`
const IconDelete = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--main-blue);
  svg {
    color: #fff;
  }
  :hover {
    background-color: var(--main-blue-dark);
  }
`
