import { Box, Button, CircularProgress, Collapse, InputAdornment } from '@material-ui/core'
import React, { useState } from 'react'
import styled from 'styled-components'
import Input from '../../components/input'
import { discountTypes } from '../../../constants'
import useForm from '../../../hooks/useForm'
import { func } from 'prop-types'
import { Alert } from '@material-ui/lab'
import discounts from '../../../modules/discounts'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../flux/notification'
import { Alarm } from '@material-ui/icons'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { toString } from '../../../helpers/date'

const Creator = (props) => {
  const [pickerActive, setPickerActive] = useState(false);
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const { getInputProps, validateInputs, values, setValues } = useForm()

  const handleSave = async () => {
    const { firstErrorMessage } = validateInputs({
      code: (value) => {
        if (!value) return 'El código es requerido'
        if (value.toString().includes(' ')) return 'El código no puede contener espacios'
        return false
      },
      value: (value) => {
        const valueParsed = Number.parseFloat(value)
        if (!valueParsed || isNaN(valueParsed)) return 'El valor es requerido'
        if (values.type === 'porcent' && valueParsed >= 100) return 'El valor no puede ser igual o mayor que 100%'
        if (values.type === 'absolute' && valueParsed >= Number.parseFloat(values.minPrice)) return 'El valor no puede ser igual o mayor que el costo minimo del producto'
        return false
      },
      minPrice: (value) => {
        const valueParsed = Number.parseFloat(value)
        if (!valueParsed || isNaN(valueParsed)) return 'El precio mínimo es requerido'
        return false
      },
      type: (value) => {
        if (!value) return 'El tipo de descuento es requerido'
        return false
      },
      deadLine: (value) => {
        if (!value) return 'La fecha de vencimiento es requerida'
        return false
      }
    })
    if (firstErrorMessage) {
      setErrorMessage(firstErrorMessage)
      setTimeout(() => setErrorMessage(''), 7000)
      return null
    }
    setLoading(true)
    const response = await discounts.create(values)
    if (response.success) {
      if (props.onClose) props.onClose()
      if (props.onCreated) props.onCreated()
      dispatch(setNotification(response))
    } else {
      setLoading(false)
      setErrorMessage(response.message)
      setTimeout(() => setErrorMessage(''), 7000)
    }
  }

  return (
    <FullWrapper>
      <Card>
        <CardBody>
          {!loading && (
            <>
              <Box fontWeight='bold' textAlign='center'>Crear promoción</Box>
              <Box marginTop='1em'>
                <Collapse in={!!errorMessage}>
                  <Alert severity='error'>{errorMessage}</Alert>
                </Collapse>
                <Box marginBottom='.1em' color='var(--main-blue)' marginTop='1em'>Código</Box>
                <Input size='small' {...getInputProps('code')} type='text' fullWidth maxlength={5} />
                <Box marginBottom='.1em' color='var(--main-blue)' marginTop='1em'>Valor</Box>
                <Input size='small' {...getInputProps('value')} type='number' fullWidth />
                <Box marginBottom='.1em' color='var(--main-blue)' marginTop='1em'>Precio mínimo</Box>
                <Input size='small' {...getInputProps('minPrice')} type='number' fullWidth />
                <Box marginBottom='.1em' color='var(--main-blue)' marginTop='1em'>Tipo de descuento</Box>
                <Input size='small' type='select' {...getInputProps('type')} fullWidth options={discountTypes} />
                <Box marginBottom='.1em' color='var(--main-blue)' marginTop='1em'>Fecha de vencimiento</Box>
                <Box onClick={() => setPickerActive(true)} style={{ cursor: 'pointer!important' }}>
                  <Input
                    {...getInputProps('deadLine')}
                    value={values.deadLine ? toString(values.deadLine) : ''}
                    size='small'
                    fullWidth
                    type="text"
                    readOnly
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Alarm style={{ color: 'var(--main-blue)' }} />
                        </InputAdornment>
                      ),
                    }} 
                  />
                </Box>
                <Box display="none">
                  <KeyboardDatePicker
                    open={pickerActive}
                    onClose={() => setPickerActive(false)}
                    disablePast
                    onChange={(date) => setValues((prevValues) => ({ ...prevValues, deadLine: date }))} 
                    value={values.deadLine}
                    disableToolbar
                  />
                </Box>
                <Box display='flex' marginTop='2em'>
                  <Button color='primary' variant='outlined' fullWidth onClick={props.onClose}>
                    Cancelar
                  </Button>
                  <Box paddingRight='1em' />
                  <Button color='primary' variant='contained' fullWidth onClick={handleSave}>
                    Guardar
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {loading && (
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='40vh'>
              <CircularProgress />
            </Box>
          )}
        </CardBody>
      </Card>
    </FullWrapper>
  )
}

Creator.propTypes = {
  onClose: func.isRequired,
  onCreated: func.isRequired
}

const FullWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
`

const Card = styled.div`
  background: #fff;
  position: relative;
  padding: 6em;
  width: 35em;
  border-radius: .5em;
  @media screen and (max-width:1300px) {
    padding: 3em;
    width: 30em;
  }
  @media screen and (max-width:600px) {
    padding: 2em;
    width: 90%;
    max-width: 400px;
  }
`

const CardBody = styled.div`
  max-height: 80vh;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

export default Creator
