/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-target-blank */
import { Box, Button, CircularProgress, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { array, bool, func, string } from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import Layout from '../../components/layout_admin'
import Editor from './components/editor'
import formatPhone from '../../../helpers/formatPhone'

const Item = (props) => {
  const [menuActive, setMenuActive] = useState(null)

  const handleMenuClose = () => {
    setMenuActive(false)
  }

  const handleMenuOpen = (event) => {
    setMenuActive(event.currentTarget)
  }

  return (
    <CardWrapper>
      <CardItem>
        <MoreVertStyled onClick={handleMenuOpen} />
        <Menu
          id='basic-menu'
          anchorEl={menuActive}
          open={!!menuActive}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={props.onDelete}>Eliminar</MenuItem>
          <MenuItem onClick={props.onEdit}>Editar</MenuItem>
        </Menu>
        <Box marginBottom='.8em'>
          <Box fontSize='1em' color='gray'>Nombre</Box>
          <Box fontFamily='bebas' fontSize='2.5em' lineHeight='1.1em'>
            {props.username}
          </Box>
        </Box>
        <Box marginBottom=''>
          <Box fontFamily='gotham' fontSize='1.2em'>
            Distribuidor {props.place}
          </Box>
        </Box>
        <Box marginBottom=''>
          <Box fontFamily='gotham' fontSize='1.2em'>
            T. {formatPhone(props.phone)}
          </Box>
        </Box>
        <Box marginBottom=''>
          <Box fontFamily='gotham' fontSize='1.2em'>
            M. {props.email}
          </Box>
        </Box>
      </CardItem>
    </CardWrapper>
  )
}

const View = (props) => {
  return (
    <>
      {props.editorOpen && <Editor onClose={props.onEditorClose} onSuccess={props.onEditorSuccess} itemEditing={props.itemEditing} />}
      <Layout title='Distribuidores'>
        {!props.loading && (
          <Box>
            <CardWrapper>
              <Button variant='contained' color='primary' onClick={props.onCreate}>
                <Box padding='0em 2em'>Crear</Box>
              </Button>
            </CardWrapper>
          </Box>
        )}
        {props.loading && (
          <Box display='flex' justifyContent='center' alignItems='center' minHeight='60vh'>
            <CircularProgress />
          </Box>
        )}
        {!props.loading && !props.items.length && (
          <Box display='flex' justifyContent='center' alignItems='center' minHeight='60vh'>
            <Box color='var(--main-blue)' fontSize='1.3em' textAlign='center'>
              Aún no hay distribuidores creado
            </Box>
          </Box>
        )}
        {!props.loading && !!props.items.length && (
          <Box display='flex' flexWrap='wrap'>
            {props.items.map((item, index) => (
              <Item
                {...item}
                key={index}
                onEdit={() => props.onEditItem(item.id)}
                onDelete={() => props.onDeleteItem(item.id)}
              />
            ))}
          </Box>
        )}
      </Layout>
    </>
  )
}

const CardWrapper = styled.div`
  width: 25%;
  padding: 1em;
  box-sizing: border-box;
  position: relative;
  @media screen and (max-width:1500px) {
    width: 33.3%;
    font-size: .9em;
  }
  @media screen and (max-width:1200px) {
    width: 33.3%;
    padding: .5em;
  }
  @media screen and (max-width:1000px) {
    width: 50%;
  }
  @media screen and (max-width:550px) {
    width: 100%;
  }
`
const CardItem = styled.div`
  border: 1px solid var(--main-blue-dark);
  padding: 1.5em;
  height: 100%;
  @media screen and (max-width:1200px) {
    padding: 1em;
  }
`
const MoreVertStyled = styled(MoreVert)`
  position: absolute;
  right: 1em;
  cursor: pointer;
  top: 1em;
`

View.propTypes = {
  items: array.isRequired,
  loading: bool.isRequired,
  editorOpen: bool.isRequired,
  onEditorClose: func.isRequired,
  onEditorSuccess: func.isRequired,
  onEditItem: func.isRequired,
  itemEditing: string,
  onDeleteItem: func.isRequired,
  onCreate: func.isRequired
}

export default View
