import { db, firebase } from './firebase'
import xlsParser from '../helpers/xlsParser';

// cnostants
const collectionRef = db.collection('shippingCost');
const limitBatch = 3000;

// helpers
const splitArrayIntoChunks = (array, limit) => {
  const chunks = [];
  let rest = [...array];
  
  do {
    const chunk = rest.slice(0, limit)
    chunks.push(chunk)
    rest = rest.slice(limit)
  } while (!!rest.length);
  
  return chunks;
}

export const getList = async () => {
  try {
    const querySnapshot = await collectionRef.get();
    const data = querySnapshot.docs.reduce((prevValue, currentDoc) => {
      return {
        ...prevValue,
        ...currentDoc.data()
      }
    }, {})
    return Object.values(data);
  } catch (error) {
    console.log('error at list shipping cost', error)
    return []
  }
}

export const deleteOne = async (cp) => {
  try {
    const querySnapshot = await collectionRef.get();
    const doc = querySnapshot.docs.find((snapshot) => {
      const data = snapshot.data();
      return !!data[cp]
    })
    if (Object.keys(doc.data()).length === 1) {
      await doc.ref.delete()
    } else {
      await doc.ref.update({
        [cp]: firebase.firestore.FieldValue.delete()
      })
    }
  } catch (error) {
    console.error(error)
    return null;
  }
}

export const add = async (data) => {
  await deleteOne(data.cp)
  const querySnapshot = await collectionRef.get();
  const documentTarget = querySnapshot.docs.find((snapshot) => Object.keys(snapshot.data()).length < limitBatch)
  if (documentTarget) {
    await documentTarget.ref.update({ [data.cp]: data })
  } else {
    await collectionRef.add({ [data.cp]: data })
  }
}

export const addListByFile = async (file) => {
  // parse file
  let list = await xlsParser(file);
  if (!list) return { error: true, message: 'El archivo seleccionado no es válido' }

  // formatting
  list = list.map((item) => ({
    cost: item.cost,
    cp: item.cp,
    smallCost: item.smallCost,
    comment: `${item.state}, ${item.city}, ${item.col}`,
  }));

  //delete duplicated postal code
  list = list.reduce((prevValue, currentItem) => {
    prevValue[currentItem.cp] = currentItem;
    return prevValue;
  }, {})
  list = Object.values(list);

  // format chunks for database model 
  let chunks = splitArrayIntoChunks(list, limitBatch)
  chunks = chunks.map((chunk) => chunk.reduce((prevValue, currentValue) => {
    prevValue[currentValue.cp] = currentValue;
    return prevValue
  }, {}))
  
  // database operation
  const querySnapshot = await collectionRef.get();
  for (const doc of querySnapshot.docs) {
    await doc.ref.delete()
  }
  for (const currentChunk of chunks) {
    await collectionRef.add(currentChunk)
  }

  
  return { error: false, message: 'Costos de envío actualizado correctamente' }
};
