import { db } from './firebase'
import filterObject from '../helpers/filterobject'
import response from '../helpers/response'

const collectionRef = db.collection('pintsOfSale')

const mekeSecureData = (data) => {
  const allows = ['title', 'address', 'mapLink', 'priority']
  const secureData = filterObject(data, allows)
  if (secureData.priority) {
    secureData.priority = Number.parseFloat(secureData.priority)
  }
  return secureData
}

const createPointOfSale = async (data) => {
  try {
    const secureData = mekeSecureData(data)
    const ref = await collectionRef.add(secureData)
    return response.success('Punto de venta creado correctamente', { id: ref.id })
  } catch (error) {
    console.error(error)
    return response.error('error interno del servidor')
  }
}

const updatePointOfSale = async (id, newData) => {
  try {
    const secureData = mekeSecureData(newData)
    await collectionRef.doc(id).update(secureData)
    return response.success('Punto de venta actualizado correctamente')
  } catch (error) {
    console.error(error)
    return response.error('error interno del servidor')
  }
}

const deletePointOfSale = async (id) => {
  try {
    await collectionRef.doc(id).delete()
    return response.success('Punto de venta eliminado correctamente')
  } catch (error) {
    console.error(error)
    return response.error()
  }
}

const getAllPointOfSale = async () => {
  try {
    const querySnapshot = await collectionRef.orderBy('priority', 'desc').get()
    const data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
    return data
  } catch (error) {
    console.error(error)
    return []
  }
}

const getOnePointOfSale = async (id) => {
  try {
    const snapshot = await collectionRef.doc(id).get()
    return {
      ...snapshot.data(),
      id: snapshot.id
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

const pointOfSale = {
  create: createPointOfSale,
  update: updatePointOfSale,
  delete: deletePointOfSale,
  getAll: getAllPointOfSale,
  getOne: getOnePointOfSale,
  mekeSecureData
}

export default pointOfSale
