/* eslint-disable react/prop-types */
import React from 'react'
import { Dialog } from '@material-ui/core'
import styled from 'styled-components'

const AlertShipping = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Box>
        <Body>
          <Describe>Lo sentimos, por el momento no tenemos servicio en tu zona</Describe>
        </Body>
      </Box>
    </Dialog>
  )
}

const Body = styled.div`
  width: 80vw;
  text-align: center;
  margin: auto;
  @media screen and (max-width: 480px) {
    width: 95vw;
  }
`

const Box = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  min-height: 400px;
  padding: 20px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: auto;
    min-height: 300px;
  }
`
const Describe = styled.p`
  font-size: 1.8em;
`

export default AlertShipping
