import React, { useEffect } from 'react'
import { Content, Box, Input, Leggend, BoxSeparator } from './styled'
import { Grid, useMediaQuery, Checkbox, Box as BoxMaterial } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { array, string } from 'prop-types'
import styled from 'styled-components'

const Sibtitle = styled.div`
  font-size: 32px;
  font-family: 'bebas';
  margin-bottom: 15px;
`

const Shipping = props => {
  const isMobile = useMediaQuery('(max-width:600px)')
  
  // scroll into top when show error message
  useEffect(() => {
    if (props.errorMessage) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [props.errorMessage])

  return (
    <Content>
      <Box>
        {!!props.errorMessage && (
          <Alert style={{ marginBottom: '10px' }} severity='error'>{props.errorMessage}</Alert>
        )}
        <BoxSeparator as={Grid} container>
          <Grid item xs={12}>
            <Sibtitle>Información de contacto</Sibtitle>
          </Grid>
          <Grid item xs={12} sm={6} style={{ paddingRight: isMobile ? '0px' : '10px' }}>
            <Input size={isMobile ? 'small' : 'medium'} {...props} name='name' placeholder='Nombre' maxlength={50} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input size={isMobile ? 'small' : 'medium'} {...props} name='lastname' placeholder='Apellidos' maxlength={50} />
          </Grid>
          <Input size={isMobile ? 'small' : 'medium'} {...props} name='number' placeholder='Tel./cel.' filter='number' maxlength={10} />
          <Input size={isMobile ? 'small' : 'medium'} {...props} name='email' placeholder='Correo electrónico' maxlength={70} />
          <Leggend>En caso de que tengamos que contactarte sobre tu pedido</Leggend>
        </BoxSeparator>
        <BoxSeparator container as={Grid}>
          <Grid item xs={12}>
            <Sibtitle>Dirección</Sibtitle>
          </Grid>
          <Input size={isMobile ? 'small' : 'medium'} {...props} name='street_number' placeholder='Calle y número' maxlength={100} />
          <Input size={isMobile ? 'small' : 'medium'} {...props} name='suburb' placeholder='Colonia' maxlength={50} />
          <Input size={isMobile ? 'small' : 'medium'} {...props} name='city' placeholder='Ciudad' maxlength={50} />
          <Grid item xs={6} style={{ paddingRight: '10px' }}>
            <Input size={isMobile ? 'small' : 'medium'} {...props} name='state' placeholder='Estado' maxlength={50} />
          </Grid>
          <Grid item xs={6}>
            <Input size={isMobile ? 'small' : 'medium'} {...props} name='postal_code' placeholder='Código Postal' filter='number' maxlength={5} />
          </Grid>
          <BoxMaterial width="100%" marginBottom="1em">
            <Grid alignItems="center" container>
              <Checkbox
                color="primary"
                id="checkSendType"
                checked={props.data.shippingOnDelivery || false}
                onChange={e => props.setData({ shippingOnDelivery: e.target.checked })}
              />
              <BoxMaterial component="label" fontWeight="bold" fontSize="1.2em" for="checkSendType">
                Pagar costo de envío al recibir pedido.
              </BoxMaterial>
            </Grid>
          </BoxMaterial>
        </BoxSeparator>
      </Box>
    </Content>
  )
}

Shipping.propTypes = {
  errors: array,
  errorMessage: string
}

export default Shipping
