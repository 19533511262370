/* eslint-disable react/prop-types */
import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Box, useMediaQuery } from '@material-ui/core'
import Button from '../components/button'
import Picture from '../../components/picture'

const Item = (props) => {
  const IsMobile = useMediaQuery('(max-width:600px)')

  return (
    <WrapperItem to={`/producto/${props.url}`}>
      <ItemBody>
        <Picture style={{ objectFit: 'contain', background: '#D6D6D6' }} src={props.picture} height={71.5} />
        <Box pt={2} pb={2}>
          <DataCOntainer container>
            <Title>{props.title}</Title>
            <ButtonContainer $related={props.related} ml={1}>
              <Button $fontSize={IsMobile ? '18px' : '1.3em'} $disableIcon>
                Mas información
              </Button>
            </ButtonContainer>
          </DataCOntainer>
        </Box>
      </ItemBody>
    </WrapperItem>
  )
}

Item.propTypes = {
  url: propTypes.string,
  picture: propTypes.string,
  title: propTypes.string
}

const WrapperItem = styled(Link)`
  display: block;
  color: inherit;
  text-decoration: none;
  box-sizing: border-box;
  height: 100%;
`

const ItemBody = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 300ms;
`
const Title = styled.h2`
  font-size: 2.6em;
  font-family: 'bebas';
  color: var(--user-black);
  padding: 0px;
  margin: 0px;
  text-align: left;
  line-height: 1em;
  max-width: 50%;
  @media (max-width:1000px) {
    font-size: 35px;
  }
  @media (max-width:400px) {
    font-size: 25px;
  }
`
const DataCOntainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 12px 0px;
`

const ButtonContainer = styled(Box)`
  @media screen and (max-width: 1100px) {
    display: ${props => props.$related ? 'none' : ''}
  }
`
export default Item
