import axios from 'axios'
import { apiBaseUrl } from './config'

const connect = async config => {
  try {
    const defautlConfig = {
      method: 'post'
    }
    const response = await axios({ ...defautlConfig, ...config, url: `${apiBaseUrl}${config.url}` })
    return response.data
  } catch (error) {
    try {
      console.log(error.response.data)
      return error.response.data
    } catch (error) {
      return {}
    }
  }
}

export const payWidthCard = data => {
  return connect({
    url: '/payments/card',
    data
  })
}

export const payWithStore = data => {
  return connect({
    url: '/payments/store',
    data
  })
}

export const payWithSpei = data => {
  return connect({
    url: '/payments/spei',
    data
  })
}

export const get = id => {
  return connect({
    method: 'get',
    url: `/payments/${id}`
  })
}

// sendMail
export const send = (data) => {
  return connect({
    method: 'post',
    url: '/email/send',
    data
  })
}

export default {
  payouts: {
    card: payWidthCard,
    store: payWithStore,
    spei: payWithSpei,
    get: get
  },
  email: {
    send
  }
}
