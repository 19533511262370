/* eslint-disable react/prop-types */
import { ThemeProvider, createMuiTheme } from '@material-ui/core'
import React from 'react'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000'
    }
  }
})

const Provider = props => (
  <ThemeProvider theme={theme}>
    {props.children}
  </ThemeProvider>
)

export default Provider
