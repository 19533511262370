export const ENV = process.env.REACT_APP_RUNTIME

const specificData = [
  { type: 'int', key: 'keyname', label: 'text to show', max: 99, min: 0 },
  { type: 'text', key: 'keynametext', label: 'text to show', maxLength: 99 },
  { type: 'select', key: 'keynameselect', label: 'text to show', options: [{ a: 'valuea' }, { b: 'valueb' }, { c: 'valuec' }] },
  { type: 'radio', keyname: 'keynameradio', label: 'label radio', options: [{ a: 'valuea' }, { b: 'valueb' }, { c: 'valuec' }] },
  { type: 'checkbox', keyname: 'keynameCheck', label: 'checkbox', options: [] }
]

const openPayCredentialsList = {
  production: {
    merchantId: 'mh4zboijc8lk9chg2o0w',
    apiKey: 'pk_d2d4808a96ec43359b56e674b0975cb0',
    isdevelopmentMode: false,
  },
  development: {
    merchantId: 'm5fnyled0han7jtscby7',
    apiKey: 'pk_aa3a841d40de4bdab43699fb2fa87a9b',
    isdevelopmentMode: true,
  }
}

console.group()
  console.log('id', openPayCredentialsList[ENV].merchantId)
  console.log('key', openPayCredentialsList[ENV].apiKey)
  console.log('isDevelopment', openPayCredentialsList[ENV].isdevelopmentMode)
console.groupEnd()

// openpay config
window.OpenPay.setApiKey(openPayCredentialsList[ENV].apiKey)
window.OpenPay.setId(openPayCredentialsList[ENV].merchantId)
window.OpenPay.setSandboxMode(openPayCredentialsList[ENV].isdevelopmentMode)

// firebase lis config
const firebaseList = {
  production: {
    apiKey: 'AIzaSyBAJFlI4rAIU5Fyt4l4LGOvaAQLcEMG2Bo',
    authDomain: 'asadores-fd5ac.firebaseapp.com',
    databaseURL: 'https://asadores-fd5ac.firebaseio.com',
    projectId: 'asadores-fd5ac',
    storageBucket: 'asadores-fd5ac.appspot.com',
    messagingSenderId: '395893304912',
    appId: '1:395893304912:web:04055e2b89725360812b55'
  },
  development: {
    apiKey: "AIzaSyC1cSzxSOfguh2GH4WXT2ccO1Si4COdWLA",
    authDomain: "asadores-development.firebaseapp.com",
    projectId: "asadores-development",
    storageBucket: "asadores-development.appspot.com",
    messagingSenderId: "722660118522",
    appId: "1:722660118522:web:1baa436829f0f98871de43"
  }
};


export const apiBaseUrl = ENV === 'production'
  ? 'https://us-central1-asadores-fd5ac.cloudfunctions.net/main'
  : 'https://us-central1-asadores-development.cloudfunctions.net/main'
// cover pictures
export const coverPictures = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60'
  },
  {
    label: 'Bird',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60'
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80'
  },
  {
    label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
    imgPath:
      'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60'
  },
  {
    label: 'Goč, Serbia',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60'
  }
]

// contacts
const contacts = {
  email: 'somos@somos.work',
  number: '5589876543'
}

const message = {
  copyright: `${new Date().getFullYear()} derechos reservados`
}

export const companyName = 'Asadores del norte'

export const payDayLimitForStore = 3
export const payDayLimitForBank = 3
export const taxPorcent = 0
export const limitCheckout = 10000
export const shipping = 0

export const firebase = firebaseList[ENV]
export const merchantId = openPayCredentialsList[ENV].merchantId

export default {
  firebase,
  specificData,
  contacts,
  message,
  coverPictures
}

