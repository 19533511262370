import React from 'react'
import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const SkeletonC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Skeleton variant='rect' height={150} />
        <Skeleton variant='text' height={30} />
        <Skeleton variant='text' height={30} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Skeleton variant='rect' height={150} />
        <Skeleton variant='text' height={30} />
        <Skeleton variant='text' height={30} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Skeleton variant='rect' height={150} />
        <Skeleton variant='text' height={30} />
        <Skeleton variant='text' height={30} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Skeleton variant='rect' height={150} />
        <Skeleton variant='text' height={30} />
        <Skeleton variant='text' height={30} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Skeleton variant='rect' height={150} />
        <Skeleton variant='text' height={30} />
        <Skeleton variant='text' height={30} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Skeleton variant='rect' height={150} />
        <Skeleton variant='text' height={30} />
        <Skeleton variant='text' height={30} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Skeleton variant='rect' height={150} />
        <Skeleton variant='text' height={30} />
        <Skeleton variant='text' height={30} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Skeleton variant='rect' height={150} />
        <Skeleton variant='text' height={30} />
        <Skeleton variant='text' height={30} />
      </Grid>
    </Grid>
  )
}

export default SkeletonC
