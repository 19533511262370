/* eslint-disable no-unused-vars */
import React from 'react'
import { SwipeableDrawer, IconButton, Box } from '@material-ui/core'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CoverSrc from '../../assets/cover2.jpg'
import useHeaderTop from '../../hooks/useHeaderheight'
import { Facebook, Instagram, YouTube, Pinterest, WhatsApp, Mail } from '@material-ui/icons'
import { HashLink } from 'react-router-hash-link'
import usePointOfSaleList from '../../hooks/usePointOfSaleList'

const MenuLEft = props => {
  const top = useHeaderTop()
  const { items: pointsOfSale } = usePointOfSaleList();

  return (
    <SwipeableDrawer
      open={props.open}
      onClose={props.handleClose}
      onOpen={props.handleOpen}
      anchor='right'
    >
      <Container onClick={props.handleClose}>
        <Body>
          <Box pt={5} pb={5}>
            <LinkStyled as={HashLink} to='/#initial'>Home</LinkStyled>
            <LinkStyled to={{ pathname: '/productos/steakhouses', state: { category: 'steakhouses' } }}>Asadores</LinkStyled>
            <LinkStyled to={{ pathname: '/productos/buildyourgrill', state: { category: 'buildyourgrill' } }}>Arma tu asador</LinkStyled>
            <LinkStyled to={{ pathname: '/productos/accesories', state: { category: 'accesories' } }}>Accesorios</LinkStyled>
            <LinkStyled as={HashLink} to='/#adn' scroll={el => window.scrollTo(0, (el.offsetTop - 100))}>ADN</LinkStyled>
            {!!pointsOfSale && !!pointsOfSale.length && (
              <LinkStyled as={HashLink} to='/#pointsales' scroll={el => window.scrollTo(0, (el.offsetTop - 50))}>Puntos de venta</LinkStyled>
            )}
            <LinkStyled as={HashLink} to='/#contact'>Contacto</LinkStyled>
          </Box>
          <SocialCOntainer>
            <SocialgridItem>
              <div>
                SÍGUENOS EN REDES
              </div>
              <div>
                <a href='https://www.facebook.com/asadoresdelnorte' target='_blank' rel='noreferrer'>
                  <IconButton size='small'>
                    <Facebook />
                  </IconButton>
                </a>
                <a href='https://www.instagram.com/asadoresnorte' target='_blank' rel='noreferrer'>
                  <IconButton size='small'>
                    <Instagram />
                  </IconButton>
                </a>
                <a href='https://www.youtube.com/user/AsadoresdelNorte' target='_blank' rel='noreferrer'>
                  <IconButton size='small'>
                    <YouTube />
                  </IconButton>
                </a>
                <a href='https://www.pinterest.com.mx/asadoresdelnorte/' target='_blank' rel='noreferrer'>
                  <IconButton size='small'>
                    <Pinterest />
                  </IconButton>
                </a>
              </div>
            </SocialgridItem>
            <Line />
            <SocialgridItem>
              <div>
                CONTÁCTANOS
              </div>
              <div>
                <a href='https://wa.me/message/V5E2YBZXZHLHM1' target='_blank' rel='noreferrer'>
                  <IconButton size='small'>
                    <WhatsApp />
                  </IconButton>
                </a>
                <a href='mailto:VENTAS@ASADORESDELNORTE.COM'>
                  <IconButton size='small'>
                    <Mail />
                  </IconButton>
                </a>
              </div>
            </SocialgridItem>
          </SocialCOntainer>
        </Body>
      </Container>
    </SwipeableDrawer>
  )
}

MenuLEft.propTypes = {
  open: propTypes.bool,
  handleClose: propTypes.func,
  handleOpen: propTypes.func
}

const Container = styled.nav`
  width: 100vw;
  padding: 10px;
  background-image: url(${CoverSrc});
  background-position: center;
  background-size: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
`
const Body = styled.div`
  width: 85%;
  margin: auto;
`
const LinkStyled = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  color: #000;
  font-family: 'bebas';
  font-size: 50px;
  line-height: 1em;
  @media screen and (max-height:650px) {
    font-size: 40px;
  }
`
const SocialCOntainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  font-family: 'bebas';
  font-size: 22px;
  svg {
    color: #000;
  }
`
const SocialgridItem = styled.div`
  text-align: center;
`
const Line = styled.div`
  border-left: 1px solid #000;
  min-height: 20px;
`

export default MenuLEft
