const formatPhone = (phone) => {
  try {
    if (phone.toString().length !== 10) return phone
    const parsed = phone.toString()
    const array = [...parsed]
    return `${array[0]}${array[1]}${array[2]} ${array[3]}${array[4]}${array[5]} ${array[6]}${array[7]}${array[8]}${array[9]}`
  } catch (error) {
    return phone
  }
}

export default formatPhone
