import React, { useState } from 'react'
import useObjectState from '../../../../hooks/useObjectState'
import styled from 'styled-components'
import validateForm from '../../../../helpers/validateform'
import message from '../../../../modules/message'
import { Box, Button, Grid, CircularProgress, useMediaQuery } from '@material-ui/core'
import Footer from '../../../components/footer'
import cover3Src from '../../../../assets/cover3.jpg'
import { Alert } from '@material-ui/lab'
import { limitLength, toNumber } from '../../../../helpers/input_parser'
import { TrendingFlat } from '@material-ui/icons'

const FullWidth = styled.div`
  background: rgb(255,255,255);
  background-blend-mode: color;
  background: linear-gradient(1deg, rgba(255,255,255,0) 10%, rgba(255,255,255,1) 90%);
  background-image: url(${cover3Src});
  background-position: center;
  background-size: cover;
`

const Container = styled.div`
  padding:10px;
  padding-top: 0px;
  padding-bottom: 150px;
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width:959px) {
    width: 80%;
    flex-wrap: wrap;
    text-align: center;
    padding-bottom: 120px;
  }
`

const ContactStyled = styled.div`
  font-size: 106px;
  font-family: 'bebas';
  @media screen and (max-width:959px) {
    font-size: 58px;
  }
`
const ContactWrapperLeft = styled.div`
  width: 40%;
  @media screen and (max-width:959px) {
    width: 100%;
  }
`

const Form = styled.div`
  width: 40%;
  @media screen and (max-width:959px) {
    width: 100%;
  }
`
const ProgressContainer = styled(Form)`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderText = styled.div`
  font-size: 16px;
  margin-bottom: 25px;
  font-family: 'gotham';
`

const Input = styled.input`
  padding: 10px 0px;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: ${props => props.$error ? '1px solid red' : '1px solid #000'};
  background: none;
  font-size: 27px;
  font-family: 'bebas';
  margin-bottom: 20px;
  ::placeholder {
    color: ${props => props.$error ? 'red' : '#000'};
  }
  @media screen and (max-width:959px) {
    font-size: 30px;
  }
`
const TextArea = styled.textarea`
  min-height: 150px;
`

const ButtonStyled = styled(Button)`
  display: flex;
  font-family: 'bebas';
  font-size: 27px;
  border-radius: 0px;
  padding: 0px 20px;
  border: 1px solid #000;
  svg {
    margin-left: 10px;
    font-size: 30px;
  }
  @media screen and (max-width:1500px) {
    font-size: 20px;
    padding: 0px 12px;
  }
  @media screen and (max-width:1168px) {
    font-size: 18px;
  }
  @media screen and (max-width:959px) {
    font-size: 25px;
    padding: 0px 20px;
  }

  :hover {
    background-color: #000;
    color: #fff;
  }
`
const ButtonWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width:450px) {
    flex-direction: column;
    button {
      min-width: 200px;
      margin-bottom: 1em;
    }
  }
`

const Gradient = styled.div`
  height: 100px;
  background: linear-gradient(0deg,rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
`

const ButtonsComponent = () => (
  <ButtonWrapper display='flex' flexWrap='wrap'>
    <a href='tel:8683521850'>
      <ButtonStyled color='primary' variant='outlined' size='small'>
        T. 868 352 1850
      </ButtonStyled>
    </a>
    <Box paddingRight='1em' />
    <a href='https://wa.me/message/V5E2YBZXZHLHM1' target='_blank'>
      <ButtonStyled color='primary' variant='outlined' size='small'>
        WhatsApp
      </ButtonStyled>
    </a>
  </ButtonWrapper>
)

const Contact = props => {
  const [data, setData] = useObjectState({})
  const [currentView, setCurrentView] = useState('form')
  const [errors, setErrors] = useState({ errors: [], message: null })

  const isMobile = useMediaQuery('(max-width:600px)')
  const isMedium = useMediaQuery('(max-width:959px)')

  const handleChange = (target, limit = 10, isNumber) => {
    var value = target.value
    if (isNumber) value = toNumber(value)
    value = limitLength(value, limit)
    console.log('value', value, limit)
    setData({ [target.name]: value })
  }

  const handleSend = async event => {
    const inputsWithErrors = validateForm.requires(data, ['name', 'email', 'number', 'content'])
    if (inputsWithErrors) setErrors({ errors: inputsWithErrors, message: 'Todos los campos son requeridos' })
    else if (!validateForm.validateEmail(data.email)) {
      setErrors({ errors: ['email'], message: 'El correo no es válido' })
    } else {
      setCurrentView('loading')
      await message.add(data)
      await new Promise(resolve => setTimeout(resolve, 2000))
      setCurrentView('success')
    }
  }

  const handleRemoveErrors = params => {
    console.log(params)
    const NewErrors = errors.errors.filter(error => error !== params.name)
    setErrors({
      errors: NewErrors,
      message: NewErrors.length > 0 ? errors.message : null
    })
  }

  return (
    <FullWidth id='contact'>
      <Gradient />
      <Container>
        <ContactWrapperLeft>
          <ContactStyled>CONTACTO</ContactStyled>
          {!isMedium && (
            <ButtonsComponent />
          )}
        </ContactWrapperLeft>
        {currentView === 'form' && (
          <Form>
            <HeaderText>
              Si tienes alguna duda o comentario favor de llenar el siguiente formulario, en
              breve uno de nuestros representantes se pondrá en contacto contigo.
            </HeaderText>
            {isMedium && (
              <Box textAlign='center' marginBottom='1em' display='flex' justifyContent='center'>
                <ButtonsComponent />
              </Box>
            )}
            <Box>
              {errors.message && (
                <Box mb={2}>
                  <Alert severity='error'>{errors.message}</Alert>
                </Box>
              )}
              <Input value={data.name || ''} $error={errors.errors.includes('name')} onChange={event => handleChange(event.target, 50)} name='name' placeholder='NOMBRE' onFocus={event => handleRemoveErrors(event.target)} />
              <Input value={data.number || ''} $error={errors.errors.includes('number')} onChange={event => handleChange(event.target, 10, true)} name='number' placeholder='TELÉFONO' onFocus={event => handleRemoveErrors(event.target)} />
              <Input value={data.email || ''} $error={errors.errors.includes('email')} onChange={event => handleChange(event.target, 80)} name='email' placeholder='MAIL' onFocus={event => handleRemoveErrors(event.target)} />
              <Input value={data.content || ''} $error={errors.errors.includes('content')} onChange={event => handleChange(event.target, 280)} name='content' as={TextArea} placeholder='MENSAJE' onFocus={event => handleRemoveErrors(event.target)} />
              <Grid container justify='flex-end'>
                <ButtonStyled color='primary' variant='outlined' size='small' onClick={handleSend}>
                  ENVIAR <TrendingFlat />
                </ButtonStyled>
              </Grid>
            </Box>
          </Form>
        )}
        {currentView === 'loading' && (
          <ProgressContainer>
            <CircularProgress />
          </ProgressContainer>
        )}
        {currentView === 'success' && (
          <ProgressContainer container>
            <Grid item xs={12} md={12}>
              <Box fontSize={isMobile ? '2em' : '2.4em'} fontFamily='bebas'>
                Hemos recibido tu mensaje <br /> Gracias por contactarnos
              </Box>
            </Grid>
          </ProgressContainer>
        )}
      </Container>
      <Footer />
    </FullWidth>
  )
}

export default Contact
