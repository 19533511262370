import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Input from '../../components/input'
import Dialog from '@material-ui/core/Dialog'
import useObjectState from '../../../hooks/useObjectState'
import { Box, Grid, CircularProgress, Typography } from '@material-ui/core'
import styled from 'styled-components'
import currencyParser from '../../../helpers/currency'
import { toNumber } from '../../../helpers/input_parser'
import { requires } from '../../../helpers/validateform'
import { Alert } from '@material-ui/lab'
import { add } from '../../../modules/shipping'

const Container = styled.div`
  width: 500px;
  padding: 80px;
  box-sizing: border-box;
  @media screen and (max-width:1000px) {
    width: 400px;
    padding: 30px;
  }
  @media screen and (max-width:500px) {
    width: 80vw;
    padding: 30px;
  }
`

export default function FormDialog (props) {
  const [state, setState, setStricState] = useObjectState(props.data || {})
  const [currentView, setCurrentView] = useState('form') // form, loading
  const [errors, setErrors] = useState([])

  const handleChange = ({ name, value }) => {
    setState({ [name]: value })
  }

  const handleSave = async () => {
    const errors = requires(state, ['cp', 'cost', 'smallCost'])
    if (errors.length) return setErrors(errors)
    setCurrentView('loading')
    await add({
      cp: state.cp,
      cost: state.cost,
      smallCost: state.smallCost,
      comment: state.comment || null
    })
    props.onClose()
    if (props.onSuccess) props.onSuccess()
    setCurrentView('form')
    setStricState({})
  }

  const removeError = ({ name }) => {
    if (errors.includes(name)) {
      setErrors(errors.filter(error => error !== name))
    }
  }

  // sync data width props
  useEffect(() => {
    if (props.data) {
      setState(props.data)
    }
  }, [props.data])

  // reset data where close
  useEffect(() => {
    if (!props.open) setStricState({})
  }, [props.open])

  return (

    <Dialog open={props.open} onClose={props.onClose}>
      <Container>
        {currentView === 'form' && (
          <>
            <Typography variant='h6' color='primary'>Agregar codigo postal</Typography>
            <div>
              {!!errors.length && (
                <Box pt={2}>
                  <Alert severity='error'>Algunos campos son requeridos</Alert>
                </Box>
              )}
              <Box pt={1}>
                <Box color='var(--main-blue)'>Código postal</Box>
                <Input
                  disabled={!!props.data}
                  removeError={removeError}
                  filter='number'
                  fullWidth
                  value={state.cp || ''}
                  margin='normal'
                  size='small'
                  name='cp'
                  type='text'
                  onChange={({ name, value }) => handleChange({ name, value: value ? toNumber(value, 5) : '' })}
                  errors={errors}
                />
              </Box>
              <Box pt={1}>
                <Box color='var(--main-blue)'>Costo asadores</Box>
                <Input
                  removeError={removeError}
                  errors={errors}
                  fullWidth
                  type='text'
                  margin='normal'
                  size='small'
                  placeholder=''
                  name='cost'
                  value={state.cost ? currencyParser.toCurrency(state.cost) : ''}
                  onChange={({ name, value }) => handleChange({ name, value: currencyParser.toNumber(value) })}
                />
              </Box>
              <Box pt={1}>
                <Box color='var(--main-blue)'>Costo accesorios</Box>
                <Input
                  removeError={removeError}
                  errors={errors}
                  fullWidth
                  type='text'
                  margin='normal'
                  size='small'
                  name='smallCost'
                  value={state.smallCost ? currencyParser.toCurrency(state.smallCost) : ''}
                  onChange={({ name, value }) => handleChange({ name, value: currencyParser.toNumber(value) })}
                />
              </Box>
              <Box pt={1}>
                <Box color='var(--main-blue)'>Comentario</Box>
                <Input
                  value={state.comment || ''}
                  fullWidth
                  margin='normal'
                  size='small'
                  name='comment'
                  type='text'
                  onChange={handleChange}
                />
              </Box>
              <Box mt={2}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button onClick={props.onClose} style={{ textTransform: 'none' }} fullWidth variant='outlined' color='primary'>
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button onClick={handleSave} style={{ textTransform: 'none' }} fullWidth variant='contained' color='primary'>
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </>
        )}
        {currentView === 'loading' && (
          <Grid container justify='center' alignItems='center' style={{ minHeight: '200px' }}>
            <CircularProgress />
          </Grid>
        )}
      </Container>
    </Dialog>
  )
}
