/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Content, ActionsContent, ButtonContent } from './styled'
import Layout from '../../components/layout_user'
import Container from '../../components/container'
import Summary from './components/summary'
import Shipping from './components/shiping'
import MethodPay from './components/method_pay'
import InfoCard from './components/credit_card_form'
import Confirm from './components/confirm'
import { number, string, arrayOf, func } from 'prop-types'
import styled from 'styled-components'
import useHeaderheight from '../../../hooks/useHeaderheight'
import { Box } from '@material-ui/core'
import Breadcrumbs from '../../components/backdream'
import Footer from '../../components/footer'

const ContainerStyled = styled(Container)`
  margin-top: ${props => props.$top || '60px'};
  @media screen and (max-width:900px) {
    max-width: 100%!important;
    width: 100%!important;
  }
`

const View = props => {
  const top = useHeaderheight()

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const titlesForBreadCrumbs = [
    'Información de tu cuenta',
    'Pagos',
    'Pagos'
  ]

  return (
    <Layout>
      <div style={{ marginTop: top }}>
        <Breadcrumbs steps={[{ label: titlesForBreadCrumbs[props.currentStep] }]} />
        <ContainerStyled $top={1}>
          <Box pt={{ xs: 3, md: 4, lg: 4 }} pb={{ xs: 3, md: 7, lg: 9 }}>
            <Content>
              <ActionsContent>
                {props.currentStep === 0 && (
                  <Shipping {...props} />
                )}
                {props.currentStep === 1 && (
                  <MethodPay {...props} />
                )}
                {props.currentStep === 2 && (
                  <InfoCard {...props} />
                )}
              </ActionsContent>
              <Summary {...props} />
            </Content>
          </Box>

        </ContainerStyled>
      </div>
      <Footer />
    </Layout>
  )
}

View.propTypes = {
  currentStep: number,
  steps: arrayOf(string),
  goToStep: func,
  handleNext: func
}

export default View
