/* eslint-disable react/prop-types */
import ButtonBase from '../../components/link'
import styled from 'styled-components'
import React from 'react'
import { oneOfType, array, string, object, node, element, func } from 'prop-types'
import { TrendingFlat } from '@material-ui/icons'

// const Button = styled(ButtonBase)`
//   width: 48%;
//   font-size: 1.2em;
//   padding: 10px 0px;
//   border-radius: 5px;
//   color: ${props => props.variant === 'contained' ? '#fff' : 'initial'};
//   border: ${props => props.variant === 'contained' ? '' : '1px solid var(--user-gray-light)'};
//   background: ${props => props.variant === 'contained' ? 'var(--user-black)' : 'none'};
// `

const Button = styled(ButtonBase)`
  font-family: 'bebas';
  font-size: ${props => props.$fontSize || '1em'};
  color: #000;
  border: 1px solid #000;
  padding: 5px 10px;
  svg {
    font-size: 1.1em;
    line-height:1em;
  }
  :hover {
    background: #000;
    color: #fff;
  }
`

const ButtonPrymary = props => {
  const otherProps = {}
  if (props.onClick) otherProps.handleClick = props.onClick

  return (
    <Button $fontSize={props.$fontSize} className={props.className || ''} {...otherProps}>
      {props.children}
      {!props.$disableIcon && (
        <>
          {props.icon ? (props.icon) : (<TrendingFlat />)}
        </>
      )}
    </Button>
  )
}

ButtonPrymary.propTypes = {
  children: oneOfType([array, string, object, node, element]),
  $fontSize: string,
  className: string,
  icon: oneOfType([string, array, object]),
  onClick: func
}

export default ButtonPrymary
