import { Box } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { bool, func, instanceOf, number, string } from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { discountTypes } from '../../../constants'
import currency from '../../../helpers/currency'
import { toStringShort } from '../../../helpers/date'

const Item = (props) => {
  return (
    <Row $header={props.header}>
      <CellCode>
        <OnlyMobile>Código:</OnlyMobile>
        {props.header ? 'Código' : props.code}
      </CellCode>
      <ValueCell>
        <OnlyMobile>Valor:</OnlyMobile>
        {props.header ? 'Valor' : props.type === 'porcent' ? `${props.value}%` : `$${currency.formatMoney(props.value)}`}
      </ValueCell>
      <MinPriceCell>
        <OnlyMobile>Precio mínimo:</OnlyMobile>
        {props.header ? 'Precio mínimo' : `$${currency.formatMoney(props.minPrice)}`}
      </MinPriceCell>
      <TypeCell>
        <OnlyMobile>Tipo:</OnlyMobile>
        {props.header ? 'Tipo' : discountTypes[props.type]}
      </TypeCell>
      <StateCell>
        <OnlyMobile>Vencimiento:</OnlyMobile>
        {props.header ? 'Vencimiento' : toStringShort(props.deadLine)}
      </StateCell>
      {props.header
        ? <Box style={{ opacity: 0 }}><Box><DeleteStyled /></Box></Box>
        : <Box><DeleteStyled onClick={props.onDelete} /></Box>}
    </Row>
  )
}

Item.propTypes = {
  code: string.isRequired,
  value: number.isRequired,
  minPrice: number.isRequired,
  isUsed: bool,
  header: bool,
  type: string,
  deadLine: instanceOf(Date),
  onDelete: func.isRequired
}

const Row = styled.div`
  font-weight: ${(args) => (args.$header ? 'bold' : 'normal')};
  border-bottom: 1px solid #cdcdcd;
  padding: 1em 0em;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width:600px) {
    flex-direction: column;
    line-height: 2em;
    position: relative;
    font-size: 1.1em;
  }
`

const CellCode = styled.div`
  width: 20%;
  @media screen and (max-width:1000px) {
    width: 25%;
  }
  @media screen and (max-width:600px) {
    width: 100%;
  }
`

const ValueCell = styled.div`
  width: 15%;
  @media screen and (max-width:1000px) {
    width: 25%;
  }
  @media screen and (max-width:600px) {
    width: 100%;
  }
`
const MinPriceCell = styled.div`
  width: 16%;
  @media screen and (max-width:1000px) {
    width: 20%;
  }
  @media screen and (max-width:600px) {
    width: 100%;
  }
`
const TypeCell = styled.div`
  width: 15%;
  @media screen and (max-width:1000px) {
    display: none;
  }
  @media screen and (max-width:600px) {
    width: 100%;
    display: block;
  }
`
const StateCell = styled.div`
  width: 15%;
  @media screen and (max-width:1000px) {
    width: 20%;
  }
  @media screen and (max-width:600px) {
    width: 100%;
  }
`
const OnlyMobile = styled.div`
  display: none;
  margin-right: .7em;
  @media screen and (max-width:600px) {
    display: inline;
    color: gray;
  }
`
const DeleteStyled = styled(Delete)`
  font-size: 1.4em;
  cursor: pointer;
  @media screen and (max-width:600px) {
    position: absolute;
    top: 1em;
    right: 1em;
  }
`
export default Item
