/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import LayoutUser from '../../components/layout_user'
import useHeaderheight from '../../../hooks/useHeaderheight'
import Footer from '../../components/footer'
import { Box, Paper } from '@material-ui/core'
import WrapperResponsive from './responsiveCss'
import styled from 'styled-components'

const Container = styled(Paper)`
  width: 60%;
  margin: auto;
  margin-top: 5em!important;
  margin-bottom: 5em!important;
  box-sizing: border-box;
  padding: 8em;
  @media screen and (max-width:1550px) {
    padding: 5em;
    width: 80%;
  }
  @media screen and (max-width:1200px) {
    padding: 2em;
    width: 90%;
  }
`

const Privacity = (props) => {
  const top = useHeaderheight()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <LayoutUser>
      <Box minHeight='100vh' mt={top} padding='1px'>
        <Container elevation={3}>
          <WrapperResponsive>
            <div dangerouslySetInnerHTML={{
              __html: `
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:24pt; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:2em; color:#1a1a1a;">Aviso de Privacidad</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#1a1a1a;">Aviso Importante</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">A todos nuestros Usuarios de www.asadoresdelnorte.com, o para todo aquel Titular de Datos Personales frente a Asadores del Norte, hacemos de su conocimiento que para mejorar la seguridad de los Datos Personales que proporcionaron a Asadores del Norte y tengan mayor conocimiento del tratamiento que se les da a dichos datos, hicimos reformas en el Aviso de Privacidad Integral de Asadores del Norte, el cual pueden consultar Aqu&iacute;. El Aviso de Privacidad que entr&oacute; en vigor el 18 de Enero de 2016, en caso que usted no est&eacute; de acuerdo con las Reformas al Aviso de Privacidad, por favor h&aacute;galo de nuestro conocimiento antes del momento en que entre en vigor al correo electr&oacute;nico ventas@asadoresdelnorte.com.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><strong><span style="font-family:'Times New Roman'; color:#000;">ASADORES DEL NORTE.&nbsp;</span></strong><span style="font-family:'Times New Roman'; color:#000;">en lo sucesivo la &ldquo;Empresa&rdquo;, expide el presente Aviso de Privacidad, para que el Titular de los datos personales, de ahora en adelante denominado &ldquo;Titular&rdquo;, tenga conocimiento del trato que la Empresa les da a los Datos Personales.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa declara que la Pol&iacute;tica de Privacidad asegura que cualquier informaci&oacute;n que el Titular provea ser&aacute; mantenida privada, segura y en calidad de confidencial. Para dar fe de esto, en este documento la Empresa se&ntilde;ala los detalles de qu&eacute; informaci&oacute;n se recaba y de qu&eacute; manera es utilizada.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Titular reconoce que la informaci&oacute;n proporcionada sobre s&iacute; mismo es verdadera, correcta, actual y completa, responsabiliz&aacute;ndose civil y penalmente de dicha informaci&oacute;n.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa se reserva el derecho de ejercer Actos Discrecionales para la Revisi&oacute;n de los Datos Personales.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La informaci&oacute;n de los titulares o los medios de comunicaci&oacute;n entre los titulares y la empresa puede ser de manera verbal, escrita, medios electr&oacute;nicos, &oacute;ptica y de otra tecnolog&iacute;a como correo postal, Internet o v&iacute;a telef&oacute;nica.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Para efectos del presente Aviso de Privacidad, existen distintos tipos de Titulares, en cada uno de los tipos de titulares ser&aacute;n distintos los Datos Personales recabados y las finalidades del tratamiento de los mencionados. Los tipos de titulares ser&aacute;n los siguientes:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">Titulares Usuarios en Entorno digital</span></em><br><em><span style="font-family:'Times New Roman'; color:#000;">Titulares Usuarios de la P&aacute;gina de Internet</span></em><br><em><span style="font-family:'Times New Roman'; color:#000;">Titulares con los que la Empresa guarda una relaci&oacute;n Laboral</span></em><br><em><span style="font-family:'Times New Roman'; color:#000;">Titulares con los que la Empresa guarda una relaci&oacute;n Contractual</span></em><br><em><span style="font-family:'Times New Roman'; color:#000;">Titulares con los que la Empresa guarda una relaci&oacute;n espor&aacute;dica jur&iacute;dica o no jur&iacute;dica</span></em></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Titulares Usuarios en Entorno Digital</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Titular Usuario en Entorno Digital, es aquel que proporciona sus datos personales para contratar los Servicios que presta la Empresa en un entorno Digital, a trav&eacute;s de su P&aacute;gina de Internet (www.asadoresdelnorte.com) o en la Aplicaci&oacute;n inform&aacute;tica, en lo sucesivo y en conjunto, la &ldquo;P&aacute;gina&rdquo;, &ldquo;P&aacute;gina de Internet&rdquo;, sitio &ldquo;Web&rdquo; o &ldquo;Sitio&rdquo;.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Los Datos Personales se proporcionan a trav&eacute;s de un la creaci&oacute;n de una Cuenta o el Registro de Usuario. La Empresa recaba informaci&oacute;n de identificaci&oacute;n personal que es cedida voluntariamente por el Usuario, o en respuesta a requerimientos expl&iacute;citos por parte de la Empresa.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La informaci&oacute;n solicitada es la siguiente:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Nombre</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Domicilio</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Edad</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; G&eacute;nero</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; N&uacute;mero de Tarjeta bancaria</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Transacciones</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Cuenta de Correo electr&oacute;nico</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; N&uacute;mero Telef&oacute;nico</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa no recaba datos personales sensibles en el entorno digital.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa tambi&eacute;n podr&aacute; recabar su direcci&oacute;n de IP (Internet Protocol) para ayudar a diagnosticar problemas con nuestro servidor, y para administrar la P&aacute;gina. Una direcci&oacute;n de IP es un n&uacute;mero que se le asigna a su computadora cuando usa Internet. Su direcci&oacute;n de IP tambi&eacute;n es utilizada para ayudar a identificarle dentro de una sesi&oacute;n particular y para recolectar informaci&oacute;n demogr&aacute;fica general.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Hacemos uso de la tecnolog&iacute;a push a trav&eacute;s de la aplicaci&oacute;n de la Empresa que se usa para enviar notificaciones con autorizaci&oacute;n previa del usuario, este medio de comunicaci&oacute;n no tiene ning&uacute;n tipo de acceso a otras funciones o informaci&oacute;n del equipo con el que se conecta al sitio.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La informaci&oacute;n puede incluir la URL de la que provienen (est&eacute;n o no en nuestro Sitio Web), a qu&eacute; URL acceden seguidamente (est&eacute;n o no en nuestro Sitio Web), qu&eacute; navegador est&aacute;n usando, as&iacute; como tambi&eacute;n las p&aacute;ginas visitadas, las b&uacute;squedas realizadas, las publicaciones, compras o ventas, mensajes, etc.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Cookies</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Usuario de la P&aacute;gina de Internet de la Empresa conoce y acepta que la Empresa podr&aacute; utilizar un sistema de seguimiento mediante la utilizaci&oacute;n de cookies (las &quot;Cookies&quot;).</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Las Cookies son archivos de datos que se almacenan en el disco duro del equipo de c&oacute;mputo o del dispositivo de comunicaciones electr&oacute;nicas de un usuario al navegar en un sitio de Internet, el cual permite intercambiar informaci&oacute;n de estado entre dicho sitio y el navegador del usuario. La informaci&oacute;n de estado puede revelar medios de identificaci&oacute;n de sesi&oacute;n, autenticaci&oacute;n o preferencias del usuario, as&iacute; como cualquier dato almacenado por el navegador respecto al sitio de Internet.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Las Cookies se utilizan con el fin de conocer los intereses, el comportamiento y la demograf&iacute;a de quienes visitan o son visitantes de la P&aacute;gina de Internet y de esa forma, comprender mejor sus necesidades e intereses y darles un mejor servicio o proveerle informaci&oacute;n relacionada. Tambi&eacute;n ofrecemos ciertas funcionalidades que s&oacute;lo est&aacute;n disponibles mediante el empleo de Cookies.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Tambi&eacute;n usaremos la informaci&oacute;n obtenida por intermedio de las Cookies para analizar las p&aacute;ginas navegadas por el visitante o Usuario, las b&uacute;squedas realizadas, mejorar nuestras iniciativas comerciales y promocionales, mostrar publicidad o promociones, banners de inter&eacute;s, perfeccionar nuestra oferta de contenidos y art&iacute;culos, personalizar dichos contenidos, presentaci&oacute;n y servicios.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Las Cookies tambi&eacute;n se usan para que el Usuario no tenga que introducir su clave tan frecuentemente durante una sesi&oacute;n de navegaci&oacute;n, tambi&eacute;n para contabilizar y corroborar los registros, la actividad del Usuario y otros conceptos y acuerdos comerciales, siempre teniendo como objetivo de la instalaci&oacute;n de las Cookies, el beneficio del Usuario que la recibe, y no ser&aacute; usado con otros fines ajenos a la Empresa.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Se establece que la instalaci&oacute;n, permanencia y existencia de las Cookies en el computador del Usuario o del visitante depende de su exclusiva voluntad y puede ser eliminada de su computador cuando as&iacute; lo desee. Para saber c&oacute;mo quitar las Cookies del sistema es necesario revisar la secci&oacute;n Ayuda (Help) del navegador. Tambi&eacute;n, se pueden encontrar Cookies u otros sistemas similares instalados por terceros en ciertas p&aacute;ginas de nuestro Sitio. La Empresa no controla el uso de Cookies por terceros.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La empresa tambi&eacute;n utiliza Web Beacons al momento en que el usuario est&aacute; utilizando la P&aacute;gina, los Web beacons son im&aacute;genes visibles u ocultas insertadas dentro de un sitio Web, que se utiliza para monitorear el comportamiento del usuario en estos medios.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Finalidades del Tratamiento</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Informaci&oacute;n Personal es recabada y almacenada para fines de identificaci&oacute;n y certeza del Usuario de la P&aacute;gina Web de la Empresa (www.asadoresdelnorte.com), adem&aacute;s, se utilizan con fines de localizaci&oacute;n, cobro, contacto, el comportamiento y la demograf&iacute;a de los Usuarios, mejorar nuestras iniciativas comerciales y promocionales (mercadotecnia), enviar informaci&oacute;n o mensajes sobre nuevos productos y/o servicios as&iacute; como cualquier otra informaci&oacute;n, mostrar publicidad o promociones, de inter&eacute;s para nuestros Usuarios, con lo anterior, la Empresa puede proporcionar un servicio eficiente al Usuario.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Las finalidades son necesarias para el cumplimiento de las obligaciones y la relaci&oacute;n con el titular, esto es que la Empresa proporcione un servicio eficiente al Usuario, siendo esta la obligaci&oacute;n principal y que dio origen a la Relaci&oacute;n Jur&iacute;dica entre la Empresa y el Usuario.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La recolecci&oacute;n de informaci&oacute;n permite a la Empresa ofrecer servicios y funcionalidades que se adecuan mejor a las necesidades del Usuario. Asimismo le permite a la Empresa hacerle llegar al Usuario por distintos medios y v&iacute;as (incluyendo mail, SMS, etc.) ofertas de productos y servicios que pueden ser del inter&eacute;s del Usuario. Asimismo le permite a la Empresa usar como medios de comunicaci&oacute;n y hacerle llegar al Usuario por distintos medios y v&iacute;as (incluyendo correo convencional o electr&oacute;nico mail, SMS, MSM, notificaciones electr&oacute;nicas) ofertas de productos y servicios que pueden ser del inter&eacute;s del Usuario, adem&aacute;s de cumplir con sus obligaciones por estos medios.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa se reserva el derecho de solicitar alg&uacute;n comprobante y/o dato adicional a efecto de corroborar los datos personales de cada Usuario, as&iacute; como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Usuario reconoce que la cuenta o Registro de Usuario es personal, &uacute;nica e intransferible, y est&aacute; prohibido que un mismo Usuario inscriba o posea m&aacute;s de una cuenta. En caso que la Empresa detecte distintas cuentas que contengan datos coincidentes o relacionados, podr&aacute; cancelar, suspender o inhabilitarlas. El Usuario ser&aacute; responsable por todas las operaciones efectuadas en su cuenta, pues el acceso a la misma est&aacute; restringido al ingreso y uso de su contrase&ntilde;a personal, de conocimiento exclusivo del Usuario. En caso que la cuenta sea suspendida, la Empresa almacenar&aacute; los datos personales del Usuario, a efecto que el Usuario y la Empresa seguir&aacute;n sujetos a lo se&ntilde;alado en el presente Aviso de Privacidad.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">En caso dado de que la informaci&oacute;n del Usuario al momento de la inscripci&oacute;n estuviese equivocada o incompleta, siendo que se imposibilite la comprobaci&oacute;n e identificaci&oacute;n del Usuario, la Empresa tendr&aacute; el derecho de suspender inmediatamente la prestaci&oacute;n de los Servicios por medio de la P&aacute;gina de Internet, sin necesidad de previo aviso, responsabiliz&aacute;ndose en todo momento el Usuario por las p&eacute;rdidas y da&ntilde;os finalmente sufridos.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Titulares con los que la Empresa guarda una relaci&oacute;n Laboral.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Los Titulares con los que la Empresa guarda una relaci&oacute;n Laboral, son aquellos que implican una fuerza laboral con la Empresa, en cualquiera de los reg&iacute;menes se&ntilde;alados contemplados en la Ley Federal del Trabajo.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Los datos recabados para estos Titulares ser&iacute;an las siguientes:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Nombre completo</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Las funciones o puestos desempe&ntilde;ados</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Domicilio f&iacute;sico</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Direcci&oacute;n electr&oacute;nica</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Tel&eacute;fono</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Fecha de Nacimiento</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Nacionalidad</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Estado Civil</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Lugar de Origen</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Datos Acad&eacute;micos</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Historial Laboral</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; N&uacute;mero de Cuenta Bancaria</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Informaci&oacute;n Fiscal</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Numero de Seguridad Social</span></em></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Fines del Tratamiento</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Informaci&oacute;n Personal es recabada y almacenada para fines de identificaci&oacute;n y certeza del Titular, adem&aacute;s, se utilizan con fines de localizaci&oacute;n, historial y cuidado de los Trabajadores, remuneraci&oacute;n, derechos de car&aacute;cter laboral y realizaci&oacute;n de todo tipo de tr&aacute;mites ante instituciones privadas o gubernamentales.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Las finalidades son necesarias para el cumplimiento de las obligaciones y la relaci&oacute;n con el titular, de igual modo son finalidades resultantes del origen de la relaci&oacute;n con entre el Titular y la Empresa.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Titulares con los que la Empresa guarda una relaci&oacute;n Contractual</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Los Titulares con los que la Empresa guarda una relaci&oacute;n Contractual son aquellas personas f&iacute;sicas o los representantes de personas morales con las que la empresa realiza un contrato, con el fin de desempe&ntilde;ar correctamente sus funciones, obligaciones, prestar un servicio o cumplir con sus fines.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Los datos recabados de estos titulares son los siguientes</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Nombre completo</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Las funciones o puestos que desempe&ntilde;a o ha desempe&ntilde;ado</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Domicilio f&iacute;sico</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Direcci&oacute;n electr&oacute;nica</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Tel&eacute;fono</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Nacionalidad</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Datos Acad&eacute;micos</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Historial Laboral</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; N&uacute;mero de Cuenta Bancaria</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Informaci&oacute;n Fiscal</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Datos de su fuerza laboral o integrantes</span></em></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Fines del Tratamiento</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Informaci&oacute;n Personal es recabada y almacenada para fines de identificaci&oacute;n y certeza del Titular, adem&aacute;s, se utilizan con fines de localizaci&oacute;n, historial, remuneraci&oacute;n, cumplimiento de obligaciones contractuales y realizaci&oacute;n de todo tipo de tr&aacute;mites ante instituciones privadas o gubernamentales.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Las finalidades son necesarias para el cumplimiento de las obligaciones y la relaci&oacute;n con el titular, de igual modo son finalidades resultantes del origen de la relaci&oacute;n con entre el Titular y la Empresa.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Titulares con los que la Empresa guarda una relaci&oacute;n espor&aacute;dica jur&iacute;dica o no jur&iacute;dica</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Son aquellos titulares con los que la Empresa se relaciona para actos espor&aacute;dicos, de car&aacute;cter jur&iacute;dico o no jur&iacute;dico.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Los datos recabados para estos Titulares ser&iacute;an las siguientes:</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Nombre completo</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Las funciones o puestos desempe&ntilde;ados</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Domicilio f&iacute;sico</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Direcci&oacute;n electr&oacute;nica</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Tel&eacute;fono</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Fecha de Nacimiento</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Nacionalidad</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Lugar de Origen</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Datos Acad&eacute;micos</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Historial Laboral</span></em></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Fines del Tratamiento</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Informaci&oacute;n Personal es recabada y almacenada para fines de identificaci&oacute;n y certeza del Titular, adem&aacute;s, se utilizan con fines de localizaci&oacute;n, capacitaci&oacute;n, orientaci&oacute;n, estad&iacute;sticos de control, postular ofertas laborales.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Las finalidades son necesarias para el cumplimiento de las obligaciones y la relaci&oacute;n con el titular, de igual modo son finalidades resultantes del origen de la relaci&oacute;n con entre el Titular y la Empresa.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Transferencia de Datos</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Titular autoriza expresamente a la Empresa a mantener en su registro las informaciones proporcionadas por el Titular, tambi&eacute;n autoriza a la Empresa a proporcionar informaci&oacute;n constante sobre el referido registro a (i) autoridades que lo solicitaren conforme lo permitido por la legislaci&oacute;n en vigor y (ii) a sus asociados estrat&eacute;gicos, comerciales o t&eacute;cnicos con la finalidad de ofrecer mejores condiciones de Promociones y/o contenidos del Usuario. Adem&aacute;s, el Usuario permite expresamente a la Empresa a recopilar informaci&oacute;n para la realizaci&oacute;n de seguimiento de tr&aacute;fico, con intenci&oacute;n de identificar grupos y perfiles de usuarios, as&iacute; como para fines de orientaci&oacute;n publicitaria.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Las Transferencias de Datos son con fines l&iacute;citos y acorde a lo se&ntilde;alado en la Ley de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Los receptores de la informaci&oacute;n a transferir son asociados estrat&eacute;gicos, comerciales o t&eacute;cnicos, misma que solo se utiliza con fines de publicidad y desarrollo de la Pagina Web.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa declara que no transferir&aacute; ning&uacute;n tipo de informaci&oacute;n del Titular a Terceros nacionales o extranjeros, en caso contrario, se le har&aacute; del conocimiento al Titular mediante la publicaci&oacute;n de un nuevo aviso de Privacidad. Lo anterior de acuerdo con lo previsto en el art&iacute;culo 36 del Reglamento de la Ley de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de Particulares.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Transferencia en circunstancias especiales</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Si existe una venta, una fusi&oacute;n, consolidaci&oacute;n, cambio en el control societario, transferencia de activos sustancial, reorganizaci&oacute;n o liquidaci&oacute;n de la Empresa entonces podemos transferir, o asignar la informaci&oacute;n recabada en este Sitio a una o m&aacute;s partes relevantes, adem&aacute;s de obligarnos a expedir un nuevo Aviso de Privacidad actualizado a los cambios internos sufridos.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Remisi&oacute;n de Datos</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa declara que puede comunicar datos Personales del Titular con el Encargado, dentro o fuera del territorio nacional en t&eacute;rminos de lo dispuesto en la ley o el Reglamento.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El encargado es persona f&iacute;sica o moral, p&uacute;blica o privada, ajena a la organizaci&oacute;n de la Empresa, que sola o conjuntamente con otras, trata datos personales por cuenta de la Empresa, como consecuencia de la existencia de una relaci&oacute;n jur&iacute;dica que le vincula con la misma y delimita el &aacute;mbito de su actuaci&oacute;n para la prestaci&oacute;n de un servicio.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Esta remisi&oacute;n de datos es para cumplir con los fines especificados en el tratamiento, mismos directamente relacionados con el cumplimiento de los objetos y relaciones contractuales o extracontractuales con la Empresa.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El tratamiento de datos en esta remisi&oacute;n est&aacute; sujeto a los siguientes t&eacute;rminos acordados entre la empresa y el encargado:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">I. Tratar &uacute;nicamente los datos personales conforme a las instrucciones de la Empresa;</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">II. Abstenerse de tratar los datos personales para finalidades distintas a las instruidas por la Empresa;</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">III. Implementar las medidas de seguridad conforme a la Ley, el Reglamento y las dem&aacute;s disposiciones aplicables;</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">IV. Guardar confidencialidad respecto de los datos personales tratados;</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">V. Suprimir los datos personales objeto de tratamiento una vez cumplida la relaci&oacute;n jur&iacute;dica con la Empresa o por instrucciones de la Empresa, siempre y cuando no exista una previsi&oacute;n legal que exija la conservaci&oacute;n de los datos personales, y</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">VI. Abstenerse de transferir los datos personales</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El ejercicio del Titular de sus derechos ARCO o cualquier otro derecho se&ntilde;alado en la ley o reglamento, ser&aacute; acorde al presente Aviso de Privacidad.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Limite de uso o divulgaci&oacute;n de Datos Personales</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Para que el Titular ejerza su derecho de limitar el uso o divulgaci&oacute;n de los datos personales, pero con intenci&oacute;n de continuar usando de los servicios que presta la Empresa, el usuario debe de manifestarlo mediante un escrito al siguiente correo electr&oacute;nico ventas@asadoresdelnorte.com, dicho escrito debe contener lo siguiente:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">&bull; El nombre del titular y correo electr&oacute;nico donde reciba la respuesta.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">&bull; Dirigida a la Empresa.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">&bull; Registro de cliente o cuenta con la Empresa para acreditar su identidad o la relaci&oacute;n que sostiene con la Empresa.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">&bull; La descripci&oacute;n clara y precisa de los datos que desea limitar de su uso o divulgaci&oacute;n.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa gozar&aacute; de un plazo de 20 d&iacute;as h&aacute;biles para contestar y en su caso ejecutar la limitaci&oacute;n de uso, en dicha respuesta, dar&aacute; la resoluci&oacute;n y la posibilidad de realizar lo solicitado en el Escrito, sin perjuicio de la posibilidad del ejercicio de sus dem&aacute;s derechos.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Derechos del Usuario con relaci&oacute;n a sus Datos Personales:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Para los Usuarios de la P&aacute;gina Web, adem&aacute;s de los se&ntilde;alados en este documento, cuenta con los siguientes:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Usuario tiene derecho de Acceder a sus datos a trav&eacute;s de la P&aacute;gina Web de la Empresa, en su Registro de Usuario se encuentran todos los Datos que proporcion&oacute; a la Empresa. A estos datos puede acceder proporcionando su Nombre de Usuario y Contrase&ntilde;a.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Usuario tiene derecho a Ratificar sus datos en el momento que desee a trav&eacute;s de la Pagina Web de la Empresa, en su Registro de Usuario puede modificarlos, adem&aacute;s debe de actualizar sus datos personales al momento de adquirir un bien publicado en la P&aacute;gina Web de la Empresa.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Usuario tiene derecho, en el momento que lo desee ejercitar, a cancelar su cuenta o Registro de Usuario, accediendo al mismo, seleccionando la opci&oacute;n para Cancelar Registro, con esto revoca su consentimiento del tratamiento de datos personales o se opone al Aviso de Privacidad, sin embargo, la Empresa conservar&aacute; la Informaci&oacute;n proporcionada en su base de datos, almacenada y resguardada.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Para que el Usuario ejerza su derecho de manifestar su negativa para el tratamiento de sus datos personales para aquellas finalidades que considere no son necesarias, el usuario debe de manifestarlo mediante un escrito libre al siguiente correo electr&oacute;nico ventas@asadoresdelnorte.com, dicho escrito debe contener lo siguiente:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">&bull;</span><em><span style="font-family:'Times New Roman'; color:#000;">&nbsp;El nombre del titular y correo electr&oacute;nico donde reciba la respuesta.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Dirigida a la Empresa</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Registro de cliente o cuenta con la Empresa para acreditar su identidad</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; La descripci&oacute;n clara y precisa de finalidades que considere no son necesarias</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa gozar&aacute; de un plazo de 20 d&iacute;as h&aacute;biles para contestar y en su caso dejar de usar los datos personales para las finalidades se&ntilde;aladas.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Para que el Usuario ejerza su derecho de limitar el uso o divulgaci&oacute;n de los datos personales, pero con intenci&oacute;n de continuar usando de los servicios que presta la Empresa, el usuario debe de manifestarlo mediante un escrito libre al siguiente correo electr&oacute;nico ventas@asadoresdelnorte.com, dicho escrito debe contener lo siguiente:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; El nombre del titular y correo electr&oacute;nico donde reciba la respuesta</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Dirigida a la Empresa</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Registro de cliente o cuenta con la Empresa para acreditar su identidad</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; La descripci&oacute;n clara y precisa de los datos que desea limitar de su uso o divulgaci&oacute;n.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa gozar&aacute; de un plazo de 20 d&iacute;as h&aacute;biles para contestar y en su caso ejecutar la limitaci&oacute;n de uso, en dicha respuesta, dar&aacute; la resoluci&oacute;n y la posibilidad de realizar lo solicitado en el Escrito, sin perjuicio de la posibilidad del ejercicio de sus dem&aacute;s derechos.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Los anteriores son medios para que el Usuario limite el tratamiento de los datos personales.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Ejercicio de Derechos ARCO</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Titular tiene derecho, de propia persona o a trav&eacute;s de un Representante, de solicitar a la Empresa, en cualquier momento el acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n, respecto de los datos personales que est&eacute; almacenada en la Base de Datos de la Empresa.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Solicitud debe ser por escrito con los siguientes requisitos:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Los documentos que acrediten la identidad (copia de credencial para votar por ejemplo) o, en su caso, la representaci&oacute;n legal del titular. Las Identificaciones deben de ser Oficiales.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; La descripci&oacute;n clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n a los mismos.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; En su caso, las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Que la carta est&eacute; dirigida a la Empresa.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Solicitud ser&aacute; enviada al Comit&eacute; o el Oficial de Datos Personales a trav&eacute;s del correo electr&oacute;nico ventas@asadoresdelnorte.com, y de forma interna se le har&aacute; llegar a la persona que recibe y contesta estas solicitudes.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa comunicar&aacute; al Titular, en un plazo de veinte d&iacute;as h&aacute;biles contados desde la fecha en que se recibi&oacute; la solicitud de acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n, la determinaci&oacute;n adoptada. El momento en que se recibe la solicitud es aquel en que haya entrado en nuestro servidor, contestando el mismo con un Acuse de Recibo.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Una vez que haya sido enviada la contestaci&oacute;n en el plazo se&ntilde;alado, la Empresa tendr&aacute; 15 d&iacute;as h&aacute;biles para ejecutar el Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n de los datos personales conforme a la solicitud o derechos similares.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Titular deber&aacute; cubrir los gastos justificados de env&iacute;o o con el costo de reproducci&oacute;n en copias u otros formatos. Dependiendo de la informaci&oacute;n, sea electr&oacute;nica o f&iacute;sica la entrega de la informaci&oacute;n, la Empresa necesitar&aacute; un domicilio para enviar la informaci&oacute;n.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Cuando los datos hayan dejado de ser necesarios para el cumplimiento de las finalidades previstas por este aviso de privacidad y las disposiciones legales aplicables, deber&aacute;n ser cancelados, bloqueados y suprimidos por ministerio de Ley.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Procedimientos para bloqueo y supresi&oacute;n de los datos personales</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Una vez que los datos hayan sido cancelados, la Empresa conservar&aacute; un mes m&aacute;s los datos personales del Titular, para fines de aclaraciones y preparaci&oacute;n para la supresi&oacute;n. Una vez vencido este plazo, la Empresa suprimir&aacute; de manera definitiva los datos personales del titular, no teniendo oportunidad de localizaci&oacute;n ni contacto con el titular, siendo eliminados de la base de datos y desecho todo expediente relacionado con el titular. Si el titular llegare a realizar otro acto con la empresa, deber&aacute; de iniciarlo como si la relaci&oacute;n nunca hubiera existido.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Lo anterior, observando lo se&ntilde;alado en las Pol&iacute;ticas de Privacidad para efectos de conservar informaci&oacute;n por ministerio de ley o de autoridad.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Los datos personales que hayan sido cumplidos sus fines, pero que no puedan ser cancelados y suprimidos por ministerio de ley o contractual, ser&aacute;n bloqueados de los fines a los que eran sometidos, hasta que se pueda suprimir. Durante dicho periodo, los datos personales no podr&aacute;n ser objeto de tratamiento.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Confidencialidad de la informaci&oacute;n</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Los datos de los Titulares ser&aacute;n suministrados &uacute;nicamente por la Empresa en las formas establecidas en este Aviso de Privacidad. La Empresa har&aacute; todo lo que est&eacute; a su alcance para proteger la privacidad de la informaci&oacute;n. Puede suceder que en virtud de &oacute;rdenes judiciales, o de regulaciones legales, la Empresa se vea compelida a revelar informaci&oacute;n a las autoridades o terceras partes bajo ciertas circunstancias, o bien en casos que terceras partes puedan interceptar o acceder a cierta informaci&oacute;n o transmisiones de datos en cuyo caso la Empresa no responder&aacute; por la informaci&oacute;n que sea revelada. En estos casos, la Empresa notificar&aacute; al Titular sobre esta situaci&oacute;n.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">No asumimos ninguna obligaci&oacute;n de mantener confidencial cualquier otra informaci&oacute;n que el Titular proporcione a trav&eacute;s de boletines y pl&aacute;ticas en l&iacute;nea (chats), as&iacute; como la informaci&oacute;n que obtenga a trav&eacute;s de los cookies, informaci&oacute;n que no haya sido directamente entregada a la Empresa.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Se debe aclarar que NO se vende, regala, facilita ni alquila la informaci&oacute;n del Titular a ning&uacute;n tercero. Si el usuario no desea que sus datos sean compartidos, puede decidir NO utilizar un servicio determinado o NO participar en algunas promociones o concursos.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa tomar&aacute; todas las medidas posibles para mantener la confidencialidad y la seguridad descritas anteriormente, pero no responder&aacute; por los da&ntilde;os o perjuicios que pudieran derivarse de la violaci&oacute;n de esas medidas por parte de terceros que utilicen las redes p&uacute;blicas o el Internet, alterando los sistemas de seguridad para obtener acceso a la informaci&oacute;n de los Titulares.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Las vulneraciones de seguridad ocurridas en cualquier fase del tratamiento que afecten de forma significativa los derechos patrimoniales o morales de los Titulares, ser&aacute;n informadas de forma inmediata por la Empresa al Titular, a fin de que este &uacute;ltimo pueda tomar las medidas correspondientes a la defensa de sus derechos.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Seguridad y almacenamiento</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa ha implementado diversas t&eacute;cnicas de seguridad para proteger los datos proporcionados por el Titular, en contra de accesos no autorizados por visitantes de la P&aacute;gina de Internet o a la Base de Datos de la Empresa, ya sea dentro o fuera de la Empresa. Uno de los factores que protege la seguridad de los datos, es que nuestro sistema ha implementado un candado de seguridad SSL, lo cual hace imposible que una persona ajena a la relaci&oacute;n entre el Titular y la Empresa, tenga acceso o adquiera los datos de manera ilegal.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Es necesario tener en cuenta que la seguridad perfecta no existe en Internet. Por ello, la Empresa no se hace responsable por interceptaciones ilegales o violaci&oacute;n de sus sistemas o bases de datos por parte de personas no autorizadas. De igual forma no se hace responsable por la indebida utilizaci&oacute;n de la informaci&oacute;n obtenida por esos medios.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Al momento de contratar un servicio o comprar un producto en l&iacute;nea, se pedir&aacute;n datos bancarios para los cuales nos comprometemos a ofrecer seguridad y confidencialidad de los datos que nos proporcionan, para ello, contamos con un servidor seguro, de tal manera que la informaci&oacute;n que nos env&iacute;an, se transmite encriptada para asegurar su protecci&oacute;n.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Una vez recibidos los datos, haremos todo lo posible por salvaguardar la informaci&oacute;n en nuestro servidor.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Bases de Datos</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Base de Datos de la Empresa, donde se almacena la informaci&oacute;n recabada, mantendr&aacute; guardada y resguardada la Informaci&oacute;n proporcionada por el Titular, a pesar que sean modificadas, actualizadas, o incluso canceladas por cualquier motivo. Lo anterior con fines de mantener un Historial sobre el Titular y tutelando sus intereses jur&iacute;dicos. La conservaci&oacute;n de informaci&oacute;n se&ntilde;alada en este p&aacute;rrafo podr&aacute; ser borrada o eliminada en su totalidad mediante el ejercicio de los Derechos ARCO.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Servicio al cliente o Atenci&oacute;n de la Empresa</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa pone a disposici&oacute;n del Titular un correo electr&oacute;nico en el que puede aclarar cualquier duda con respecto al uso de los Datos Personales, medios para ejercer derechos con relaci&oacute;n a datos personales, etc. El correo electr&oacute;nico es ventas@asadoresdelnorte.com.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Modificaciones al Aviso de Privacidad</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa, as&iacute; como el Titular, reconocen que este Aviso de Privacidad es de vigencia ilimitada. Sin embargo, la empresa procurara mantener actualizado el presente Aviso.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa podr&aacute; modificar en cualquier momento el Aviso de Privacidad y confidencialidad sin necesidad de previo aviso. Por lo anterior la Empresa recomienda al Titular que vuelva a leer con regularidad este documento, de forma que se mantenga siempre informado sobre eventuales modificaciones.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Las alteraciones o modificaciones al presente Aviso de Privacidad se volver&aacute;n efectivas inmediatamente despu&eacute;s de su publicaci&oacute;n en la P&aacute;gina de Internet de la Empresa.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Una vez realizadas las modificaciones, se presumir&aacute; que el Titular que contin&uacute;e usando la P&aacute;gina de Internet de la Empresa, solicitado sus servicios, o realizando los actos que dieron origen a la relaci&oacute;n con la empresa, tendr&aacute; pleno conocimiento, habr&aacute; le&iacute;do y consentido el Aviso de Privacidad reformado.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Las Reformas al Aviso de Privacidad se podr&aacute;n hacer saber al Titular a trav&eacute;s de un aviso en la P&aacute;gina de la Empresa, correo electr&oacute;nico, de forma telef&oacute;nica o por escrito.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Correos Electr&oacute;nicos</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa puede enviar correos electr&oacute;nicos al Usuario con el prop&oacute;sito de proporcionarle mejor servicio:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Luego del Registro de Usuario, notific&aacute;ndole los datos de su cuenta</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Con recordatorios de los servicios que ofrecemos (especialmente aquellos que a&uacute;n no haya utilizado o no haya utilizado en un tiempo considerable)</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Para enviar informaci&oacute;n sobre los Productos que haya pedido</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Como parte de un Newsletter</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Correo electr&oacute;nico de Promociones de los Productos</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Para ofrecer servicios relacionados</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; De todas maneras, en cada uno de los correos electr&oacute;nicos que enviemos siempre ofreceremos la posibilidad de solicitar que le dejemos de enviar correos electr&oacute;nicos en el futuro</span></em></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Registros P&uacute;blicos</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Registro P&uacute;blico de Consumidores, de Usuarios y dem&aacute;s afines, se&ntilde;alados en disposiciones relativas como la del Consumidos o la de Protecci&oacute;n y Defensa al Usuario de Servicios Financieros, continuar&aacute;n vigentes y se regir&aacute;n de conformidad con lo que establezcan las leyes en cita y las disposiciones aplicables que de ellas deriven, junto con los derechos que ellas se consignen.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Consentimiento</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Titular manifiesta que al uso de la p&aacute;gina, la proporci&oacute;n de sus Datos personales y poseer una cuenta o Registro de Usuario, o la realizaci&oacute;n directa de origen contractual o extracontractual con la Empresa, ya ha le&iacute;do el presente aviso y consiente lo estipulado en el mismo. El Titular consiente el presente Aviso de Privacidad, de conformidad con la Ley de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares, no oponi&eacute;ndose al Aviso de Privacidad. Ning&uacute;n dato ser&aacute; tratado sino hasta cinco d&iacute;as despu&eacute;s de que hayan sido proporcionados por el usuario y este no haya manifestado su negativa.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">Revocaci&oacute;n del consentimiento</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El consentimiento podr&aacute; ser revocado en cualquier momento sin que se le atribuyan efectos retroactivos. Para revocar el consentimiento el Titular debe de enviar una Solicitud, la cual, debe ser por escrito con los siguientes requisitos:</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Los documentos que acrediten la identidad (copia de credencial para votar por ejemplo) o, en su caso, la representaci&oacute;n legal del titular. Las Identificaciones deben de ser Oficiales.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; La descripci&oacute;n clara y precisa del v&iacute;nculo que tiene con la Empresa.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Aportar la documentaci&oacute;n que sustente su petici&oacute;n.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Que la carta est&eacute; dirigida a la Empresa.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><em><span style="font-family:'Times New Roman'; color:#000;">&bull; Que compruebe que la relaci&oacute;n que sostiene con la Empresa haya terminado.</span></em></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Empresa emitir&aacute; una respuesta en la cual confirmar&aacute; la revocaci&oacute;n del consentimiento, o en su caso, se&ntilde;alar&aacute; el razonamiento dependiendo del caso en concreto, contando la empresa con 15 d&iacute;as para emitir esta respuesta. Los plazos ser&aacute;n contados a partir del momento en que el correo entra a nuestro servidor, emitiendo la Empresa el respectivo Acuse de Recibido de Solicitud.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Cualquier Solicitud ulterior a la mencionada en el p&aacute;rrafo anterior tendr&aacute; el mismo efecto que una de inicio, estando el Titular y la Empresa obligadas a los mismos plazos se&ntilde;alados anteriormente.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">La Solicitud no ser&aacute; v&aacute;lida ante la omisi&oacute;n de lo se&ntilde;alado anteriormente.</span></p>
                <p style="margin-top:0pt; margin-bottom:8.1pt; line-height:1.5em; background-color:#ffffff;"><span style="font-family:Helvetica; font-size:1.5em; color:#000;">&Aacute;rea Interna de Privacidad</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El titular, podr&aacute; ejercer sus derechos y realizar las consultas se&ntilde;aladas en el presente documento, as&iacute; como en la ley, a trav&eacute;s del &aacute;rea de Privacidad de la Empresa.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">El Usuario tendr&aacute; que utilizar el multicitado correo electr&oacute;nico ventas@asadoresdelnorte.com o Tel&eacute;fono (868) 352 1850, para contactar el &aacute;rea de Privacidad.</span></p>
                <p style="margin-top:12pt; margin-bottom:12pt; font-size:1.2em; background-color:#ffffff;"><span style="font-family:'Times New Roman'; color:#000;">Autoridad Protectora de Datos Personales Para cualquier duda o informaci&oacute;n adicional, as&iacute; como ejercer distintos tipos de derechos y procedimientos adem&aacute;s de los se&ntilde;alados en el presente, el Usuario puede contactar al Instituto Federal de Acceso a la Informaci&oacute;n y Protecci&oacute;n de Datos Personales &ldquo;IFAI&rdquo; al Tel&eacute;fono 01800-835-4324 (lada sin costo) por la p&aacute;gina de internet www.ifai.org.mx</span></p>
                <p style="margin-top:0pt; margin-bottom:10pt; line-height:115%; font-size:11pt;"><span style="font-family:Calibri;">&nbsp;</span></p>
              `
            }}
            />
          </WrapperResponsive>
        </Container>
      </Box>
      <Footer />
    </LayoutUser>
  )
}

export default Privacity
