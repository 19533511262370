import { number } from 'prop-types';
import React from 'react';
import styled from 'styled-components'

const StepInfoHeader = (props) => (
  <Wrapper>
    <Circle $selected={props.value === 1}>1</Circle>
    <Line />
    <Circle $selected={props.value === 2}>2</Circle>
    <Line />
    <Circle $selected={props.value === 3}>3</Circle>
    <Line />
    <Circle $selected={props.value === 4}>4</Circle>
    <Line />
    <Circle $selected={props.value === 5}>5</Circle>
    <Line />
    <Circle $selected={props.value === 6}>6</Circle>
    <Line />
    <Circle $selected={props.value === 7}>7</Circle>
    <Line />
    <Circle $selected={props.value === 8}>8</Circle>
    <Line />
    <Circle $selected={props.value === 9}>C</Circle>
  </Wrapper>
);

StepInfoHeader.propTypes = {
  value: number.isRequired
}

const Wrapper = styled.div`
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  padding: 1.5em 0em;
  font-family: 'bebas';
  margin-bottom: 1em;
  @media screen and (max-width:600px) {
    justify-content: space-between;
  }
`

const Circle = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  background-color: ${(props) => props.$selected ? '#000' : '#e1e1e1a6'};
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: .09em;
  padding-right: .06em;
  color: ${(props) => props.$selected ? '#ffffff' : '#aaa'};
`
const Line = styled.div`
  width: 2.5em;
  border-bottom: 1px solid #cdcdcd;
  margin: 0em .5em;
  @media screen and (max-width:600px) {
    display: none;
  }
`
export default StepInfoHeader;