/* eslint-disable no-unreachable */
import { SelectStyled, Row, Form, Input, Flex, FlexItem, Container } from './styled'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Alert } from '@material-ui/lab'
import { func, object, oneOfType, string, number } from 'prop-types'
import Icons from '../icons'
import useFetch from '../../../../../hooks/useFetch'
import { Box, Radio } from '@material-ui/core'
import { getIsMonthsWithoutInterestActivated } from '../../../../../modules/paymentsCuota'

var months = [
  { Enero: '01' },
  { Febrero: '02' },
  { Marzo: '03' },
  { Abril: '04' },
  { Mayo: '05' },
  { Junio: '06' },
  { Julio: '07' },
  { Agosto: '08' },
  { Septiembre: '09' },
  { Octubre: '10' },
  { Noviembre: '11' },
  { Diciembre: '12' }
]

var years = [
  { 20: 20 },
  { 21: 21 },
  { 22: 22 },
  { 23: 23 },
  { 24: 24 },
  { 25: 25 },
  { 26: 26 },
  { 27: 27 },
  { 28: 28 },
  { 29: 29 },
  { 30: 30 },
  { 31: 31 },
  { 32: 32 },
  { 33: 33 },
  { 34: 34 },
  { 35: 35 },
  { 36: 36 },
  { 37: 37 },
  { 38: 38 },
  { 39: 39 },
  { 40: 40 }
]

const CreditCard = props => {
  const [monthsWithoutInterestActivated, setMonthsWithoutInterest] = useState(false)

  const refMounted = useRef();
  const monthsWithoutInterests = useMemo(
    () => {
      if (!monthsWithoutInterestActivated) return null;
      const total = parseInt(props.totalPrice, 10)
      if (total >= 1200) return [3,6,9,12]
      if (total >= 900) return [3,6,9]
      if (total >= 600) return [3,6]
      if (total >= 300) return [3]
      return null
    },
    [props.totalPrice, monthsWithoutInterestActivated]
  )

  const handleMonthsWithoutInterestsChange = (e) => {
    const value = isNaN(parseInt(e.target.value, 10)) ? null : parseInt(e.target.value, 10);
    props.setData({ monthsWithoutInterest: value })
  }

  // reset months without interest when total change
  useEffect(
    () => {
      if (refMounted.current) {
        props.setData({ monthsWithoutInterest: null })
      } else {
        refMounted.current = true;
      }
    },
    [props.totalPrice]
  )

  // fetch months without interest status 
  useFetch(async () => {
    const statusMonths = await getIsMonthsWithoutInterestActivated()
    setMonthsWithoutInterest(statusMonths)
  })

  // scroll into top when show error message
  useEffect(() => {
    if (props.errorMessage) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [props.errorMessage])

  return (
    <Container>
      <Row>
        {!!props.errorMessage && (
          <Alert severity='error'>{props.errorMessage}</Alert>
        )}
      </Row>
      <Form id='credit_card_form'>
        <Row>
          <Input
            {...props}
            maxlength={80}
            name='card_name'
            placeholder='Nombre en la tarjeta'
          />
        </Row>
        <Row>
          <Input
            maxlength={16}
            name='card_number'
            filter='number'
            placeholder='16 Dígitos de la Tarjeta'
            {...props}
          />
        </Row>
        <Row>
          <Flex style={{ justifyContent: 'space-between' }}>
            <FlexItem style={{ width: '32%' }}>
              <SelectStyled
                {...props}
                type='select'
                name='card_month'
                options={months}
                placeholder='Mes'
                onClick={props.onFocus}
              />
            </FlexItem>
            <FlexItem style={{ width: '32%' }}>
              <SelectStyled
                {...props}
                type='select'
                name='card_year'
                options={years}
                placeholder='Año'
                onClick={props.onFocus}
              />
            </FlexItem>
            <FlexItem style={{ width: '32%' }}>
              <Input
                {...props}
                maxlength={4}
                placeholder='CVV'
                name='card_cvv'
                type='password'
              />
            </FlexItem>
          </Flex>
        </Row>
        {!!monthsWithoutInterests && !!monthsWithoutInterests.length && !props.data.discountApplied &&  (
          <Row>
            <Box fontSize="1.2em" fontWeight="bold">Pago a meses sin intereses</Box>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Box fontSize="1.2em" display="flex" alignItems="center" marginRight="1em" fontWeight="bold">
                <Radio
                  color="primary"
                  onChange={handleMonthsWithoutInterestsChange}
                  value=''
                  checked={!props.data.monthsWithoutInterest}
                />
                Un solo pago
              </Box>
              {monthsWithoutInterests.map((currentItem) => (
                <Box fontSize="1.2em" display="flex" alignItems="center" marginRight="1em" fontWeight="bold">
                  <Radio
                    color="primary"
                    onChange={handleMonthsWithoutInterestsChange}
                    value={currentItem}
                    checked={currentItem === props.data.monthsWithoutInterest}  
                  />
                  {currentItem} meses
                </Box>
              ))}
            </Box>
          </Row>
        )}

        <input type='hidden' name='card_code' />
      </Form>
      <Icons type='card' />
    </Container>
  )
}

CreditCard.propTypes = {
  saveOperation: func,
  startProcess: func,
  endProcess: func,
  goToStep: func,
  data: object,
  totalPrice: oneOfType([string, number]),
  errorMessage: string,
  onFocus: func,
  onPayWidthCard: func
}

export default CreditCard
