/* eslint-disable react/prop-types */
import styled from 'styled-components'
import React from 'react'

const Wrapper = styled.div`
font-family: 'Roboto', sans-serif;
font-size: 1em;
@media screen and (max-width:1800px) {
  font-size: .95em!important;
}
@media screen and (max-width:1700px) {
  font-size: .90em!important;
}
@media screen and (max-width:1600px) {
  font-size: .85em!important;
}
@media screen and (max-width:1500px) {
  font-size: .80em!important;
}
@media screen and (max-width:1400px) {
  font-size: .75em!important;
}
@media screen and (max-width:1300px) {
  font-size: .70em!important;
}
@media screen and (max-width:1100px) {
  font-size: .60em!important;
}
@media screen and (max-width:600px) {
  font-size: .8em!important;
}
`

const WrapperResponsive = props => {
  return (
    <Wrapper>
      {props.children}
    </Wrapper>
  )
}

export default WrapperResponsive
