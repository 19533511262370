import { Box, Button, CircularProgress, Collapse, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { number, object, oneOfType, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Input from '../../../../components/inputs/input'
import discount from '../../../../modules/discounts'
import { setNotification } from '../../../../flux/notification'
import { bool, func } from 'joi';

const DiscountCode = (props) => {
  const dispatch = useDispatch()
  const [view, setView] = useState('form') // form || loading || success
  const [code, setCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const parsedSubTotal = useMemo(() => {
    if (!props.subTotal) return 0;
    if (isNaN(Number.parseFloat(props.subTotal))) return 0
    return Number.parseFloat(props.subTotal)
  }, [props.subTotal])

  const handleCodeChange = (e) => {
    setCode(e.target.value)
  }

  const handleCodeApply = async () => {
    setView('loading')
    const response = await discount.getByCode(code)
    if (response.error) {
      setView('form')
      setErrorMessage(response.message)
      setTimeout(() => setErrorMessage(''), 7000)
      return null
    }
    if (response.success && response.payload.minPrice > parsedSubTotal) {
      setView('form')
      setErrorMessage('No se puede usar el codigo')
      setTimeout(() => setErrorMessage(''), 7000)
      return null
    }
    if (response.success && response.payload.isUsed) {
      setView('form')
      setErrorMessage('El código no es válido')
      setTimeout(() => setErrorMessage(''), 7000)
      return null
    }
    setView('success')
    dispatch(setNotification({ message: 'Código aplicado correctamente' }))
    setCode('')
    props.onApplyCode(response.payload)
  };
  
  // reset state whe discount is reseted by parent
  useEffect(() => {
    if (!props.data.discountApplied) {
      setView('form')
      setErrorMessage('')
      setCode('')
    }
  }, [props.data.discountApplied])

  if (props.hide) return null;

  return (
    <Box marginTop="1em" marginBottom="3em">
      {view === 'form' && (
        <>
        <Box fontSize="1.3em">¿Tienes un código de descuento?</Box>
        <Box marginTop=".5em">
          <Collapse in={!!errorMessage}>
            <Box marginBottom="1em">
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          </Collapse>
          <Grid container wrap="nowrap">
            <Input type="text" placeholder="Código" onChange={handleCodeChange} value={code} name="code" />
            <Box paddingRight="1em" />
            <ButtonStyled onClick={handleCodeApply}>Aplicar</ButtonStyled>
          </Grid>
        </Box>
        </>
      )}
      {view === 'loading' && (
        <Box display="flex" alignItems="center" flexDirection="column" mt="2em" mb="4em">
          <CircularProgress />
          <Box marginTop="3em">Validando código!</Box>
        </Box>
      )}
    </Box>
  )
};

DiscountCode.propTypes = {
  subTotal: oneOfType([number, string]).isRequired,
  onApplyCode: func.isRequired,
  hide: bool,
};

const ButtonStyled = styled(Button)`
  color: #000;
  padding: 0em 2em;
  border: 1px solid #000;
  font-family: bebas;
  font-size: 1.4em;
  :hover {
    color: #fff;
    background-color: #000;
  }
`

export default DiscountCode