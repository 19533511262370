import React from 'react'
import styled from 'styled-components'
import ContainerBase from '../../components/container'
import { Grid, Button, useMediaQuery, Box, Hidden } from '@material-ui/core'
import useHeader from '../../../hooks/useHeaderheight'
import { string, oneOfType, object } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { TrendingFlat } from '@material-ui/icons'

const Image = styled.img`
  width: 40%;
  @media screen and (max-width: 1500px) {
    width: 48%;
  }
  @media (max-width:959px) {
    width: 100%;
    margin-bottom: 50px;
  }
`

const Container = styled(ContainerBase)`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 10vh;
  align-items: center;
  color: #fff;
  text-align: center;
  @media (max-width:959px) {
    flex-wrap: wrap;
  }
`

const Body = styled.div`
  width: 50%;
  @media (max-width:959px) {
    width: 100%;
  }
`
const Title = styled.div`
  font-size: 90px;
  font-family: 'bebas';
  line-height: 1em;
  @media screen and (max-width:1750px) {
    font-size: 80px;
  }
  @media screen and (max-width:1550px) {
    font-size: 65px;
  }
  @media screen and (max-width:1250px) {
    font-size: 55px;
  }
  @media screen and (max-width:1050px) {
    font-size: 49px;
    text-align: center;
  }
  @media screen and (max-width:500px) {
    font-size: 35px;
    text-align: center;
  }
  
`
const Description = styled.div`
  font-size: 16px;
  font-family: 'gotham';
  padding-top: 30px;
  @media screen and (max-width:1400px) {
    font-size: 14px;
  }
  @media screen and (max-width:959px) {
    text-align: center;
    font-size: 14px;
  }
`
const ButtonStyled = styled(Button)`
  margin-left: 15px;
  font-family: 'bebas';
  font-size: 27px;
  border-radius: 0px;
  padding: 0px 20px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  @media screen and (max-width:1279px) {
    margin-left: 0px!important;
  }
  @media screen and (max-width:1300px) {
    font-size: 20px;
    padding: 0px 12px;
  }
  @media screen and (max-width:959px) {
    font-size: 27px;
    padding: 4px 20px;
  }
  :hover {
    background-color: #fff;
    color: #000;
  }
  svg {
    margin-left: 10px;
    font-size: 30px;
  }
`
const TrendingFlatStyled = styled(TrendingFlat)`
  @media screen and (max-width:1500px) {
    display: none;
  }
`
const ContentFirst = props => {
  const isMobile = useMediaQuery('(max-width:959px)')
  const top = useHeader()
  const history = useHistory()

  return (
    <Container style={{ marginTop: isMobile ? top : '0px' }}>
      <Body>
        <Grid container spacing={0} justify="center">
          <Grid item xs={12}>
            <Title>{props.title}</Title>
          </Grid>
          <Grid item xs={12} lg={11} xl={10}>
            <Description>
              {props.description}
            </Description>
          </Grid>
          <Grid item xs={12}>
            <Description>
              <ButtonStyled disableElevation onClick={event => history.push(props.location)} color='primary' variant={isMobile ? 'outlined' : 'contained'} size='small'>
                {props.buttonText} <TrendingFlatStyled />
              </ButtonStyled>
              <Hidden smUp>
                <Box pt={5} />
              </Hidden>
            </Description>
          </Grid>
        </Grid>
      </Body>
    </Container>

  )
}

ContentFirst.propTypes = {
  description: string,
  title: string,
  buttonText: string,
  location: oneOfType([string, object]),
  picture: string
}

export default ContentFirst
