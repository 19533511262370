/* eslint-disable no-unused-vars */
import React from 'react'
import { ButtonBase, MobileStepper as MobileStepperBase, Hidden, useMediaQuery } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import styled from 'styled-components'
import Content from './content'
import Footer from './footer'
import asadores from '../../../assets/new_5.png'
import src17 from '../../../assets/17.png'
import src1 from '../../../assets/new_4.png' // arma tu asador
import src11 from '../../../assets/11.png' // manos con gguantes
import cover1Src from '../../../assets/cover1.jpg' // manos
import src16 from '../../../assets/16.png' // manos

const covers = [
  cover1Src,
  src11,
  src16
]

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

function Cover () {
  const isMobile = useMediaQuery('(max-width:959px)')
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = 3

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  return (
    <FullWidth $src={covers[activeStep]} id='initial'>
      <AutoPlaySwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents disableLazyLoading interval={10000}>
        <Content
          picture={asadores}
          location={{ pathname: '/productos/asadores' }}
          buttonText='ASADORES'
          title='ASADORES ARTESANALES CALIDAD PREMIUM'
          description='En el norte conocemos bien el arte de la parrilla y creamos los asadores perfectos dignos del más destacado maestro parrillero. Construidos con procesos totalmente artesanales nuestros asadores combinan alto desempeño con un diseño rústico y poderoso.'
        />
        <Content
          picture={src1}
          location={{ pathname: '/arma-tu-asador' }}
          buttonText='ARMA TU ASADOR'
          title='construye el asador perfecto para ti'
          description='Personaliza tu asador y elige los componentes
          que más te gusten: diseño de puerta, tipo de
          parrilla, color, termómetro, grabado, mesas de
          apoyo, llantas de desplazamiento, etc. Todo lo
          necesario para llevar tus parrilladas al
          siguiente nivel.'
        />
        <Content
          picture={src17}
          location={{ pathname: '/productos/accesorios' }}
          buttonText='ACCESORIOS'
          title='LLEVA TUS PARRILLADAS AL SIGUIENTE NIVEL'
          description='Un buen parrillero necesita herramientas.
          Nuestros accesorios están pensados para
          facilitar el arte de las brasas y proteger
          nuestra fuente de felicidad: los asadores.'
        />
      </AutoPlaySwipeableViews>
      {!isMobile && (
        <ContainerStepper>
          <ContainerDots>
            <Dot onClick={event => setActiveStep(0)} $selected={activeStep === 0} />
            <Dot onClick={event => setActiveStep(1)} $selected={activeStep === 1} />
            <Dot onClick={event => setActiveStep(2)} $selected={activeStep === 2} />
          </ContainerDots>
        </ContainerStepper>
      )}
      <Hidden smDown>
        <Footer />
      </Hidden>
    </FullWidth>

  )
}

const Picture = styled.img`
  width: 100%;
  min-height: 400px;
  object-fit: cover;
  @media screen and (max-width:900px) { height: 300px; min-height: 300px; }
`
const FullWidth = styled('div')`
  display: flex;
  align-items: center;
  height: 750px;
  min-height: calc(100vh);
  position: relative;
  background-image: url(${props => props.$src});
  background-position: center;
  background-size: cover;
  transition: all 700ms;
  @media screen and (max-width:959px) {
    height: auto;
  }
`

const ArrowNext = styled(KeyboardArrowRight)`

`
const ArroBack = styled(KeyboardArrowLeft)`

`
const ButtonWrapperBase = styled(ButtonBase)`
  background: var(--user-gray);
  position: absolute;
  top: calc(50% - 12px);
  z-index: 2;
  padding: 7px;
  border-radius: 50%;
`
const ButtonWrapperNext = styled(ButtonWrapperBase)`
  right: 20px;
`
const ButtonWrapperBack = styled(ButtonWrapperBase)`
  left: 20px;
`

const ContainerStepper = styled.div`
  width: 100%;
  bottom: 160px;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width:1600px) {
    bottom: 130px;
  }
`

const ContainerDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #fff;
  margin-right: 10px;
  cursor: pointer;
  background: ${props => props.$selected ? '#fff' : ''};
  :hover {
    border-color: ${props => props.$selected ? '#fff' : 'red'};
  }
`
export default Cover
