import { Box, Button, CircularProgress, Collapse, useMediaQuery } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { func, string } from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setNotification } from '../../../../flux/notification'
import useForm from '../../../../hooks/useForm'
import useFetch from '../../../../hooks/useFetch'
import pointOfSale from '../../../../modules/pointsOfSale'
import Input from '../../../components/input'

const rules = {
  title: (value) => {
    if (!value) return 'El titulo es requerido'
    return false
  },
  address: (value) => {
    if (!value) return 'La dirección es requerido'
    return false
  },
  mapLink: (value) => {
    if (!value) return 'El link de google maps es requerido'
    return false
  },
  priority: (value) => {
    if (!value && value !== 0 && value !== '0') return 'La prioridad debe ser un numero entero'
    const parsed = Number.parseFloat(value)
    if ((!parsed || isNaN(parsed)) && value !== 0 && value !== '0') return 'La prioridad debe ser un numero entero'
    return false
  }
}

const Editor = (props) => {
  const [loading, setLoading] = useState(false)
  const isMobile = useMediaQuery('(max-width:600px)')
  const { getInputProps, validateInputs, values, setValues } = useForm({}, rules)
  const [errorMessage, setErrorMessage] = useState(null)
  const dispatch = useDispatch()

  const checkInputs = () => {
    const { firstErrorMessage } = validateInputs()
    if (firstErrorMessage) {
      setErrorMessage(firstErrorMessage)
      setTimeout(() => {
        setErrorMessage(null)
      }, 7000)
      return false
    }
    return true
  }

  const handleSave = async () => {
    if (checkInputs()) {
      setLoading(true)
      const response = await pointOfSale.create(values)
      if (response.success) {
        dispatch(setNotification(response))
        if (props.onSuccess) props.onSuccess()
        if (props.onClose) props.onClose()
      } else {
        setLoading(false)
        setErrorMessage(response.message)
        setTimeout(() => {
          setErrorMessage(null)
        }, 7000)
      }
    }
  }

  const handleUpdate = async () => {
    if (checkInputs()) {
      setLoading(true)
      const response = await pointOfSale.update(props.itemEditing, values)
      if (response.success) {
        dispatch(setNotification(response))
        if (props.onSuccess) props.onSuccess()
        if (props.onClose) props.onClose()
      } else {
        setLoading(false)
        setErrorMessage(response.message)
        setTimeout(() => {
          setErrorMessage(null)
        }, 7000)
      }
    }
  }

  useFetch(async () => {
    if (props.itemEditing) {
      setLoading(true)
      const data = await pointOfSale.getOne(props.itemEditing)
      setValues(pointOfSale.mekeSecureData(data))
      setLoading(false)
    }
  })

  return (
    <Wrapper>
      <Card>
        {loading && (
          <Box display='flex' justifyContent='center' alignItems='center' minHeight='12em'>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <Box textAlign='center' fontSize='1.1em' color='var(--main-blue-dark)' fontWeight='bold'>
              {props.itemEditing ? 'Editar punto de venta' : 'Crear punto de venta'}
            </Box>
            <Box marginTop='1em'>
              <Collapse in={!!errorMessage}>
                <Box marginTop='1em'>
                  <Alert severity='error'>
                    {errorMessage}
                  </Alert>
                </Box>
              </Collapse>
              <Box marginTop='1em'>
                <Box fontSize='1em' color='var(--main-blue)'>Titulo</Box>
                <Input {...getInputProps('title')} size={isMobile ? 'small' : 'small'} type='text' fullWidth label='Titulo' />
              </Box>
              <Box marginTop='1em'>
                <Box fontSize='1em' color='var(--main-blue)'>Dirección</Box>
                <Input {...getInputProps('address')} size={isMobile ? 'small' : 'small'} type='text' fullWidth label='Titulo' />
              </Box>
              <Box marginTop='1em'>
                <Box fontSize='1em' color='var(--main-blue)'>Link de google maps</Box>
                <Input {...getInputProps('mapLink')} size={isMobile ? 'small' : 'small'} type='text' fullWidth label='Titulo' />
              </Box>
              <Box marginTop='1em'>
                <Box fontSize='1em' color='var(--main-blue)'>Prioridad</Box>
                <Input {...getInputProps('priority')} size={isMobile ? 'small' : 'small'} type='number' fullWidth label='Titulo' />
              </Box>
              <Box marginTop='3em' display='flex'>
                <Button fullWidth variant='outlined' color='primary' onClick={props.onClose}>Cancelar</Button>
                <Box marginRight='2em' />
                <Button fullWidth variant='contained' color='primary' onClick={props.itemEditing ? handleUpdate : handleSave}>Guardar</Button>
              </Box>
            </Box>
          </>
        )}
      </Card>
    </Wrapper>
  )
}

Editor.propTypes = {
  onClose: func.isRequired,
  onSuccess: func.isRequired,
  itemEditing: string
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #0000008f;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
`
const Card = styled.div`
  background: #fff;
  width: 35em;
  box-sizing: border-box;
  padding: 5em;
  border-radius: .5em;
  @media screen and (max-width:1400px) {
    width: 30em;
    padding: 2em;
  }
  @media screen and (max-width:550px) {
    width: 80%;
  }
  @media screen and (max-width:400px) {
    width: 95%;
  }
`

export default Editor
