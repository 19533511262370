import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import logoSrc from '../../../assets/logo.png'
import logoWhiteSrc from '../../../assets/logo_white.png'
import { ButtonBase, Badge, Hidden } from '@material-ui/core'
import { ShoppingCartOutlined, ExpandLess, ExpandMore, Menu, Close } from '@material-ui/icons'
import MenuMobile from '../menu_left_mobile'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { categories } from '../../../constants'
import { HashLink } from 'react-router-hash-link'
import animatedScroll from '../../../helpers/animateScroll'
import slugify from 'slugify'
import usePointOfSaleList from '../../../hooks/usePointOfSaleList'
import { bool } from 'prop-types'

const HeaderContainer = styled('div')`
  position: sticky;
  top: 0;
  z-index: 9999;
`

const Header = styled.header`
  position: fixed;
  width: 100%;
  top: 0px;
  padding: 0px 135px;
  @media (max-width:1500px) {
    padding: 0px 100px;
  }
  @media (max-width:959px) {
    padding: 0px 35px;
  }
  background: ${props => props.$scrolled ? '#fff' : 'initial'};
  box-shadow: ${props => !props.$scrolled ? 'none' : '5px 0px 10px #cdcdcd'};
`

const Body = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width:2000px) {
    height: 120px;
  }
  @media screen and (max-width:1800px) {
    height: 110px;
  }
  @media (max-width:1500px) {
    height: 100px;
  }
  @media (max-width:1500px) {
    height: 90px;
  }
  @media (max-width:959px) {
    height: 70px;
  }
`

const Logo = styled.img`
  width: 70px;
  @media (max-width:1500px) {
    width: 55px;
  }
  @media (max-width:959px) {
    width: 45px;
  }
`

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MenuItem = styled.div`
  font-size: 15px;
  margin-right: 15px;
  position: relative;
  border: 1px solid transparent;
  transition: all 300ms linear;
  @media (min-width:960px) {
    :hover {
      border: ${props => props.$white ? '1px solid #fff' : '1px solid #000'};
      :last-of-type {
        border: 1px solid transparent;
      }
    }
  }
`

const ButtonStyled = styled(ButtonBase)`
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  width: 100%;
  color: ${props => props.$white ? '#fff!important' : '#000!important'};
`

const ButtonStyledProdusct = styled(ButtonStyled)`
  color: ${props => props.$nowhite ? '#fff!important' : '#000!important'};
  text-align: left;
  background: ${props => props.$nowhite ? 'transparent' : '#fff'};
  text-transform: capitalize;
  display: block;
  :last-of-type {
    border-bottom: none;
  }
  :hover {
    background: ${props => props.$nowhite ? '#none!important' : '#000!important'};
    color: ${props => props.$nowhite ? '#fff!important' : '#fff!important'};
    transition: background-color 1000ms linear;
  }
`

const Submenu = styled.div`
  position: absolute;
  width: calc(100% + 2px);
  right: -1px;
  border: 1px solid ${props => props.$white ? '#fff' : '#000'};
`

const LinkStyled = styled(Link)`
  color: initial;
  text-decoration: none;
  text-transform: lowercase;
`

const BadgeStyled = styled(Badge)`
  span {
    background-color: red;
    color: #fff;
  }
  svg {
    color: ${props => props.$white ? '#fff!important' : '#000'};
    transition: all 200ms linear;
    :hover {
      color: red!important;
    }
  }
`

const Producst = (props) => {
  const [isOpen, setOpen] = useState(false)
  const history = useHistory()

  const handleCategorySelected = (category) => {
    const slug = slugify(categories[category], {
      lower: true,
      strict: true
    })
    history.push(`/productos/${slug}`)
  }

  return (
    <MenuItem $white={props.white} onMouseEnter={event => setOpen(true)} onMouseLeave={event => setOpen(false)}>
      <LinkStyled as={HashLink} to='/#productos' scroll={el => animatedScroll((el.offsetTop - 150), 300)}>
        <ButtonStyled $white={props.white}>
          Productos {isOpen ? (<ExpandLess style={{ margin: '0px 15px' }} />) : (<ExpandMore style={{ margin: '0px 15px' }} />)}
        </ButtonStyled>
      </LinkStyled>
      {isOpen && (
        <Submenu $white={props.white}>
          {Object.keys(categories).map((cat) => (
            <LinkStyled as='div' onClick={() => handleCategorySelected(cat)} key={cat}>
              <ButtonStyledProdusct $nowhite={props.white}>
                {categories[cat].toLowerCase()}
              </ButtonStyledProdusct>
            </LinkStyled>
          ))}
        </Submenu>
      )}
    </MenuItem>
  )
}

const HeaderComponent = (props) => {
  const [scrolled, setScrolled] = useState(false)
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false)
  const itemsOnCart = useSelector(state => state.cart)
  const { items: pointsOfSale } = usePointOfSaleList()

  const handleScroll = event => {
    const scrolledpx = window.scrollY
    if (scrolledpx > 10 && !scrolled) setScrolled(true)
    if (scrolledpx <= 10 && scrolled) setScrolled(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrolled])

  return (
    <HeaderContainer>
      <MenuMobile open={isMenuMobileOpen} handleOpen={event => setIsMenuMobileOpen(true)} handleClose={event => setIsMenuMobileOpen(false)} />
      <Header id='header' $scrolled={scrolled}>
        <Body>
          <LinkStyled as={HashLink} to='/#initial'>
            <Logo src={(props.white && !scrolled) ? logoWhiteSrc : logoSrc} />
          </LinkStyled>
          <MenuContainer>
            <Hidden smDown>
              <MenuItem $white={props.white && !scrolled}>
                <LinkStyled as={HashLink} to='/#initial' scroll={el => animatedScroll((el.offsetTop), 300)}>
                  <ButtonStyled $white={props.white && !scrolled}>Home</ButtonStyled>
                </LinkStyled>
              </MenuItem>
              <Producst white={props.white && !scrolled} />
              <MenuItem $white={props.white && !scrolled}>
                <LinkStyled as={HashLink} to='/#adn' scroll={el => animatedScroll((el.offsetTop - 160), 300)}>
                  <ButtonStyled $white={props.white && !scrolled}>ADN</ButtonStyled>
                </LinkStyled>
              </MenuItem>
              {!!pointsOfSale && !!pointsOfSale.length && (
                <MenuItem $white={props.white && !scrolled}>
                  <LinkStyled as={HashLink} to='/#pointsales' scroll={el => animatedScroll((el.offsetTop - 150), 300)}>
                    <ButtonStyled $white={props.white && !scrolled}>Puntos de venta</ButtonStyled>
                  </LinkStyled>
                </MenuItem>
              )}
              <MenuItem $white={props.white && !scrolled}>
                <LinkStyled as={HashLink} to='/#contact' scroll={el => animatedScroll((el.offsetTop - 80), 300)}>
                  <ButtonStyled $white={props.white && !scrolled}>Contacto</ButtonStyled>
                </LinkStyled>
              </MenuItem>
            </Hidden>
            <MenuItem $white={props.white && !scrolled}>
              <ButtonStyled $white={props.white && !scrolled}>
                <BadgeStyled badgeContent={itemsOnCart.length} $white={props.white && !scrolled}>
                  <LinkStyled to='/my-cart'>
                    <ShoppingCartOutlined />
                  </LinkStyled>
                </BadgeStyled>
              </ButtonStyled>
            </MenuItem>
            <Hidden mdUp>
              <MenuItem $white={props.white && !scrolled} onClick={event => setIsMenuMobileOpen(!isMenuMobileOpen)} style={{ marginRight: '0px' }}>
                {!isMenuMobileOpen ? (<Menu />) : (<Close />)}
              </MenuItem>
            </Hidden>
          </MenuContainer>
        </Body>
      </Header>
    </HeaderContainer>
  )
}

HeaderComponent.propTypes = {
  white: bool,
}

export default HeaderComponent
