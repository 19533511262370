import xls from 'xlsx-populate';

// helpers
const fileToBuffer = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * parse xlsx file into array
 * @param {File} file xlsx 
 * @returns {Array|null} result
 */
const parser = async (file) => {
  try {
    const fileBlob = await fileToBuffer(file);
    const book = await xls.fromDataAsync(fileBlob);
    const sheet = book.sheet(0);
    let currentRowNumber = 2; // ignore head row
    const rowsValue = []

    // map rows
    while (true) {
      const row = sheet.row(currentRowNumber)
      let cp = row.cell(1).value();
      const col = row.cell(2).value();
      const city = row.cell(4).value();
      const state = row.cell(5).value();
      const cost = row.cell(6).value();
      const smallCost = row.cell(7).value() || 0;

      // format cp
      if (!!cp && !!cp.toString && cp.toString().length < 5) {
        if (cp.toString().length === 4) cp = `0${cp}`;
        if (cp.toString().length === 3) cp = `00${cp}`;
        if (cp.toString().length === 2) cp = `000${cp}`;
        if (cp.toString().length === 1) cp = `0000${cp}`;
      }
      
      // validate data
      if (!cp || !col || !city || !state || (!cost && cost !== 0) || (!smallCost && smallCost !== 0)) break;
      currentRowNumber = currentRowNumber + 1;

      rowsValue.push({ cp, col, city, state, cost, smallCost });
    }

    return rowsValue.length ? rowsValue : null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default parser;
