import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Layout from '../../components/layout_admin'
import Title from '../../components/page_title'
import { Grid, Box, Typography, Button } from '@material-ui/core'
import { db } from '../../../modules/firebase'
import snapshotParser from '../../../helpers/snapshotparser'
import Picture from '../../../components/picture'
import currency from '../../../helpers/currency'
import { Link } from 'react-router-dom'
import admin from '../../hoc/admin'

const Item = styled.div`
  border: 1px solid #2196f3;
  border-radius: 10px;
  overflow: hidden;
`

const View = props => {
  const [state, setState] = useState({ loading: true, items: [] })

  const handlefetch = async () => {
    const snapshot = await db.collection('sizes').get()
    const items = snapshotParser(snapshot)
    setState({ items, loading: false })
  }

  useEffect(() => {
    handlefetch()
  })

  return (
    <Layout title='Tamaños'>
      <Title>Tamaños</Title>
      <Box mt={2}>
        {!state.loading && !!state.items.length && (
          <Grid container spacing={3}>
            {state.items.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Item>
                  <Picture height={80} src={item.picture} />
                  <Box p={2}>
                    <Grid container justify='space-between'>
                      <Typography variant='h6'>{item.name}</Typography>
                      <Typography color='secondary' variant='h6'>$ {currency.formatMoney(item.price)}</Typography>
                    </Grid>
                    <Typography variant='subtitle1'><span style={{ color: 'gray' }}>Asador:</span> {item.rotisserie}</Typography>
                    <Typography variant='subtitle1'><span style={{ color: 'gray' }}>Parrilla:</span> {item.grill}</Typography>
                  </Box>
                  <Box p={2}>
                    <Link to={{ pathname: '/edit-sizes', state: { ...item } }}>
                      <Button color='secondary' variant='contained' fullWidth>
                        Editar
                      </Button>
                    </Link>
                  </Box>
                </Item>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Layout>
  )
}

View.propTypes = {

}

export default admin(View)
