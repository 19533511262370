import { Box } from '@material-ui/core';
import { number, object } from 'prop-types';
import React from 'react';
import styled from 'styled-components'
import useHeaderheight from '../../../hooks/useHeaderheight';

const SumaryParts = (props) => {
  const top = useHeaderheight()

  return (
    <Wrapper style={{ top: `calc(${top} + 50px)` }}>
      <Box fontSize="1.5em" marginBottom=".5em">RESUMEN</Box>

      <Row $defined={props.currentStep > 1}>
        <Box component="span" marginRight=".2em">tamaño:</Box>
        {props.currentStep > 1 && (props.data.size.title || props.data.size.name)}
        {props.currentStep <= 1 && <>S/E</>} 
      </Row>

      <Row $defined={props.currentStep > 2}>
        <Box component="span" marginRight=".2em">Diseño de puerta:</Box>
        {props.currentStep > 2 && (props.data.doorDesign.title || props.data.doorDesign.name)}
        {props.currentStep <= 2 && <>S/E</>}
      </Row>

      <Row $defined={props.currentStep > 3}>
      <Box component="span" marginRight=".2em">tipo de parrilla:</Box>
        {props.currentStep > 3 && (props.data.grillType.title || props.data.grillType.name)}
        {props.currentStep <= 3 && <>S/E</>}
      </Row>

      <Row $defined={props.currentStep > 4}>
        <Box component="span" marginRight=".2em">mesas laterales::</Box>
        {props.currentStep > 4 && (props.data.sideTables.title || props.data.sideTables.name)}
        {props.currentStep <= 4 && <>S/E</>}
      </Row>

      <Row $defined={props.currentStep > 5}>
        <Box component="span" marginRight=".2em">tipo de llantas:</Box>
        {props.currentStep > 5 && (props.data.tiresType.title || props.data.tiresType.name)}
        {props.currentStep <= 5 && <>S/E</>}
      </Row>

      <Row $defined={props.currentStep > 6}>
        <Box component="span" marginRight=".2em">interior:</Box>
        {props.currentStep > 6 && (props.data.interiorType.title || props.data.interiorType.name)}
        {props.currentStep <= 6 && <>S/E</>}
      </Row>

      <Row $defined={props.currentStep > 7 && !!props.data.firm}>
        <Box component="span" marginRight=".2em">grabado:</Box>
        {props.currentStep > 7 && !!props.data.firm && props.data.firm}
        {(props.currentStep <= 7 || !props.data.firm) && <>S/E</>}
      </Row>

      <Row $defined={props.currentStep > 8}>
        <Box component="span" marginRight=".2em">color:</Box>
        {props.currentStep > 8 && (props.data.color.title || props.data.color.name)}
        {props.currentStep <= 8 && <>S/E</>}
      </Row>
    </Wrapper>
  );
};

SumaryParts.propTypes = {
  currentStep: number.isRequired,
  data: object.isRequired,
};

const Wrapper = styled.div`
  background: #e1e1e1;
  font-family: 'bebas';
  font-size: 22px;
  padding: 1.5em;
  text-transform: uppercase;
  position: sticky;
  @media screen and (max-width:1300px) {
    font-size: 18px;
  }

  @media screen and (max-width:600px) {
    font-size: 15px;
  }
`

const Row = styled.div`
  line-height: 1em;
  color: ${(props => !!props.$defined ? '#000' : 'gray')};
  border-bottom: 1px solid ${(props) => !!props.$defined ? '#000' : 'gray'};
  padding: .4em 0em;
  :last-child {
    border-bottom: none;
  }
`

export default SumaryParts;