/* eslint-disable react/prop-types */
import styled from 'styled-components'
import { Box, Divider } from '@material-ui/core'
import React from 'react'

export const Container = styled.div`
  width: 50%;
  margin: auto;
  margin-top: ${props => props.$top || 'auto'};
  @media screen and (max-width:1000px) {
    width: 70%;
  }
  @media screen and (max-width:700px) {
    width: 80%;
  }
`
export const Header = styled.div`
  padding: 10px;
  line-height: 1em;
  font-size: 44px;
  font-family: 'bebas';
  background: #000;
  text-align: center;
  color: #fff;
  @media screen and (max-width:1800px) {
    font-size: 35px;
  }
  @media screen and (max-width:700px) {
    font-size: 25px;
  }
`
export const BoxComponent = (props) => (
  <Box pt={2} pb={2}>
    {props.children}
  </Box>
)

export const Text = styled.div`
  font-size: 35px;
  font-family: 'bebas';
  @media screen and (max-width:1800px) {
    font-size: 30px;
  }
  @media screen and (max-width:700px) {
    font-size: 20px;
  }
`
export const TextSpecial = styled(Text)`
  @media screen and (max-width:700px) {
    font-size: 25px
  }
`

export const DividerComponent = () => (
  <Divider style={{ backgroundColor: '#000' }} />
)
