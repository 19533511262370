import React, { useMemo } from 'react'
import LayoutUser from '../../components/layout_user'
import { Grid, Box, Divider, useMediaQuery, Collapse } from '@material-ui/core'
import useHeaderheight from '../../../hooks/useHeaderheight'
import BackBread from '../../components/backdream'
import Menu from '../products/menu'
import Skeleton from '../products/skeleton'
import Footer from '../../components/footer'
import Footerinfo from '../../components/footerInfo'
import currency from '../../../helpers/currency'
import { Check } from '@material-ui/icons'
import { InputStyled, MyCustomCheckBox, Text, TextGray, ItemContainer, PictureStyled, Container, MenuContainer, Body, Header, ItemContainerColor, TitleColor, Button } from './styled'
import { func, object, array, bool, number, element } from 'prop-types'
import StepInfoHeader from './stepInfoHeader'
import SumaryParts from './sumaryParts'
import Related from '../item/related'

const View = (props) => {
  const top = useHeaderheight()
  const ismobile = useMediaQuery('(max-width:600px)')
  const isMedium = useMediaQuery('(max-width:1000px)')

  // sections filtered
  const doorDesign = props.items.filter((currentItem) => currentItem.section === 'doorDesign')
  const grillType = props.items.filter((currentItem) => currentItem.section === 'grillType')
  const sideTables = props.items.filter((currentItem) => currentItem.section === 'sideTables')
  const tiresType = props.items.filter((currentItem) => currentItem.section === 'tiresType')
  const interiorType = props.items.filter((currentItem) => currentItem.section === 'interiorType')


  const valuesSelectedWithFullData = useMemo(() => {
    try {
      return {
        color: props.colors.find((element) => element.id === props.data.color),
        doorDesign: props.items.find((element) => element.id === props.data.doorDesign),
        grillType: props.items.find((element) => element.id === props.data.grillType),
        interiorType: props.items.find((element) => element.id === props.data.interiorType),
        sideTables: props.items.find((element) => element.id === props.data.sideTables),
        tiresType: props.items.find((element) => element.id === props.data.tiresType),
        size: props.sizes.find((element) => element.keyname === props.data.size),
        firm: (props.data.firm && props.data.firmText) ? props.data.firmText : null,
      }
    } catch (error) {
      return {}
    }
  })

  return (
    <LayoutUser>
      <div name='wrapper' style={{ marginTop: top }}>
        <BackBread
          steps={[
            { label: 'Home', to: '/' },
            { label: 'ARMA TU ASADOR' }
          ]}
        />
        <Container style={{ justifyContent: 'flex-end', marginTop: '45px' }}>
          <Header>
            ARMA TU ASADOR
          </Header>
        </Container>
        <Container>
          <MenuContainer>
            {(props.currentStep === 1 || !!isMedium) && (
              <Menu {...props} currentCategory='buildyourgrill' />
            )}
            {props.currentStep >= 2 && !isMedium && (
              <SumaryParts currentStep={props.currentStep} data={valuesSelectedWithFullData} />
            )}
          </MenuContainer>
          <Body>
            {!props.items.length && props.loading && <Skeleton /> }
            {!props.loading && (
              <>
                <StepInfoHeader value={props.currentStep} />
                <Collapse in={props.currentStep === 1}>
                  <Box pt={4}>
                    <Box mb={2}>
                      <Text>
                        <Box fontSize="1.2em">
                          SELECCIONA UN TAMAÑO
                        </Box>
                      </Text>
                    </Box>
                    <Grid container spacing={5}>
                      {props.sizes.map((singleSize, index) => (
                        <Grid key={index} item xs={6} sm={6} lg={4}>
                          <ItemContainer onClick={event => props.onSizeSelect(singleSize.keyname)}>
                            <PictureStyled src={singleSize.picture} $selected={singleSize.keyname === props.data.size} />
                            <Box pt={2}>
                              <Grid container justify='space-between'>
                                <Text>{singleSize.name}</Text>
                                <Text>${currency.formatMoney(singleSize.price)}</Text>
                              </Grid>
                              <TextGray> ASADOR {singleSize.rotisserie}</TextGray>
                              <TextGray> PARRILLA {singleSize.grill}</TextGray>
                            </Box>
                          </ItemContainer>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Collapse>

                <Collapse in={props.currentStep === 2}>
                  <Box pt={4}>
                    <Box mb={2}>
                      <Text>
                        <Box fontSize="1.2em">
                          SELECCIONA UN DISEÑO DE PUERTA
                        </Box>
                      </Text>
                    </Box>
                    <Grid container spacing={5}>
                      {doorDesign.map((currentItem, index) => (
                        <Grid key={index} item xs={6} sm={6} lg={4}>
                          <ItemContainer onClick={() => props.onSectionSelect('doorDesign', currentItem.id)}>
                            <PictureStyled src={currentItem.picture} $selected={props.data.doorDesign === currentItem.id} />
                            <Box pt={2}>
                              <Grid container justify='space-between'>
                                <TextGray>{currentItem.title}</TextGray>
                                <TextGray>${props.data.size === 'big' ? currency.formatMoney(currentItem.big_price) : currency.formatMoney(currentItem.medium_price)}</TextGray>
                              </Grid>
                            </Box>
                          </ItemContainer>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Collapse>

                <Collapse in={props.currentStep === 3}>
                  <Box pt={4}>
                    <Box mb={2}>
                      <Text>
                        <Box fontSize="1.2em">
                          SELECCIONA UN TIPO DE PARRILLA
                        </Box>
                      </Text>
                    </Box>
                    <Grid container spacing={5}>
                      {grillType.map((currentItem, index) => (
                        <Grid key={index} item xs={6} sm={6} lg={4}>
                          <ItemContainer onClick={() => props.onSectionSelect('grillType', currentItem.id)}>
                            <PictureStyled src={currentItem.picture} $selected={props.data.grillType === currentItem.id} />
                            <Box pt={2}>
                              <Grid container justify='space-between'>
                                <TextGray>{currentItem.title}</TextGray>
                                <TextGray>${props.data.size === 'big' ? currency.formatMoney(currentItem.big_price) : currency.formatMoney(currentItem.medium_price)}</TextGray>
                              </Grid>
                            </Box>
                          </ItemContainer>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Collapse>

                <Collapse in={props.currentStep === 4}>
                  <Box pt={4}>
                    <Box mb={2}>
                      <Text>
                        <Box fontSize="1.2em">
                          SELECCIONA UNA OPCIÓN DE MESAS LATERALES
                        </Box>
                      </Text>
                    </Box>
                    <Grid container spacing={5}>
                      {sideTables.map((currentItem, index) => (
                        <Grid key={index} item xs={6} sm={6} lg={4}>
                          <ItemContainer onClick={() => props.onSectionSelect('sideTables', currentItem.id)}>
                            <PictureStyled src={currentItem.picture} $selected={props.data.sideTables === currentItem.id} />
                            <Box pt={2}>
                              <Grid container justify='space-between'>
                                <TextGray>{currentItem.title}</TextGray>
                                <TextGray>${props.data.size === 'big' ? currency.formatMoney(currentItem.big_price) : currency.formatMoney(currentItem.medium_price)}</TextGray>
                              </Grid>
                            </Box>
                          </ItemContainer>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Collapse>
                
                <Collapse in={props.currentStep === 5}>
                  <Box pt={4}>
                    <Box mb={2}>
                      <Text>
                        <Box fontSize="1.2em">
                          SELECCIONA UN TIPO DE LLANTAS
                        </Box>
                      </Text>
                    </Box>
                    <Grid container spacing={5}>
                      {tiresType.map((currentItem, index) => (
                        <Grid key={index} item xs={6} sm={6} lg={4}>
                          <ItemContainer onClick={() => props.onSectionSelect('tiresType', currentItem.id)}>
                            <PictureStyled src={currentItem.picture} $selected={props.data.tiresType === currentItem.id} />
                            <Box pt={2}>
                              <Grid container justify='space-between'>
                                <TextGray>{currentItem.title}</TextGray>
                                <TextGray>${props.data.size === 'big' ? currency.formatMoney(currentItem.big_price) : currency.formatMoney(currentItem.medium_price)}</TextGray>
                              </Grid>
                            </Box>
                          </ItemContainer>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Collapse>

                <Collapse in={props.currentStep === 6}>
                  <Box pt={4}>
                    <Box mb={2}>
                      <Text>
                        <Box fontSize="1.2em">
                          SELECCIONA UN TIPO DE INTERIOR
                        </Box>
                      </Text>
                    </Box>
                    <Grid container spacing={5}>
                      {interiorType.map((currentItem, index) => (
                        <Grid key={index} item xs={6} sm={6} lg={4}>
                          <ItemContainer onClick={() => props.onSectionSelect('interiorType', currentItem.id)}>
                            <PictureStyled src={currentItem.picture} $selected={props.data.interiorType === currentItem.id} />
                            <Box pt={2}>
                              <Grid container justify='space-between'>
                                <TextGray>{currentItem.title}</TextGray>
                                <TextGray>${props.data.size === 'big' ? currency.formatMoney(currentItem.big_price) : currency.formatMoney(currentItem.medium_price)}</TextGray>
                              </Grid>
                            </Box>
                          </ItemContainer>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Collapse>
                
                <Collapse in={props.currentStep === 7}>
                  <Box paddingTop="3em" paddingBottom="3em">
                    <Box pt={4}>
                      <Box mb={2}>
                        <Text>
                          <Box fontSize="1.2em">
                            GRABADO PERSONALIZADO
                          </Box>
                        </Text>
                      </Box>
                      <Grid container spacing={1}>
                        <Grid container item xs={12} md={8} wrap='nowrap'>
                          <MyCustomCheckBox onClick={event => props.setData({ firm: !props.data.firm })}>
                            {props.data.firm && (
                              <Check />
                            )}
                          </MyCustomCheckBox>
                          <InputStyled
                            label={ismobile ? 'Grabado personalizado' : 'Introduce la palabra que se grabará en tu asador'}
                            fullWidth
                            value={props.data.firmText || ''}
                            variant='outlined'
                            disabled={!props.data.firm}
                            focused={props.data.firm}
                            onChange={event => props.setData({ firmText: event.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={8} container justify='flex-end'>
                          <TextGray>$ 200.00 </TextGray>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Collapse>
                

                <Collapse in={props.currentStep === 8} >
                  <div>
                    {!!props.colors.length && (
                      <Box marginTop="2em" marginBottom="2em">
                        <Box pt={4}>
                          <Box mb={2}>
                            <Text>
                              <Box fontSize="1.2em">
                                SELECCIONA EL COLOR DE TU ASADOR
                              </Box>
                            </Text>
                          </Box>
                          <Grid container spacing={1}>
                            {props.colors.map(color => (
                              <ItemContainerColor key={color.id} onClick={event => props.onSectionSelect('color', color.id)}>
                                <PictureStyled src={color.picture} $selected={props.data.color === color.id} />
                                <TitleColor>{color.title}</TitleColor>
                              </ItemContainerColor>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </div>
                </Collapse>

                <Collapse in={props.currentStep === 9}>
                  <div>
                    {!!props.accesories.length && (
                      <Box pt={4}>
                        <Box mb={2}>
                          <Text>
                            <Box fontSize="1.2em">
                              ¿DESEAS AGREGAR ALGÚN COMPLEMENTO (OPCIONAL)? 
                              <Box component="span" color="gray"> PUEDES SELECCIONAR VARIOS</Box>
                            </Box>
                          </Text>
                        </Box>
                        <Grid container spacing={5}>
                          {props.accesories.map(accesorie => (
                            <Grid key={accesorie.id} item xs={6} sm={6} lg={4}>
                              <ItemContainer onClick={event => props.onAccesorieSelect(accesorie)}>
                                <PictureStyled src={accesorie.picture} $selected={props.isThisItemOnCart(accesorie)} />
                                <Box pt={2}>
                                  <Grid container justify='space-between' spacing={2}>
                                    <Grid item xs={7}>
                                      <TextGray>{accesorie.title}</TextGray>
                                    </Grid>
                                    <Grid item xs={5}>
                                      {!!accesorie.multiplePrice && (
                                        <TextGray style={{ textAlign: 'right' }}>${props.data.size === 'big' ? currency.formatMoney(accesorie.big_price) : currency.formatMoney(accesorie.medium_price)}</TextGray>
                                      )}
                                      {!accesorie.multiplePrice && (
                                        <TextGray style={{ textAlign: 'right' }}>${currency.formatMoney(accesorie.price)}</TextGray>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </ItemContainer>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  </div>
                </Collapse>

                {props.currentStep > 1 && ismobile && (
                  <Box marginTop="3em">
                    <MenuContainer>
                      <SumaryParts currentStep={props.currentStep} data={valuesSelectedWithFullData} />
                    </MenuContainer>
                  </Box>
                )}

                {/* buttons */}
                {props.currentStep < 9 && (
                  <Box pt={8} pb={6}>
                    <Grid container justify='flex-end'>
                      {props.currentStep > 1 && (
                        <>
                          <Button handleClick={props.onPrevStep} variant='contained'>
                            atrás
                          </Button>
                          <Box paddingRight="2em" />
                        </>
                      )}
                      <Button handleClick={props.onNextStep} variant='contained'>
                        Siguente
                      </Button>
                    </Grid>
                  </Box>
                )}

                {props.currentStep === 9 && (
                  <Box pt={8} pb={6}>
                    <Grid container justify='flex-end'>
                      {props.currentStep > 1 && (
                        <>
                          <Button handleClick={props.onPrevStep} variant='contained'>
                            atrás
                          </Button>
                          <Box paddingRight="2em" />
                        </>
                      )}
                      <Button handleClick={props.onAddToCart} variant='contained'>
                        {props.editing ? 'ACTUALIZAR ASADOR' : 'AGREGAR A CARRITO'}
                      </Button>
                    </Grid>
                  </Box>
                )}
              </>
            )}

            <Box pt={5} pb={5}>
              <Divider />
            </Box>
          </Body>
        </Container>
        <Related small />
        <Footerinfo />
      </div>
      <Footer />
    </LayoutUser>
  )
}

View.propTypes = {
  onAddToCart: func.isRequired,
  isThisItemOnCart: func.isRequired,
  onAccesorieSelect: func.isRequired,
  onSizeSelect: func.isRequired,
  onSectionSelect: func.isRequired,
  data: object.isRequired,
  setData: func.isRequired,
  items: array.isRequired,
  loading: bool.isRequired,
  sizes: array.isRequired,
  accesories: array.isRequired,
  colors: array.isRequired,
  editing: bool.isRequired,
  currentStep: number.isRequired,
  onNextStep: func.isRequired,
  onPrevStep: func.isRequired,
}



export default View
