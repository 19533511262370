import React from 'react'
import styled from 'styled-components'
import Layout from '../../components/layout_admin'
import Title from '../../components/page_title'
import PeriodSelector from './period_selector'
import Order from './order'
import propTypes, { oneOf } from 'prop-types'
import Skeleton from './skeleton'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import ViewSelector from './selectorView'
import List from './list'
import Input from '../../components/input'
import { Box, Grid } from '@material-ui/core'

const View = props => {
  return (
    <Layout title='Mis Pedidos'>
      <Title>Mis Pedidos</Title>
      <Box mb={2}>
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs={12} md={4}>
            <PeriodSelector {...props} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input fullWidth placeholder='Buscar por id' value={props.keywords} size='small' type='text' onChange={target => props.setKewords(target.value)} />
          </Grid>
          {props.isTableAvalible && (
            <Grid item xs={12} md={4}>
              <ViewSelector />
            </Grid>
          )}
        </Grid>
      </Box>
      {props.items.length > 0 && !props.loading && props.viewType === 'grid' && (
        <Flex>
          {props.items.map(item => (
            <Order {...item} key={item.id} />
          ))}
        </Flex>
      )}
      {props.items.length > 0 && !props.loading && props.viewType === 'list' && (
        <List items={props.items} />
      )}
      {props.items.length === 0 && props.loading && (
        <Skeleton />
      )}
      {!props.loading && props.items.length === 0 && (
        <Empty>
          <LoyaltyIcon />
          {props.keywords && !!props.keywords.toString().length && (
            <>
              No encontramos resultados de busqueda
            </>
          )}
          {!props.keywords && (
            <>
              Aun no tienes pedidos
            </>
          )}
        </Empty>
      )}
    </Layout>
  )
}

View.propTypes = {
  items: propTypes.array,
  loading: propTypes.bool,
  viewType: oneOf(['list', 'grid']),
  isTableAvalible: propTypes.bool,
  setKewords: propTypes.func,
  keywords: propTypes.string
}

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`
const Empty = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  flex-direction: column;
  font-size: 2em;
  color: #008ffd4d;
  & svg {
    margin-bottom: 30px;
    font-size: 150px;
    color: #008ffd4d;
  }
`

export default View
