/* eslint-disable guard-for-in */
import { useState } from 'react'

const useForm = (initialState = {}, rules = {}) => {
  const [values, setValues] = useState(initialState)
  const [inputErrors, setInputErrors] = useState({})

  /**
   * update form state
   * @param {{}} event domEvent
   */
  const handleInputChange = (target) => {
    setValues((prevValues) => ({ ...prevValues, [target.name]: target.value }))
  }

  /**
   * validate form and update inputErrors
   * @param {{}} scopedRules use scoped rules if exist before use global rules
   * @returns object of errors
   */
  const validateInputs = (scopedRules) => {
    const currentUssingRules = scopedRules || rules
    const errors = {}
    for (const inputName in currentUssingRules) {
      const validator = currentUssingRules[inputName]
      const inputErrorMessage = validator(values[inputName], values)
      if (inputErrorMessage) errors[inputName] = inputErrorMessage
    }
    setInputErrors(errors)
    return {
      errors,
      firstErrorMessage: Object.values(errors).length ? Object.values(errors)[0] : null
    }
  }

  /**
   * remove error on list
   * @param {{}} event dom event
   */
  const handleRemoveError = (target) => {
    console.log('target', target)
    const newErrors = { ...inputErrors }
    delete newErrors[target.name]
    setInputErrors(newErrors)
  }

  const computeInputValue = (name) => (values[name] !== null && values[name] !== undefined ? values[name] : '')

  /**
   * validate single input and update inputErrors
   * @param {{}} event dom event
   */

  const getInputProps = (name) => ({
    onChange: handleInputChange,
    value: computeInputValue(name),
    removeError: handleRemoveError,
    errors: Object.keys(inputErrors || {}),
    name
  })

  return {
    getInputProps,
    validateInputs,
    values,
    setValues
  }
}

export default useForm
