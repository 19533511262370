/* eslint-disable camelcase */
import { db, storageRef, firebase } from './firebase'
import snapshotParser from '../helpers/snapshotparser'
import filterobject from '../helpers/filterobject'
import slugify from 'slugify'
import { ENV } from '../config'

const article = {
  getListPaginated (limit = 10, filters = {}) {
    console.log('filters', filters)
    var last = null
    var finished = false

    return async () => {
      if (finished) return []
      try {
        await new Promise(resolve => setTimeout(resolve, 1000))
        let query = db.collection('Articulos')
        if (filters.category) query = query.where('category', '==', filters.category)
        if (filters.doShowAtBuild !== null && filters.doShowAtBuild !== undefined) {
          query = query.where('doShowAtBuild', '==', filters.doShowAtBuild)
        }
        if (last) query = query.startAfter(last)
        if (limit) query = query.limit(limit)
        const snapshot = await query.get()
        last = snapshot.docs[snapshot.docs.length - 1]
        if (snapshot.docs.length !== limit) finished = true
        console.log(snapshotParser(snapshot).map(i => i.category))
        return snapshotParser(snapshot)
      } catch (error) {
        console.log('__error__', error)
        return []
      }
    }
  },

  /**
   * @param data::object
   * @return id created || false
   * save order on database
   */
  async saveOrder (data) {
    try {
      const date = new Date()
      const period = `${date.getMonth() + 1}-${date.getFullYear()}`
      const result = await db.collection(`Ordenes/Pedidos/${period}`).add({
        ...data,
        date: new Date()
      })
      await db.doc(`Ordenes/Pedidos/${period}/${result.id}`).update({ id: result.id })
      await db.doc('Ordenes/Pedidos').update({
        counter: firebase.firestore.FieldValue.increment(1)
      })
      return result.id
    } catch (error) {
      console.error('error_description:', error)
      return false
    }
  },

  /**
   * @param id::string or url
   * @return data::object || false
   * getSpecific article
   */
  async get (id) {
    try {
      // get by id or url
      let snap = null
      snap = await db.doc(`Articulos/${id}`).get()
      if (!snap.exists) {
        const querySnapshot = await db.collection('Articulos').where('url', '==', id).get()
        snap = querySnapshot.docs[0]
      }

      const data = snapshotParser(snap)
      return data
    } catch (error) {
      console.error('error_description:', error)
      return false
    }
  },

  /**
   * @param id::strinf
   * @return true||false::bool
   * set disable on status
   */
  async setInactive (id) {
    await db.doc(`Articulos/${id}`).update({
      isActive: false
    })
  },

  /**
   * @param id::strinf
   * @return true||false::bool
   * set active on status
   */
  async setActive (id) {
    await db.doc(`Articulos/${id}`).update({
      isActive: true
    })
  },

  /**
 * @param id::strinf
 * @return true||false::bool
 * delete item on database
 */
  async delete (id) {
    try {
      await db.doc(`Articulos/${id}`).delete()
      return true
    } catch (error) {
      console.error('error_Descript:', error)
      return false
    }
  },
  /**
   * @params data::object
   * @return id created or false
   * create a new item
  **/
  async upload (data) {
    var allowed = []
    if (data.category === 'steakhouses') allowed = ['videos', 'availableSignature', 'picture', 'pictures', 'colors', 'title', 'description', 'sku', 'category', 'medium_price', 'big_price', 'medium_rotisserie', 'medium_grill', 'big_grill', 'big_rotisserie', 'multiplePrice', 'price', 'grill', 'rotisserie']
    if (data.category === 'buildyourgrill') allowed = ['picture', 'pictures', 'title', 'sku', 'category', 'medium_price', 'big_price', 'section']
    if (data.category === 'accesories') allowed = ['videos', 'multiplePrice', 'doShowAtBuild', 'picture', 'pictures', 'title', 'sku', 'category', 'medium_price', 'big_price', 'description', 'price']

    const dataFiltered = filterobject(data, allowed)
    dataFiltered.date = new Date()
    dataFiltered.isActive = true
    dataFiltered.url = article.makeUrl(dataFiltered.title)

    // keep only valid prices
     if (dataFiltered.price || dataFiltered.medium_price || dataFiltered.big_price) {
      try {
        if (dataFiltered.multiplePrice) {
          dataFiltered.price = null;
        } else {
          dataFiltered.medium_price = null;
          dataFiltered.big_price = null;
        }
      } catch (error) {
        console.log('error casting prices');
      }
    }

    if (typeof dataFiltered.doShowAtBuild !== 'boolean') dataFiltered.doShowAtBuild = false

    try {
      const { id } = await db.collection('Articulos').add(dataFiltered)
      await db.collection('Articulos').doc(id).update({ id })
      return id
    } catch (error) {
      console.error('error_description:', error)
      return false
    }
  },
  /**
   * @params id:int, data:object
   * @return true or false
   * update a data of item
   */
  async update (id, data) {
    var allowed = ['videos', 'price', 'multiplePrice', 'availableSignature', 'doShowAtBuild', 'id', 'colors', 'picture', 'pictures', 'title', 'description', 'sku', 'category', 'medium_price', 'big_price', 'medium_rotisserie', 'medium_grill', 'big_grill', 'big_rotisserie', 'section', 'grill', 'rotisserie']
    const dataFiltered = filterobject(data, allowed)
    if (dataFiltered.title) dataFiltered.url = article.makeUrl(dataFiltered.title)
    dataFiltered.date = new Date()

    // keep only valid prices
    if (dataFiltered.price || dataFiltered.medium_price || dataFiltered.big_price) {
      try {
        if (dataFiltered.multiplePrice) {
          dataFiltered.price = null;
        } else {
          dataFiltered.medium_price = null;
          dataFiltered.big_price = null;
        }
      } catch (error) {
        console.log('error casting prices');
      }
    }

    try {
      await db.doc(`Articulos/${id}`).update(dataFiltered)
      return true
    } catch (error) {
      console.error('error_description:', error)
      return false
    }
  },
  /**
   * @params id_article::int and photo::file and OPTIONAL::bool
   * @return url or false
   */
  async uploadPicture (id, picture, prefix) {
    var name = picture.name
    if (prefix) name = `${prefix}_${name}`
    const nameEncoded = encodeURIComponent(name)
    const newNameEncoded = encodeURIComponent(`thumb@1100_${name}`)
    const { ref } = await storageRef.child(`${id}/${name}`).put(picture)
    const url = await ref.getDownloadURL()
    var urlTransformed = ENV === 'production' ? url.replace(nameEncoded, newNameEncoded) : url;
    urlTransformed = urlTransformed.split('&token')[0]
    return urlTransformed
  },
  /**
   * @params lastItem::snap, Limit::int
   * @return Object of = Items::array, lastItem::snap
   */
  async getList (lastItem = null, limit = null) {
    try {
      var query = db.collection('Articulos').orderBy('date', 'desc')
      if (limit) query = query.limit(limit)
      if (lastItem) query = query.startAfter(lastItem)
      const snapshot = await query.get()
      const items = snapshotParser(snapshot)
      return { items, last: snapshot.docs[snapshot.docs.length - 1] }
    } catch (error) {
      console.error('error_description:', error)
      return { items: [], last: null }
    }
  },

  /**
   * @param urlPicture::string and idarticle::string
   * @return true or false :: bool
   */
  async deletePicture (id, url) {
    try {
      const urlDecoded = decodeURIComponent(url)
      var urlToDelete = urlDecoded.split(id)[1]
      urlToDelete = urlToDelete.split('?')[0]
      urlToDelete = `${id}/${urlToDelete}`
      await storageRef.child(urlToDelete).delete()
      return true
    } catch (error) {
      console.log('error_description:', error)
      return false
    }
  },

  makeUrl (title) {
    const url = slugify(title, {
      replacement: '-',
      remove: undefined,
      lower: true,
      strict: true,
      trim: true
    })
    return url;
  }
}

window.article = article

export default article
