import React, { useEffect, useMemo, useState } from 'react'
import Layout from '../../components/layout_admin'
import Title from '../../components/page_title'
import useObjectState from '../../../hooks/useObjectState'
import { getList, deleteOne, addListByFile } from '../../../modules/shipping'
import { Container, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Box, IconButton, Hidden, Grid, InputAdornment } from '@material-ui/core'
import { setAlert } from '../../../flux/alert'
import { useDispatch } from 'react-redux'
import Create from './create'
import currency from '../../../helpers/currency'
import { Delete, Edit, Search, LocalShipping } from '@material-ui/icons'
import styled from 'styled-components'
import Input from '../../components/input'
import Dropzone from './dropzone'
import { setNotification } from '../../../flux/notification'
import useVirtualize from '../../../hooks/useVirtualize';

const RowComponent = (props) => {
  return (
    <TableRow key={props.cp}>
      <TableCell> {props.cp} </TableCell>
      <TableCell>${currency.formatMoney(props.cost)}</TableCell>
      <TableCell>
        {(props.smallCost === 0 || !!props.smallCost) && (
          <>${currency.formatMoney(props.smallCost)}</>
        )}
        {(props.smallCost !== 0 && !props.smallCost) && (
          <>Indefinido</>
        )}
      </TableCell>
      <Hidden xsDown>
        <TableCell>{props.comment}</TableCell>
      </Hidden>
      <TableCell>
        <IconButton onClick={event => props.onEdit(props)} style={{ textTransform: 'none' }} variant='contained' color='secondary' size='small'>
          <Edit />
        </IconButton>
        <IconButton onClick={event => props.onDelete(props.cp)} style={{ textTransform: 'none' }} variant='contained' color='secondary' size='small'>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const ShippingCost = () => {
  const dispatch = useDispatch()
  const [state, setState] = useObjectState({ loading: true, items: [] })
  const [isDialogActive, setDialog] = useState(false)
  const [edit, setEdit] = useState(null)
  const [keywords, setKeywords] = useState('')

  var itemsFiltered = state.items.sort((a, b) => parseInt(a.cp) - parseInt(b.cp))

  itemsFiltered = useMemo(
    () => {
      if (keywords && keywords.toString().length) {
        return state.items.filter(current => {
          const currentCp = current.cp.toString()
          return currentCp.includes(keywords.toString())
        })
      } else {
        return state.items
      }
    },
    [keywords, state.items]
  )

  const itemsVirtualized = useVirtualize(itemsFiltered, 20)

  const handleFetch = async () => {
    const items = await getList()
    setState({ loaidng: false, items })
  }

  useEffect(() => {
    handleFetch()
  }, [])

  const handleDelete = (id) => {
    dispatch(setAlert({
      title: '¿Seguro que quieres borrar este código postal?',
      message: 'Una vez realizada esta acción no podras recurar la informacion',
      action: async () => {
        await deleteOne(id)
        await handleFetch()
      },
      textAction: 'Eliminar'
    }))
  }

  const handleEdit = (data) => {
    setEdit(data)
    setDialog(true)
  }

  const handleFileSelect = async (file) => {
    dispatch(setAlert({
      title: 'Seguro quieres subir este archivo?',
      message: 'Esto sobrescribirá todos los costos de envío actuales',
      action: async () => {
        const result = await addListByFile(file)
        result.error
          ? dispatch(setNotification({ type: 'error', message: result.message }))
          : dispatch(setNotification({ type: 'success', message: result.message }))
        handleFetch()
      },
    }))
  }

  return (
    <Layout title='Costos de envío'>
      <Create
        open={isDialogActive}
        onClose={event => {
          setDialog(false)
          setEdit(null)
        }}
        onSuccess={() => handleFetch()}
        data={edit}
      />

      <Container maxWidth='md' disableGutters>
        <Title>Costos de envío</Title>
        <Box mt={2} mb={2}>
          <Grid container justify='space-between'>
            <Grid container item xs={12} sm={6} md={6}>
              <Button onClick={event => setDialog(true)} color='primary' variant='contained'>Crear</Button>
              <Box paddingRight="1em" />
              <DropzoneStyled
                accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                onSelect={handleFileSelect}
              >
                <Button color='primary' variant='contained'>Subir archivo</Button>
              </DropzoneStyled>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Hidden smUp>
                <Box pt={2} style={{ width: '100%' }} />
              </Hidden>
              <Input
                value={keywords || ''}
                onChange={event => setKeywords(event.value)}
                placeholder='Buscar'
                type='number'
                size='small'
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Search style={{ color: '#008ffd' }} />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {!!itemsVirtualized.length && (
          <>
            <TableContainerStyled component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Código postal</TableCell>
                    <TableCell>Costo asadores</TableCell>
                    <TableCell>Costo accesorios</TableCell>
                    <Hidden xsDown>
                      <TableCell>Comentario</TableCell>
                    </Hidden>
                    <TableCell>Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemsVirtualized.map(row => (
                    <RowComponent
                      key={row.cp}
                      onDelete={handleDelete}
                      onEdit={handleEdit}
                      {...row}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainerStyled>
          </>
        )}
        {!itemsVirtualized.length && (
          <>
            <EmptyContainer>
              <LocalShipping />
              No se encontro código postal
            </EmptyContainer>
          </>
        )}
      </Container>
    </Layout>
  )
}

const TableContainerStyled = styled(TableContainer)`
    padding: 5px;
    .MuiTableCell-root {
      @media screen and (max-width:600px) {
        padding: 15px 2px;
      }
    }
  
`
const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  text-align: center;
  font-size: 2em;
  color: #008ffd63;
  flex-direction: column;
  @media screen and (max-width: 500px) {
    font-size: 1.5em;
  }
  svg {
    color: #008ffd63;
    font-size: 2em;
    margin-bottom: 25px;
  }
`

const DropzoneStyled = styled(Dropzone)`
  display: flex;
`

export default ShippingCost
