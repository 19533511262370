import React from 'react'
import propTypes, { bool } from 'prop-types'
import Header from './header'
// import Footer from '../footer'
import Theme from '../../theme'

const LayoutUser = (props) => {
  return (
    <Theme>
      <Header white={props.headerColorWhite} />
      {props.children}
    </Theme>
  )
}

LayoutUser.propTypes = {
  children: propTypes.oneOfType([propTypes.string, propTypes.object, propTypes.array]),
  headerColorWhite: bool
}

export default LayoutUser
