/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import View from './view'
import validateForm from '../../../helpers/validateform'
import article from '../../../modules/article'
import propTypes from 'prop-types'
import Admin from '../../hoc/admin'
import { useDispatch } from 'react-redux'
import { setInitialState } from '../../../flux/items'
import { Prompt } from 'react-router-dom'

const Initialdata = {
  doShowAtBuild: false
}

const CreateItem = (props) => {
  const dispatch = useDispatch()
  var preloadedState = props.location.state || Initialdata
  const [steps, setStep] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [data, setData] = useState(preloadedState || {})
  const [errors, setErrors] = useState([])
  const [picture, setPicture] = useState(preloadedState.picture || null)
  const [pictures, setPictures] = useState(preloadedState.pictures || [])
  const [numPicturesUploaded, setPicturesUploaded] = useState(0)
  const [currentView, setCurrenView] = useState('form') // form || loading || success
  const [idCreated, setIdCreated] = useState(null)
  const [colors, setColors] = useState(preloadedState.colors || []) // arrayOf {name: red, picture: file || url}
  const idEditing = preloadedState.id || null

  var numPicturesToUpload = pictures.filter(item => typeof item !== 'string').length + colors.filter(color => typeof color.picture !== 'string').length
  numPicturesToUpload = typeof picture === 'string' ? numPicturesToUpload : numPicturesToUpload + 1

  // setSteps depending from category selected
  useEffect(() => {
    if (data.category === 'steakhouses') setStep(['información general', 'Colores', 'Multimedia', 'Resumen'])
    if (data.category === 'buildyourgrill' || data.category === 'accesories') setStep(['información general', 'Multimedia', 'Resumen'])
    if (errors.length) setErrors([])
  }, [data.category])

  const handleUpload = async () => {
    setCurrenView('loading')
    var counter = 0
    const urlPictures = []
    const urlColors = []
    const id = await article.upload({ ...data, pictures: [], picture: null })
    setIdCreated(id)
    const urlPicture = await article.uploadPicture(id, picture, 'primary')
    await article.update(id, { picture: urlPicture })
    counter++
    setPicturesUploaded(counter)

    // upload pictures
    for (const pic of pictures) {
      const url = await article.uploadPicture(id, pic)
      urlPictures.push(url)
      await article.update(id, { pictures: urlPictures })
      counter++
      setPicturesUploaded(counter)
    }

    // upload color's image
    for (const color of colors) {
      const url = await article.uploadPicture(id, color.picture, 'color')
      urlColors.push({ ...color, picture: url })
      await article.update(id, { colors: urlColors })
      counter++
      setPicturesUploaded(counter)
    }

    setCurrenView('success')
    dispatch(setInitialState())
    setData({})
  }

  const handleUpdate = async () => {
    try {
      setCurrenView('loading')
      var counter = 0

      // pictures
      const newPicturesToUpload = pictures.filter(picture => typeof picture !== 'string')
      const urlPersist = pictures.filter(picture => typeof picture === 'string')
      const picturesToDelete = preloadedState.pictures ? preloadedState.pictures.filter(urlOldPicture => !urlPersist.includes(urlOldPicture)) : []

      // color's picture
      const newColorPicturesToUpload = colors.filter(color => typeof color.picture !== 'string')
      const colorsUrlPersist = colors.filter(color => typeof color.picture === 'string')
      var colorsPicturesToDelete = preloadedState.colors ? preloadedState.colors.filter(oldPicture => !colorsUrlPersist.map(item => item.picture).includes(oldPicture.picture)) : []

      // update data
      await article.update(idEditing, data)
      await article.update(idEditing, { colors: colorsUrlPersist, pictures: urlPersist })

      // upload new principal picture and delete old
      if (typeof picture !== 'string') {
        const fileToDelete = preloadedState.picture
        const urlPicture = await article.uploadPicture(idEditing, picture, 'primary')
        console.log('urlPictureurlPictureurlPicture', urlPicture)
        await article.update(idEditing, { picture: urlPicture })
        await article.deletePicture(idEditing, fileToDelete)
        counter++
        setPicturesUploaded(counter)
      }

      // update new pictures
      for (const picture of newPicturesToUpload) {
        const url = await article.uploadPicture(idEditing, picture)
        urlPersist.push(url)
        await article.update(idEditing, { pictures: urlPersist })
        counter++
        setPicturesUploaded(counter)
      }

      // update new color's pictures
      for (const color of newColorPicturesToUpload) {
        const url = await article.uploadPicture(idEditing, color.picture, 'color')
        colorsUrlPersist.push({ ...color, picture: url })
        await article.update(idEditing, { colors: colorsUrlPersist })
        counter++
        setPicturesUploaded(counter)
      }

      // delete old pictures
      for (const item of picturesToDelete) {
        article.deletePicture(idEditing, item)
      }

      // delete old color's picture
      for (const item of colorsPicturesToDelete) {
        console.log('deleting colors picture', item)
        article.deletePicture(idEditing, item.picture)
      }

      setCurrenView('success')
      dispatch(setInitialState())
      setData({})
    } catch (error) {
      console.error('error_description:', error)
    }
  }

  const handleDropPicture = (ArrayOfpicture) => {
    setPicture(ArrayOfpicture ? ArrayOfpicture[0] : null)
    if (errors.length) setErrors([])
  }

  const handleDropPictures = (ArrayOfpicture = []) => {
    const limit = 10
    const beforeFiles = pictures.filter(pic => typeof pic !== 'string')
    const beforeFilesNme = beforeFiles.map(file => file.name)
    const newFilesFiltered = ArrayOfpicture.filter(file => !beforeFilesNme.includes(file.name))
    const filesMerged = [...pictures, ...newFilesFiltered]
    const filesLimited = filesMerged.filter((item, index) => { return index < limit })
    setPictures(filesLimited)
    if (errors.length) setErrors([])
  }

  const handleDropPictureColors = (arrayOfFiles = []) => {
    const arrayOfColorsWidthName = arrayOfFiles.map(file => ({ picture: file, name: '' }))
    const limit = 4
    const beforeColors = colors.filter(color => typeof color.picture !== 'string')
    const beforeColorsName = beforeColors.map(color => color.picture.name)
    const newsColorsFiltered = arrayOfColorsWidthName.filter(color => !beforeColorsName.includes(color.picture.name))
    const colorsMerged = [...colors, ...newsColorsFiltered]
    const filesLimited = colorsMerged.filter((item, index) => { return index < limit })
    setColors(filesLimited)
    if (errors.length) setErrors([])
  }

  const handleChangeColorName = (value, index) => {
    const currentColors = [...colors]
    currentColors[index].name = value
    setColors(currentColors)
    if (errors.length) setErrors([])
  }

  const handleChange = ({ value, name }) => {
    setData({
      ...data,
      [name]: value
    })
  }

  const onReset = () => {
    setData({})
    setCurrenView('form')
    setCurrentStep(0)
    setErrors([])
    setPicture(null)
    setPictures([])
    setPicturesUploaded(0)
  }

  const handleNext = () => {
    var validate = []
    var errors = false

    if (currentStep === 0 && (data.category === 'steakhouses')) {
      const validateBase = ['title', 'category']
      const validateMiltipleSize = ['medium_price', 'medium_rotisserie', 'medium_grill', 'big_price', 'big_rotisserie', 'big_grill']
      const validateSingeSize = ['price', 'rotisserie', 'grill'];
      validate = data.multiplePrice ? [...validateBase, ...validateMiltipleSize] : [...validateBase, ...validateSingeSize] 
      errors = validateForm.requires(data, validate)
      if (errors) return setErrors(errors)
    }
    
    else if (steps[currentStep] === 'información general' && data.category === 'buildyourgrill') {
      validate = ['title', 'category', 'medium_price', 'big_price', 'section']
      errors = validateForm.requires(data, validate)
      if (errors) return setErrors(errors)
    }
    
    else if (steps[currentStep] === 'información general' && data.category === 'accesories') {
      validate = data.multiplePrice ? ['title', 'category', 'medium_price', 'big_price'] : ['title', 'category', 'price']
      errors = validateForm.requires(data, validate)
      if (errors) return setErrors(errors)
    }
    
    else if (steps[currentStep] === 'Colores') {
      const doEveryHaveName = colors.every(val => !!val.name.toString().length)
      if (!colors.length || !doEveryHaveName) return setErrors(!colors.length ? ['colors'] : ['colorsName'])
    }
    
    else if (steps[currentStep] === 'Multimedia') {
      validate = ['picture']
      errors = validateForm.requires({ picture }, validate)
      if (errors) return setErrors(errors)
    }
    
    setCurrentStep(currentStep + 1)
  }

  const handleBack = () => {
    setCurrentStep(currentStep - 1)
  }

  const removeError = ({ name }) => {
    if (errors.includes(name)) {
      setErrors(errors.filter(error => error !== name))
    }
  }
  const handleDeletePicture = () => {
    setPicture(null)
  }

  const handleDeletePictures = (picturesSelected) => {
    const persistPictures = pictures.filter(picture => {
      if (typeof picture === 'string') {
        return typeof picturesSelected === 'string' ? picture !== picturesSelected : true
      } else {
        return typeof picturesSelected === 'string' ? true : picturesSelected.name !== picture.name
      }
    })
    setPictures(persistPictures)
  }

  const handleDeleteColor = indexToDelete => {
    const colorsNotDeleted = colors.filter((_value, index) => index !== indexToDelete)
    setColors(colorsNotDeleted)
  }

  return (
    <>
      <Prompt message='' when={JSON.stringify(data) !== '{}'} />
      <View
        {...data}
        data={data}
        currentView={currentView}
        setCurrenView={setCurrenView}
        errors={errors}
        setData={setData}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
        handleChange={handleChange}
        handleNext={handleNext}
        removeError={removeError}
        handleDropPicture={handleDropPicture}
        handleDropPictures={handleDropPictures}
        handleDeletePictures={handleDeletePictures}
        handleDeletePicture={handleDeletePicture}
        handleBack={handleBack}
        picture={picture}
        pictures={pictures}
        colors={colors}
        handleUpload={handleUpload}
        numPicturesUploaded={numPicturesUploaded}
        numPicturesToUpload={numPicturesToUpload}
        idCreated={idCreated}
        setIdCreated={setIdCreated}
        onReset={onReset}
        idEditing={idEditing}
        handleUpdate={handleUpdate}
        handleDropPictureColors={handleDropPictureColors}
        handleChangeColorName={handleChangeColorName}
        handleDeleteColor={handleDeleteColor}
        setErrors={setErrors}
      />
    </>
  )
}

CreateItem.propTypes = {
  location: propTypes.object
}

export default Admin(CreateItem)
