import { useState } from "react";
import useFetch from './useFetch'
import pointOfSaleModel from '../modules/pointsOfSale'

const usePointOfSaleList = () => {
  const [items, setItems] = useState([])

  useFetch(async () => {
    const response = await pointOfSaleModel.getAll()
    setItems(response)
  })

  return {
    items,
  }
};

export default usePointOfSaleList;