/* eslint-disable react/jsx-handler-names */
import React from 'react'
import styled from 'styled-components'
import { Box, Container, Divider, Typography } from '@material-ui/core'
import Dropzone from './Dropzone'
import Previews from './previews'
import { Alert } from '@material-ui/lab'
import { array, func, oneOfType, string, object, number } from 'prop-types'
import VideoForm from './videoForm'

const Multimedia = (props) => {
  if (!props.steps || props.steps[props.currentStep] !== 'Multimedia') return null

  return (
    <Container maxWidth='md' disableGutters>
      <Box>
        {props.errors.length !== 0 && (
          <Alert severity='error'>Agrega al menos la imagen principal de tu artículo</Alert>
        )}
        <TextContainer>
          <Title>Foto Principal</Title>
          <SubTitle>Agrega una foto principal a tu producto</SubTitle>
        </TextContainer>
        {!props.picture && (
          <Dropzone
            multiple={false}
            handleDrop={props.handleDropPicture}
          />
        )}
        <Previews
          pictures={props.picture ? [props.picture] : []}
          handleDelete={props.handleDeletePicture}
        />
        {props.category !== 'buildyourgrill' && (
          <>
            <Box marginTop='2em' marginBottom='2em'>
              <DividerStyled />
            </Box>
            <TextContainer>
              <Title>Fotos</Title>
            </TextContainer>
            {props.pictures.length < 10 && (
              <Dropzone
                handleDrop={props.handleDropPictures}
                {...props}
              />
            )}
            <Box marginTop='2em'>
              <Previews
                pictures={props.pictures || []}
                handleDelete={props.handleDeletePictures}
              />
            </Box>
            <Box marginTop='2em' marginBottom='2em'>
              <DividerStyled />
            </Box>
            <TextContainer>
              <Title>Videos</Title>
            </TextContainer>
            <VideoForm
              onChange={(videos) => props.setData((prevData) => ({ ...prevData, videos }))}
              value={Array.isArray(props.data.videos) ? props.data.videos : []}
            />
          </>
        )}
      </Box>
    </Container>
  )
}

Multimedia.propTypes = {
  errors: array,
  handleDelete: func,
  handleDrop: func,
  handleDeletePictures: func,
  handleDeletePicture: func,
  picture: oneOfType([string, object]),
  pictures: array,
  handleDropPictures: func,
  handleDropPicture: func,
  currentStep: number,
  steps: array,
  category: string,
  setData: func.isRequired,
  data: object
}

const TextContainer = styled('div')`
  margin-top: 30px;
  margin-bottom: 10px;
`

const Title = styled('h6')`
  font-size: 1.5em;
  padding: 0px;
  margin: 0px;
  color: var(--main-blue-dark);
`

const SubTitle = styled(Typography)`
  color: var(--main-blue-dark);
`

const DividerStyled = styled(Divider)`
  background: var(--main-blue);
`

export default Multimedia
