/* eslint-disable camelcase */
import { db, firebase } from './firebase'
import snapshotParser from '../helpers/snapshotparser'
import filterobject from '../helpers/filterobject'

const getRandom = (bottom, top) => Math.floor(Math.random() * (1 + top - bottom)) + bottom

export const getList = async (period) => {
  const snap = await db.collection(`Ordenes/Pedidos/${period}`).orderBy('date', 'desc').get()
  return snapshotParser(snap)
}

export const setViewed = async ({ id, period }) => {
  try {
    await db.doc(`/Ordenes/Pedidos/${period}/${id}`).update({ isViewed: true })
    await db.doc('Ordenes/Pedidos').update({
      counter: firebase.firestore.FieldValue.increment(-1)
    })
    return true
  } catch (error) {
    console.error('error_description:', error)
    return false
  }
}

export const changeStatus = async ({ status, id, period }) => {
  try {
    await db.doc(`/Ordenes/Pedidos/${period}/${id}`).update({ status })
    return true
  } catch (error) {
    console.error('error_description:', error)
    return false
  }
}

export const onCounterChange = handler => {
  const unsubscribe = db.doc('Ordenes/Pedidos').onSnapshot(snap => {
    const data = snapshotParser(snap)
    handler(data ? data.counter : 0)
  })
  return unsubscribe
}

export const create = async ({ tax = '', email, name, lastname, street_number, suburb, city, state, postal_code, number, subTotal, shipping, totalPrice, methodPay, itemsOncart, discountId = null, discountType = null, discountApplied = null, discountValue = null, shippingOnDelivery = false, monthsWithoutInterest = null }) => {
  const random = getRandom(1, 99)
  const order_id = Date.now()
  const trimmed = parseInt(`${random}${(order_id).toString().slice(2)}`)

  try {
    const items = itemsOncart.map(item => filterobject(item, ['multiplePrice', 'sizeInfo', 'category', 'tiresType', 'sideTables', 'id', 'price', 'quantity', 'sku', 'title', 'picture', 'color', 'size', 'firm', 'doorDesign', 'grillType', 'interiorType']))
    const date = new Date()
    const period = `${date.getMonth() + 1}-${date.getFullYear()}`
    const result = await db.collection(`Ordenes/Pedidos/${period}`).add({
      monthsWithoutInterest,
      isViewed: false,
      items,
      subTotal,
      shipping,
      totalPrice,
      date,
      methodPay,
      tax,
      shippingOnDelivery,
      discount: {
        discountId,
        discountType,
        discountApplied,
        discountValue,
      },
      user: { email, name, lastname, number },
      shipTo: { street_number, suburb, city, state, postal_code },
      orderId: trimmed
    })
    await db.doc(`Ordenes/Pedidos/${period}/${result.id}`).update({ id: result.id })
    return { id: result.id, path: result.path, order_id: trimmed }
  } catch (error) {
    console.error('error_description:', error)
    return false
  }
}

export const success = async ({ id, meta = {} }) => {
  try {
    const date = new Date()
    const period = `${date.getMonth() + 1}-${date.getFullYear()}`
    await db.doc(`Ordenes/Pedidos/${period}/${id}`).update({ status: 'pending', meta, isViewed: false })
    console.log('success executed')
    try {
      await db.doc('Ordenes/Pedidos').update({ counter: firebase.firestore.FieldValue.increment(1) })
    } catch (error) {
      await db.doc('Ordenes/Pedidos').set({ counter: firebase.firestore.FieldValue.increment(1) })
    }
    return true
  } catch (error) {
    console.error('error_description:', error)
    return false
  }
}

export const failed = async id => {
  try {
    const date = new Date()
    const period = `${date.getMonth() + 1}-${date.getFullYear()}`
    await db.doc(`Ordenes/Pedidos/${period}/${id}`).delete()
  } catch (error) {
    console.error('error_description:', error)
    return false
  }
}

export default {
  getList,
  changeStatus,
  onCounterChange,
  setViewed,
  create,
  success,
  failed
}
