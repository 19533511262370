import ButtonBase from '../../../components/link'
import Picture from '../../../components/picture'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'

export const InputStyled = styled(TextField)`
  border-radius: 0px;
  .Mui-focused, .Mui-disabled {
    border-radius: 0px;
  }
`

export const MyCustomCheckBox = styled.div`
  min-width: 50px;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  svg {
    font-size: 33px;
  }
`

export const Text = styled.div`
  font-size: 28px;
  font-family: 'bebas';
  font-weight: bold;
  line-height: 1em;
  @media screen and (max-width:600px) {
    font-size: 18px;
  }
`
export const TextGray = styled(Text)`
  color: gray;
`
export const ItemContainer = styled.div`
  cursor: pointer;
`
export const PictureStyled = styled(Picture)`
  ${props => props.$selected ? 'border: 3px solid #000' : ''};
  box-sizing: border-box;
  object-fit: contain;
  background: #d6d6d6;
`
export const Container = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width:1600px) {
    font-size: .9em;
    width: 90%;
  }
  @media screen and (max-width:1350px) {
    font-size: .8em;
  }
  @media screen and (max-width:1000px) {
    flex-direction: column;
  }
`
export const MenuContainer = styled.div`
  width: 24%;
  @media screen and (max-width:1500px) {
    width: 24%;
  }
  @media screen and (max-width:1000px) {
    width: 100%;
  }
`
export const Body = styled.div`
  width: 70%;
  @media screen and (max-width:1500px) {
    width: 70%;
  }
  @media screen and (max-width:1000px) {
    width: 100%;
  }
`
export const Header = styled.div`
  width: 70%;
  font-family: 'bebas';
  font-size: 7em;
  @media screen and (max-width:1500px) {
    width: 75%;
  }
  @media screen and (max-width:1000px) {
    width: 100%;
    text-align: center;
    font-size: 58px;
  }
  @media screen and (max-width:600px) {
    width: 100%;
    text-align: center;
    font-size: 50px;
  }
`

export const ItemContainerColor = styled(ItemContainer)`
  width: 110px;
  margin-right: 20px;
  margin-bottom: 20px;
  :last-of-type {
    margin-right: 0px;
  }
  @media screen and (max-width:400px) {
    width: 40%;
  }
`
export const TitleColor = styled(TextGray)`
  font-size: 25px;
`

export const Button = styled(ButtonBase)`
  width: 220px;
  font-size: 35px;
  font-family: 'bebas';
  padding: 8px 0px;
  color: ${props => props.variant === 'contained' ? '#fff' : '#000'};
  background: ${props => props.variant === 'contained' ? '#000' : 'var(--user-black-light)'};
  @media screen and (max-width:1500px) {
    font-size: 25px;
  }

  @media screen and (max-width:700px) {
    width: 45%;
    max-width: 300px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
  }
`