import { useEffect, useState } from 'react'

export default (deps = []) => {
  const [val, setVal] = useState(0)

  useEffect(() => {
    try {
      const got = document.getElementById('header').offsetHeight
      setVal(got)
    } catch (error) {
      console.error('error', error)
    }
  }, deps)

  return `${val}px`
}
