import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Fade } from '@material-ui/core'
import { Alert as AlertBase } from '@material-ui/lab'

const Notification = props => {
  const items = useSelector(state => state.notification)

  return (
    <Container>
      {items.map((item, index) => (
        <Fade in={item.open} key={index} timeout={700}>
          <Alert severity={item.type || 'success'} icon={false}>
            {item.message}
          </Alert>
        </Fade>
      ))}
    </Container>
  )
}

const Container = styled.section`
  position: fixed;
  z-index: 11;
  bottom: 40px;
  left: 40px;
  width: 100%;
  max-width: 300px;
  transition: all 1s;
`

const Alert = styled(AlertBase)`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  min-height: 70px;
  align-items: center;
  font-size: 1.2em;
  box-shadow: 2px 2px 5px #000;
  ${props => props.severity === 'success' && {
    backgroundColor: '#000!important',
    color: '#fff!important',
  }}
`
export default Notification
