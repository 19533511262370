import { Box } from '@material-ui/core'
import React from 'react'
import { Container, Title, DatatextVideo } from './styles'
import styled from 'styled-components'
import cover from '../../../assets/home-social-cover.jpg'
import iconF from '../../../assets/icon-f.png'
import iconI from '../../../assets/icon-i.png'
import iconY from '../../../assets/icon-y.png'
import iconP from '../../../assets/icon-p.png'
import { social } from '../../../constants'

const Social = () => (
  <FullWrapper>
    <Container>
      <Box color='#fff' display='flex' flexDirection='column' alignItems='center'>
        <Box>
          <TitleWrapper>SÍGUENOS EN REDES</TitleWrapper>
          <DatatextVideoStyled>
            Síguenos en nuestras redes sociales para que estés al pendiente <BrStyled />
            de nuestras novedades, promociones y descuentos. También<BrStyled />
            envíanos tus fotos usando nuestros productos ADN siendo todo<BrStyled />
            un master en la parrilla. ¡Nos vemos en las redes!<BrStyled />
          </DatatextVideoStyled>
          <IconsWrapper>
            <a href={social.facebook} target='_blank' rel='noreferrer'>
              <Icon src={iconF} />
            </a>
            <IconDivider />
            <a href={social.instagram} target='_blank' rel='noreferrer'>
              <Icon src={iconI} />
            </a>
            <IconDivider />
            <a href={social.youtube} target='_blank' rel='noreferrer'>
              <Icon src={iconY} />
            </a>
            <IconDivider />
            <a href={social.pinterest} target='_blank' rel='noreferrer'>
              <Icon src={iconP} />
            </a>
          </IconsWrapper>
        </Box>
      </Box>
    </Container>
  </FullWrapper>
)

const TitleWrapper = styled(Title)`
  @media screen and (max-width:500px) {
    font-size: 3em;
  }
`

const BrStyled = styled.br`
  @media screen and (max-width:650px) {
    display: none;
  }
`

const DatatextVideoStyled = styled(DatatextVideo)`
  text-align: center;
  @media screen and (max-width:650px) {
    width: 100%;
    margin: auto;
  }
`

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  margin: auto;
  margin-top: 2em;
  @media screen and (max-width:650px) {
    width: 100%;
    margin: auto;
    margin-top: 2em;
  }
`

const FullWrapper = styled.div`
  background-image: url(${cover});
  background-position: center;
  background-size: cover;
  padding: 5em 0em;
  margin: 6em 0em;
`

const Icon = styled.img`
  width: 4em;
  @media screen and (max-width:650px) {
    width: 3em;
  }
`
const IconDivider = styled.div`
  border-bottom: 1px solid #cdcdcd;
  flex-grow: 1;
  margin: auto 1em;
`

export default Social
