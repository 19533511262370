import { db } from './firebase'
import response from '../helpers/response'

const collectionRef = db.collection('config')

export const activateMountsWithoutInterest = async () => {
  try {
    await collectionRef.doc('monthsWithoutInterest').set({ activated: true })
    return response.success('Meses sin intereses activado')
  } catch (error) {
    return response.error(error.toString())
  }
}

export const deactivateMountsWithoutInterest = async () => {
  try {
    await collectionRef.doc('monthsWithoutInterest').set({ activated: false })
    return response.success('Meses sin intereses desactivado')
  } catch (error) {
    return response.error(error.toString())
  }
}

export const getIsMonthsWithoutInterestActivated = async () => {
  const snapshot = await collectionRef.doc('monthsWithoutInterest').get()
  if (!snapshot.exists) return false;
  return snapshot.data().activated;
};

const monthsWithoutInterest = {
  activateMountsWithoutInterest,
  deactivateMountsWithoutInterest,
  getIsMonthsWithoutInterestActivated,
}

export default monthsWithoutInterest
