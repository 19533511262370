import React from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { Container, ListContainer, Video, DatatextVideo, Title, DataVideo } from './styles'
import VideoPlayerfrom from './video'

const VideoSection = () => {
  const isMobile = useMediaQuery('(max-width:959px)')

  return (
    <Container>
      <Box pt={{ xs: 3, md: 4, lg: 6 }} />
      <ListContainer id='adn'>
        <Video>
          <VideoPlayerfrom />
        </Video>
        <DataVideo>
          <Title style={{ textAlign: isMobile ? 'center' : 'left' }}>ASADORES DEL NORTE</Title>
          <DatatextVideo>
            ADN es una empresa dedicada a la fabricación
            de asadores de la más alta calidad que te ofrecen
            productos que llevan tus parrilladas al siguiente nivel.
          </DatatextVideo>
          <DatatextVideo>
            Contamos con más de 10 años de experiencia y nuestro mercado se ha extendido en la República mexicana y al sur de Estados Unidos.
          </DatatextVideo>
        </DataVideo>
      </ListContainer>
    </Container>
  )
}

export default VideoSection
