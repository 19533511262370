import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import { array } from 'prop-types'
import { Link } from 'react-router-dom'

const FullWidth = styled.div`
  background: #000;
`
const StepContent = styled(Link)`
  font-family: 'gotham';
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
  @media screen and (max-width:1000px) {
    font-size: .8em!important;
  }
`

const Step = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  & svg {
    color: #fff;
    font-size: 1rem;
    margin: 0px 15px;
    @media screen and (max-width:1000px) {
      margin: 0px 10px;
    }
  }
`
const Container = styled.div`
  width: 80%;
  height: 50px;
  margin: auto;
  display: flex;
  align-items: center;
`

const Searcher = props => {
  return (
    <FullWidth>
      <Container>
        <Grid container spacing={2} justify='space-between' alignItems='center'>
          <Grid item xs={12} container>
            {props.steps.map((step, index) => (
              <Step key={index}>
                {!!index && (
                  <ArrowForwardIos />
                )}
                <StepContent to={step.to} as={step.to ? Link : 'span'}>
                  {step.label}
                </StepContent>
              </Step>
            ))}
          </Grid>
        </Grid>
      </Container>
    </FullWidth>
  )
}

Searcher.propTypes = {
  steps: array
}

export default Searcher
