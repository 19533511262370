import React, { useEffect, useState } from 'react'
import LayoutUser from '../../components/layout_user'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSpecificItem } from '../../../flux/items'
import { addItem } from '../../../flux/cart'
import { setNotification } from '../../../flux/notification'
import Container from '../../components/container'
import Data from './data'
import Skeleton from './skeleton'
import useHeaderheight from '../../../hooks/useHeaderheight'
import Related from './related'
import BackBread from '../../components/backdream'
import { categories } from '../../../constants'
import FooterInfo from '../../components/footerInfo'
import { Divider } from '@material-ui/core'
import styled from 'styled-components'
import Footer from '../../components/footer'

const Item = props => {
  const { id } = useParams()
  const dispatch = useDispatch()
  var { items, loading } = useSelector(state => state.items)
  const [quantity, setQuantity] = useState(1)
  const headerheight = useHeaderheight()
  const [size, setSize] = useState(null)
  const [firm, setFirm] = useState('')
  const [price, setPrice] = useState(0)
  const [color, setColor] = useState(null)

  let itemSelected = items[id] || null
  if (!itemSelected) {
    const arrayOfItems = Object.values(items)
    itemSelected = arrayOfItems.find((element) => element.url === id)
  }

  const handleSizeChange = value => {
    setSize(value)
    value === 'medium' ? setPrice(itemSelected.medium_price) : setPrice(itemSelected.big_price)
  }

  // fetch data
  useEffect(() => {
    if (!itemSelected) dispatch(fetchSpecificItem(id)) // url or id
  }, [])

  const AddToCart = event => {
    dispatch(setNotification({
      type: 'success',
      message: `Se han agregado ${quantity} ${itemSelected.title} a tu carrito`
    }))
    dispatch(addItem({ ...itemSelected, quantity, size, firm, price, color }))
  }

  const onQuantityChange = event => {
    var newValue = event.target.value
    if (newValue === '') {
      setQuantity('')
      return false
    }
    newValue = parseInt(newValue)
    if (isNaN(newValue)) newValue = 1
    setQuantity(newValue)
  }

  // move to up
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [id])

  // calculate initial state
  useEffect(() => {
    if (!loading) {
      if (itemSelected.colors && itemSelected.colors.length) {
        setColor(itemSelected.colors[0])
      }
      if (!!itemSelected.medium_price && !!itemSelected.big_price && !itemSelected.price) {
        handleSizeChange('medium')
        setPrice(itemSelected.medium_price)
      } else {
        setPrice(itemSelected.price)
      }
    }
  }, [loading, id])

  const handleSelecteColor = value => {
    setColor(value)
  }

  return (
    <LayoutUser>
      <div style={{ marginTop: headerheight }}>
        {loading && (<BackBread steps={[{ label: 'Home' }]} />)}
        {!loading && (
          <BackBread
            steps={[
              { label: 'Home', to: '/' },
              { label: categories[itemSelected.category], to: { pathname: '/productos', state: { category: itemSelected.category } } },
              { label: itemSelected.title }
            ]}
          />)}
        {loading && (
          <Container>
            <Skeleton />
          </Container>
        )}
        {!loading && !!itemSelected && (

          <Data
            onSelectColor={handleSelecteColor}
            color={color}
            firm={firm}
            setFirm={setFirm}
            size={size}
            setSize={handleSizeChange}
            AddToCart={AddToCart}
            {...itemSelected}
            onQuantityChange={onQuantityChange}
            quantity={quantity}
            setQuantity={setQuantity}
          />
        )}
        <Related />
        <Container>
          <DividerStyled />
          <Limiter>
            <FooterInfo />
          </Limiter>
        </Container>
      </div>
      <Footer />
    </LayoutUser>
  )
}

const DividerStyled = styled(Divider)`
  background-color: #000;
  margin-top: 50px;
  margin-bottom: 40px;
`
const Limiter = styled.div`
  width: 85%;
  margin: auto;
  @media screen and (max-width:1000px) {
    width: 100%;
  }
`
export default Item
