import { Box, Button, Collapse } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import { Alert } from '@material-ui/lab'
import { array, func } from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Input from '../../components/input'

const VideoForm = (props) => {
  const [error, setError] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [videos, setVideos] = useState(props.value || [])

  const handleAdd = () => {
    if (!inputValue) {
      setError('El campo no puede estar vacío')
      setTimeout(() => setError(null), 7000)
      return null
    }
    if (!inputValue.toString().includes('http') || !inputValue.toString().includes('you')) {
      setError('La url del video no es válida')
      setTimeout(() => setError(null), 7000)
      return null
    }
    if (videos.includes(inputValue)) {
      setError('El video ya se encuentra en la lista')
      setTimeout(() => setError(null), 7000)
      return null
    }
    setVideos([...videos, inputValue])
    setInputValue('')
  }

  const handleRemoveError = () => {
    setError(null)
  }

  const handleDelete = (videoUrl) => {
    setVideos((prevVideos) => {
      return prevVideos.filter((currentVide) => {
        return currentVide !== videoUrl
      })
    })
  }

  useEffect(() => {
    if (props.onChange) props.onChange(videos)
  }, [videos])

  return (
    <Wrapper>
      <Box width='100%'>
        <ul>
          {videos.map((currentVideo) => (
            <Li key={currentVideo} onClick={() => handleDelete(currentVideo)}>
              {currentVideo}
              <Delete />
            </Li>
          ))}
        </ul>
      </Box>
      <FormWrapper>
        <Box textAlign='center'>
          <Collapse in={!!error}>
            <Box marginBottom='1em'>
              <Alert severity='error'>{error}</Alert>
            </Box>
          </Collapse>
          <Box textAlign='left' color='var(--main-blue-dark)' marginBottom='.2em' fontWeight='bold'>URL del video</Box>
          <Input
            name='video'
            errors={error ? ['video'] : []}
            type='text'
            size='small'
            fullWidth
            value={inputValue}
            onChange={(target) => setInputValue(target.value)}
            removeError={handleRemoveError}
          />
        </Box>
        <Box textAlign='center' marginTop='1em'>
          <Button onClick={handleAdd} color='primary' variant='contained'>
            Agregar
          </Button>
        </Box>
      </FormWrapper>
    </Wrapper>
  )
}

VideoForm.propTypes = {
  onChange: func.isRequired,
  value: array.isRequired
}

const Wrapper = styled.div`
  min-height: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 30em;
  margin: auto;
  flex-wrap: wrap;
`
const FormWrapper = styled.div`
  width: 100%;
`
const Li = styled.li`
  color: var(--main-blue);
  font-weight: bold;
  line-height: 2em;
  display: flex;
  svg {
    margin-left: 1em;
    font-size: 1.5em;
    cursor: pointer;
    color: var(--main-blue-dark);
  }
`

export default VideoForm
