/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Grid, useMediaQuery } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import propTypes from 'prop-types'
import IconButton from '../../../components/link'
import Picture from '../../../components/picture'
import ReactYoutube from 'react-youtube'

const YoutubePlayer = (props) => {
  const videoId = props.video.replace('https://youtu.be/', '')
  const [viewport, setViewport] = useState({ width: 0, height: 0 })

  const nodeRef = useRef()

  const opts = {
    height: viewport.height,
    width: viewport.width,
    playerVars: {
      autoplay: 0
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const width = nodeRef.current.offsetWidth
      const height = nodeRef.current.offsetHeight
      setViewport({ width, height })
    }, 500)
  }, [])

  return (
    <div ref={nodeRef} style={{ height: '100%' }}>
      {viewport.width !== 0 && (
        <ReactYoutube
          videoId={videoId}
          opts={opts}
          onPlay={props.onPlay}
          onEnd={props.onEnd}
          onPause={props.onPause}
          onReady={event => {}}
        />
      )}
    </div>
  )
}

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const PictureViewer = props => {
  const isMobile = useMediaQuery('(max-width:700px)')
  const [currentIndexShow, setCurrentIndexShow] = useState(0)
  const mediaComposed = [...props.pictures, ...props.videos]
  const [playing, setPlaying] = useState(false)
  const maxSteps = mediaComposed.length

  const handleNext = () => {
    setCurrentIndexShow((prevIndex) => maxSteps > (prevIndex + 1) ? prevIndex + 1 : 0)
  }

  const handleBack = () => {
    setCurrentIndexShow((prevIndex) => prevIndex > 0 ? prevIndex - 1 : maxSteps - 1)
  }

  const handleIndexAutoChange = (indexShow) => {
    if (!playing) setCurrentIndexShow(indexShow)
  }

  const handleThumbSelect = (evenet) => {
    setCurrentIndexShow(parseInt(evenet.target.id))
  }

  // pause video when change current index show
  useEffect(() => {
    if (playing && playing.target && playing.target.pauseVideo) {
      playing.target.pauseVideo()
    }
  }, [currentIndexShow])

  return (
    <>
      <PicturesContainer>
        <ButtonWrapperBack handleClick={handleBack}><KeyboardArrowLeft /></ButtonWrapperBack>
        <ButtonWrapperNext handleClick={handleNext}><KeyboardArrowRight /></ButtonWrapperNext>

        <WidthLimiter>
          <AutoPlaySwipeableViews
            index={currentIndexShow}
            onChangeIndex={handleIndexAutoChange}
            enableMouseEvents
            autoPlay={false}
          >
            {mediaComposed.map(mediaUrl => (
              <>
                {!props.videos.includes(mediaUrl) && (
                  <Picture key={mediaUrl} src={mediaUrl} alt={mediaUrl} height={80} />
                )}
                {props.videos.includes(mediaUrl) && (
                  <Picture key={mediaUrl} height={80} as='div'>
                    <YoutubePlayer
                      video={mediaUrl}
                      onPlay={(ref) => setPlaying(ref)}
                      onEnd={() => setPlaying(false)}
                      onPause={() => setPlaying(false)}
                    />
                  </Picture>
                )}
              </>
            ))}
          </AutoPlaySwipeableViews>
        </WidthLimiter>

      </PicturesContainer>
      <WidthLimiter>
        <Grid container spacing={isMobile ? 1 : 2} justify='flex-start' style={{ marginTop: '10px' }}>
          {mediaComposed.map((mediaUrl, index) => (
            <Grid item xs key={mediaUrl} style={{ maxWidth: '100px' }}>
              {!props.videos.includes(mediaUrl) && (
                <Thumb
                  onClick={handleThumbSelect}
                  id={index}
                  src={mediaUrl}
                  selected={index === currentIndexShow}
                />
              )}
              {props.videos.includes(mediaUrl) && (
                <Thumb
                  selected={index === currentIndexShow}
                  as='div'
                  style={{ height: '95%', position: 'relative' }}
                >
                  <YoutubePlayer video={mediaUrl} />
                  <YoutubeShadow onClick={handleThumbSelect} id={index} />
                </Thumb>
              )}
            </Grid>
          ))}
        </Grid>
      </WidthLimiter>
    </>
  )
}

PictureViewer.propTypes = {
  pictures: propTypes.array,
  videos: propTypes.array
}

const Thumb = styled(Picture)`
  border-radius: 5px;
  border: ${props => props.selected === true ? '2px solid var(--main-blue)' : 'none'};
`

const WidthLimiter = styled.div`
  width: 70%;
  margin: auto;
  @media screen and (max-width:1000px) {
    width: 100%
  }
`

const PicturesContainer = styled('div')`
  position: relative;
  text-align: right;
  @media screen and (max-width:1000px) {
    padding-top: 30px
  }
`

const ButtonWrapperBase = styled(IconButton)`
  height: 35px;
  font-family: 'bebas';
  font-size: ${props => props.$fontSize || '1em'};
  color: #000;
  border: 1px solid #000;
  padding: 5px 10px;
  svg {
    font-size: 1.1em;
    line-height:1em;
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 0px;
    top: 0px;
    margin: auto;
  }
  :hover {
    background: #000;
    color: #fff;
  }
  position: absolute;
  top: calc(50% - 12px);
  z-index: 2;
  font-size: 2em;
  @media screen and (max-width:1000px) {
    position: relative;
    width: 51px;
    height: 26px;
    margin-bottom: 10px;
    margin-right: 10px;
    :last-of-type {
      margin-right: 0px;
    }
  }
`
const ButtonWrapperNext = styled(ButtonWrapperBase)`
  right: 0px;
  width: 10%;
`
const ButtonWrapperBack = styled(ButtonWrapperBase)`
  left: 0px;
  width: 10%;
`
const YoutubeShadow = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`
export default PictureViewer
