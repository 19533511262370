import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import styled, { ThemeProvider as ThemeProviderStyled } from 'styled-components'
import { ThemeProvider } from '@material-ui/core'
import Alert from './components/alert'
import Notification from './components/notification'
import mytheme from './themeMaterial'
import DefineListeners from './components/define_listeners'
import theme from './theme'
import './index.css'
import Fonts from './fonts'

// pages
import CreateItem from './admin/pages/create_item'
import Login from './admin/pages/login'
import MyArticles from './admin/pages/my_articles'
import Messages from './admin/pages/messages'
import Orders from './admin/pages/orders'
import Order from './admin/pages/order'
import Sizes from './admin/pages/sizes'
import PointOfSale from './admin/pages/pointOfSale'

import Home from './user/pages/home'
import Item from './user/pages/item'
import MyCart from './user/pages/my_cart'
import Checkout from './user/pages/checkout'
import About from './user/pages/about'
import Contact from './user/pages/contact'
import admin from './admin/hoc/admin'
import Search from './user/pages/search'
import Loading from './components/loading'
import Success from './user/pages/success'
import Products from './user/pages/products'
import editSizes from './admin/pages/editSizes'
import BuildYourGrill from './user/pages/buildyourgrill'
import colors from './admin/pages/colors'
import editColor from './admin/pages/editColor'
import Shipping from './admin/pages/shippingCost'
import Verify from './admin/pages/verify'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import Privacity from './user/pages/privacity'
import Terms from './user/pages/terms'
import Dealers from './admin/pages/dealers'

import './modules/discounts'
import DiscountCodes from './admin/pages/discountCodes'
import PickersProvider from './MuiPickersUtilsProvider'

console.log('welcome to', process.env.REACT_APP_RUNTIME)

ReactGA.initialize('UA-185095524-1')

const advancedMatching = {}
const options = {
  autoConfig: true,
  debug: false
}

ReactPixel.init('420425742864487', advancedMatching, options)

function App () {
  const location = useLocation()

  const pageController = (url) => {
    try {
      console.log(`-- ${url} registered --`)
      ReactGA.ga('set', 'page', url)
      ReactGA.ga('send', 'pageview', url)
      ReactPixel.pageView()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(_any => {
    pageController(location.pathname)
  }, [location.pathname])

  return (
    <ThemeProvider theme={mytheme}>
      <ThemeProviderStyled theme={theme}>
        <DefineListeners>
          <PickersProvider>
            <Background>
              <Loading />
              <Notification />
              <Alert />
              <Fonts />
              <Switch>
                <Route exact path='/productos/:category?' component={Products} />
                <Route exact path='/' component={Home} />
                <Route exact path='/create-item' component={CreateItem} />
                <Route exact path='/my-articles' component={MyArticles} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/producto/:id' component={Item} />
                <Route exact path='/my-cart' component={MyCart} />
                <Route exact path='/checkout' component={Checkout} />
                <Route exact path='/about' component={About} />
                <Route exact path='/contact' component={Contact} />
                <Route exact path='/messages' component={Messages} />
                <Route exact path='/orders' component={Orders} />
                <Route exact path='/order/:period/:id' component={Order} />
                <Route exact path='/admin' component={admin(props => (<Redirect to='/my-articles' />))} />
                <Route exact path='/search' component={Search} />
                <Route exact path='/success' component={Success} />
                <Route exact path='/sizes' component={Sizes} />
                <Route exact path='/edit-sizes' component={editSizes} />
                <Route exact path='/build' component={() => <Redirect to='/arma-tu-asador' />} />
                <Route exact path='/arma-tu-asador' component={BuildYourGrill} />
                <Route exact path='/colors' component={colors} />
                <Route exact path='/edit-color' component={editColor} />
                <Route exact path='/shipping' component={Shipping} />
                <Route exact path='/verify/:path' component={Verify} />
                <Route exact path='/terms' component={Terms} />
                <Route exact path='/privacity' component={Privacity} />
                <Route exact path='/point-of-sale' component={PointOfSale} />
                <Route exact path='/dealers' component={Dealers} />
                <Route exact path='/discount-codes' component={DiscountCodes} />
              </Switch>
            </Background>
          </PickersProvider>
        </DefineListeners>
      </ThemeProviderStyled>
    </ThemeProvider>
  )
}

export default App

const Background = styled('div')`
  background: #fff;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
`
