import styled from 'styled-components'
import ContainerBase from '../../components/container'

export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Summary = styled.div`
  background: #cdcdcd42;
  color: #000;
  padding: 10px;
  font-size: 35px;
  width: 100%;
  font-weight: bold;
  font-family: 'bebas';
  text-align: right;
  & span {
    /* font-weight: normal;
    color: var(--user-gray); */
    margin-right: 10px;
  }
  @media screen and (max-width:1500px) {
    font-size: 25px;
  }
  @media screen and (max-width:1000px) {
    font-size: 35px!important;
  }
`
export const Total = styled.div`
  font-family: 'bebas';
  font-size: 35px;
  margin-top: 20px;
  background: var(--user-black-light);
  padding: 10px;
  font-weight: bold;
  text-align: right;
  width: 100%;
  color: #fff;
  & span {
    margin-right: 10px;
    color: #9a9a9a;
  }
  @media screen and (max-width:1500px) {
    font-size: 25px;
  }
  @media screen and (max-width:1000px) {
    font-size: 35px!important;
  }
`
export const Container = styled(ContainerBase)`
  width: 80%;
  @media screen and (max-width:500px) {
    width: 100%;
  }
`
export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width:1000px) {
    flex-wrap: wrap;
    justify-content: flex-end!important;
  }
  @media screen and (max-width:600px) {
    flex-wrap: wrap;
    margin-top: 50px;
  }
`
export const ButtonStyled = styled.div`
  border-radius: 0px!important;
  width:100%;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 35px!important;
  font-family: 'bebas';
  @media screen and (max-width:1500px) {
    font-size: 25px!important;
  }
  @media screen and (max-width:1000px) {
    font-size: 27px!important;
    margin:0px!important;
    padding-right: 18px!important;
    padding-left: 18px!important;
    width: auto;
    margin-bottom: 10px!important;
  }
`
