import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Item from '../../components/item'
import { useSelector } from 'react-redux'
import { toArray } from '../../../helpers/transformer'
import { dispatch } from '../../../store'
import { fetchAll } from '../../../flux/items'
import { useParams } from 'react-router-dom'
import suffle from '../../../helpers/array_suffle'
import { IconButton } from '@material-ui/core'
import { ArrowForward, ArrowBack } from '@material-ui/icons'

const ContentFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const IconButtonStyled = styled(IconButton)`
  position: relative;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 50px;
  border-radius: 0px;
  @media screen and (max-width:1500px) {
    height: 28px;
    width: 40px;
  }
  svg {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    color: #000;
    display: block;
    margin: auto;
  }
  :hover {
    background: #000;
    svg {
      color: #fff;
    } 
  }
`

const ItemContent = styled.div`
  margin: 0px 15px;
  flex-grow: 1;
  display: flex;
  overflow-x: scroll;
  @media screen and (max-width:1500px) {
    margin: 0px 0px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  scroll-behavior: smooth;
`

const ItemWrapper = styled.div`
  padding: 20px;
  width: 33%;
  @media screen and (max-width:800px) {
    font-size: .8em;
  }
`

const Carrousel = styled.div`
  width: 200%;
  min-width: 200%;
  display: flex;
  @media screen and (max-width:1300px) {
    width: 300%;
    min-width: 300%;
  }
  @media screen and (max-width:800px) {
    width: 600%;
    min-width: 600%;
  }
`

const Related = props => {
  var { items, isfinally } = useSelector(state => state.items)
  const { id } = useParams()
  items = suffle(toArray(items).filter(item => item.id !== id))
  items = items.filter(item => item.isActive && item.category !== 'buildyourgrill' && !item.doShowAtBuild)
  items = items.slice(0, 6)
  const [itemwidth, setItemWidth] = useState(0)

  // fetch data
  useEffect(() => {
    if (items.length < 5 && !isfinally) {
      console.log('fetching data of inside related...')
      dispatch(fetchAll())
    }
  }, [])

  // get item width}
  useEffect(() => {
    try {
      const width = document.getElementById('first-item').offsetWidth
      if (width !== itemwidth) {
        setItemWidth(width)
      }
    } catch (error) {
      if (itemwidth !== 200) {
        setItemWidth(200)
      }
    }
  })

  const scrollToRigth = event => {
    const element = document.getElementById('content-scroll')
    element.scrollTo(element.scrollLeft + itemwidth, 0)
  }

  const scrollToLefth = event => {
    const element = document.getElementById('content-scroll')
    element.scrollTo(element.scrollLeft - itemwidth, 0)
  }

  return (
    <Content>
      <Title><span>Productos que te pueden gustar</span> <Line /></Title>
      <ContentFlex>
        <IconButtonStyled onClick={scrollToLefth}>
          <ArrowBack />
        </IconButtonStyled>
        <ItemContent id='content-scroll'>
          <Carrousel>
            {items && items.map((item, index) => {
              const propsitem = {}
              if (index === 0) propsitem.id = 'first-item'
              return (
                <ItemWrapper key={index} {...propsitem}>
                  <Item {...item} />
                </ItemWrapper>
              )
            })}
          </Carrousel>
        </ItemContent>
        <IconButtonStyled onClick={scrollToRigth}>
          <ArrowForward />
        </IconButtonStyled>
      </ContentFlex>
    </Content>
  )
}

const Title = styled.h1`
  display: flex;
  align-items: center;
  color: var(--user-black);
  font-size: 70px;
  font-family: 'bebas';
  @media screen and (max-width:1600px) {
    font-size: 60px;
  }
  @media screen and (max-width:1320px) {
    font-size: 50px!important;
  }
  @media screen and (max-width:1000px) {
    font-size: 40px!important;
  }
  @media screen and (max-width:600px) {
    font-size: 40px!important;
  }
  span {
    width: 35%;
    line-height: .9em;
    @media screen and (max-width:960px) {
      width: 100%;
      text-align: center;
    }
  }
`
const Line = styled.div`
  flex: 1 1 auto;
  margin-left: 0px;
  border-bottom: 1px solid #000;
`
const Content = styled.div`
  margin: auto;
  margin-top: 50px;
  width: 80%;
  @media screen and (max-width:500px) {
    width: 90%;
  }
`

export default Related
