/* eslint-disable camelcase */
import renderFull from './master'
import paynetRender from './paynet'
import producst from './products'
import speiRender from './spei'

const renderHtml = (productsList, data, speiParams, paynetParams) => {
  const { total, name, lastname, email, street_number, suburb, postal_code, city, state, orderId, methodPay, shipping } = data

  const productsHtml = producst(productsList)
  const speiHtml = methodPay === 'spei' ? speiRender(speiParams) : ''
  const paynetHtml = methodPay === 'cash' ? paynetRender(paynetParams.reference, paynetParams.barcode_url) : ''

  const fullHtml = renderFull(productsHtml, speiHtml, paynetHtml, total, `${name} ${lastname}`, email, street_number, suburb, postal_code, city, state, orderId, methodPay, shipping)
  return fullHtml
}

export default renderHtml
