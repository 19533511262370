import { db } from './firebase'
import response from '../helpers/response'

const collectionRef = db.collection('discountCodes')

/**
 * create a new discount code
 * @param {Object} args
 * @param {String} args.code
 * @param {String|Number} args.value
 * @param {String|Number} args.minPrice
 * @param {Date} args.deadLine
 */
export const createDiscountCode = async ({ code, value, minPrice, type, deadLine }) => {
  try {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    // check requires
    if (!code) return response.error('El código no es válido')
    if (!type) return response.error('El tipo no es válido')
    if (!value || value === 0 || isNaN(Number.parseFloat(value))) return response.error('El valor no es válido')
    if (!minPrice || isNaN(Number.parseFloat(minPrice))) return response.error('El precio mínimo no es válido')

    // verify is the code available
    const querySnapshot = await collectionRef.where('code', '==', code.toString().toUpperCase()).get()
    if (!querySnapshot.empty) return response.error('El código ya se encuentra registrado')

    // save data
    await collectionRef.add({
      type,
      code: code.toString().toUpperCase(),
      value: Number.parseFloat(value),
      minPrice: Number.parseFloat(minPrice),
      isUsed: false,
      createdAt: new Date(),
      deadLine
    })
    return response.success('Promoción creado correctamente')
  } catch (error) {
    console.error('__error__', error)
    return response.error('Error interno del servidor')
  }
}

/**
 * get all discount codes
 */
export const getAllDiscountCodes = async () => {
  try {
    const querySnapshot = await collectionRef.get()
    const data = querySnapshot.docs.map((snapshot) => {
      const formated = { id: snapshot.id, ...snapshot.data() };
      if (!!formated.deadLine && formated.deadLine.toDate) formated.deadLine = formated.deadLine.toDate()
      return formated;
    })
    return data
  } catch (error) {
    console.error('__error__', error)
    return []
  }
}

/**
 * delete discount code by id
 * @param {String} id - discount code id
 */
export const deleteDiscountCode = async (id) => {
  try {
    await collectionRef.doc(id).delete()
    return response.success('Promoción eliminado correctamente')
  } catch (error) {
    console.error('__error__', error)
    return response.error()
  }
}

/**
 * get discount code data
 * @param {String} id - discount code id
 * @returns
 */
export const getOneDiscountCode = async (id) => {
  try {
    const snapshot = await collectionRef.doc(id).get()
    if (!snapshot.exists) return null
    return {
      id: snapshot.id,
      ...snapshot.data()
    }
  } catch (error) {
    return null
  }
}

export const useDiscountCode = async (id) => {
  try {
    const docRef = collectionRef.doc(id)
    await docRef.update({ isUsed: true, usedAt: new Date() })
    return response.success('Código usado correctamente')
  } catch (error) {
    console.log('__error__', error)
    return response.error()
  }
}

export const getDiscountByCode = async (code = '') => {
  try {
    const querySnapshot = await collectionRef.where('code', '==', code.toString().toUpperCase()).get()
    console.log('querySnapshot', querySnapshot)
    if (querySnapshot.empty) return response.error('Código no válido')
    return response.success('', { payload: querySnapshot.docs[0].data() })
  } catch (error) {
    console.error('__error__', error)
    return response.error('Código no válido')
  }
};

const delevetOutDateDiscountsCodes = async () => {
  console.log('deleting out date discounts code')
  const date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  const querySnapshot = await collectionRef.where('deadLine', '<', date).get();
  for (const document of querySnapshot.docs) {
    await document.ref.delete()
  }
  console.log('deleted docs', querySnapshot.docs.length)
};

delevetOutDateDiscountsCodes()

const discounts = {
  create: createDiscountCode,
  use: useDiscountCode,
  getOne: getOneDiscountCode,
  getAll: getAllDiscountCodes,
  delete: deleteDiscountCode,
  getByCode: getDiscountByCode
}

export default discounts
window.discounts = discounts
