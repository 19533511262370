import React from 'react'
import styled from 'styled-components'
import { Grid, Box } from '@material-ui/core'
import { LockOutlined, CreditCardOutlined, LocalShipping } from '@material-ui/icons'

const Rect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  width: 105px;
  height: 105px;
  margin: auto;
  border-radius: 10px;
  svg {
    font-size: 55px;
    @media screen and (max-width:600px) {
      font-size: 64px;
    }
  }
  @media screen and (max-width:600px) {
    width: 122px;
    height: 122px;
  }
`
const Text = styled.div`
  font-family: 'gotham';
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
  @media screen and (max-width:600px) {
    font-size: 18px;
  }
`
const FooterInfo = props => {
  return (
    <Box pb={5}>
      <Grid container spacing={3} justify='space-around'>
        <Grid item sm={3} xs={12}>
          <Rect>
            <LockOutlined />
          </Rect>
          <Text>
            Compra <br /> 100% segura
          </Text>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Rect>
            <CreditCardOutlined />
          </Rect>
          <Text>
            Pagos con tarjetas, depósitos, OpenPay y PayPal
          </Text>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Rect>
            <LocalShipping />
          </Rect>
          <Text>
            Envíos a <br />todo México
          </Text>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FooterInfo
