import React, { useEffect } from 'react'
import Layout from '../../components/layout_user'
import { useSelector, useDispatch } from 'react-redux'
import { FlexEnd, Summary, Total, Container, ButtonContainer } from './styled'
import Item from './compoenents/item'
import { updateQuantity, RemoveItem } from '../../../flux/cart'
import { setAlert } from '../../../flux/alert'
import Currency from '../../../helpers/currency'
import Button from '../../../components/inputs/Button'
import sumePrice from '../../../helpers/sumPrice'
import Empty from '../../components/empty'
import { taxPorcent } from '../../../config'
import useHeaderheight from '../../../hooks/useHeaderheight'
import Footer from '../../components/footer'
import HistoryStep from '../../components/backdream'
import { ShoppingCartOutlined } from '@material-ui/icons'
import styled from 'styled-components'
import { Box, useMediaQuery } from '@material-ui/core'

const CartComponent = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 27px;
  }
`

const SummaryLimiter = styled(Box)`
  width: 40%;
  @media screen and (max-width:1100px) {
    width: 50%;
  }
  @media screen and (max-width:1000px) {
    width: 100%;
  }
`

const MyCart = props => {
  const myCart = useSelector(state => state.cart)
  const dispatch = useDispatch()
  const subtotal = sumePrice(myCart)
  const shipping = 0
  const tax = Math.ceil((subtotal + shipping) * (taxPorcent / 100))
  const total = subtotal + shipping + tax
  const top = useHeaderheight()
  const isMobile = useMediaQuery('(max-width:1000px)')

  const handleRemoveItem = data => {
    dispatch(
      setAlert({
        title: 'Quitar producto del carrito',
        message: '¿Estas seguro de quitar este producto de tu carrito?',
        action: RemoveItem(data)
      })
    )
  }

  const handleChangeQuantity = (item, quantity) => {
    if (quantity === 0) return false
    dispatch(updateQuantity({
      ...item,
      quantity
    }))
  }

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Layout>
        <div style={{ marginTop: top }}>
          <HistoryStep steps={[{ label: <CartComponent> <ShoppingCartOutlined /> Tu carrito </CartComponent> }]} />
          {!!myCart.length && (
            <Box pt={{ xs: 2, md: 5, lg: 8 }} pb={{ xs: 3, md: 7, lg: 10 }}>
              <Container>
                <Item header />
                {myCart.map(item => (
                  <Item
                    handleRemoveItem={handleRemoveItem}
                    handleChangeQuantity={handleChangeQuantity}
                    key={item.id}
                    {...item}
                  />
                ))}
                <FlexEnd>
                  <SummaryLimiter>
                    <Box>
                      <Summary>
                        <div>
                          <span>Subtotal:</span>
                          ${Currency.formatMoney(subtotal)}
                        </div>
                        <div>
                          <span>Envío:</span>
                          SIN CALCULAR
                        </div>
                        {!!tax && (
                          <div>
                            {/* <span>Impuestos:</span>
                            ${Currency.formatMoney(tax)} */}
                            <span>Todos los precios incluyen IVA</span>
                          </div>
                        )}
                      </Summary>
                    </Box>
                    <Box>
                      <Total>
                        <div>
                          <span>Total:</span>
                          ${Currency.formatMoney(total)}
                        </div>
                      </Total>
                    </Box>
                    {isMobile && (
                      <ButtonContainer>
                        <ButtonSecondary to='/' as={Button} variant='outlined'>Seguir comprando</ButtonSecondary>
                        <ButtonPrimary to='/checkout' as={Button} variant='contained'>Continuar</ButtonPrimary>
                      </ButtonContainer>
                    )}
                    {!isMobile && (
                      <ButtonContainer>
                        <ButtonSecondary to='/' as={Button} variant='outlined'>Seguir comprando</ButtonSecondary>
                        <ButtonPrimary to='/checkout' as={Button} variant='contained'>Continuar</ButtonPrimary>
                      </ButtonContainer>
                    )}
                  </SummaryLimiter>
                </FlexEnd>
              </Container>
            </Box>
          )}
          {!myCart.length && (
            <Container>
              <Empty />
            </Container>
          )}
        </div>
      </Layout>
      <Footer />
    </>
  )
}

const ButtonSecondary = styled(Button)`
  background: #fff!important;
  border: 2px solid #000;
  color: #000;
  transition: all 200ms linear;
  border-radius: 0px!important;
  width:100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 35px!important;
  font-family: 'bebas';
  @media screen and (max-width:1500px) {
    font-size: 25px!important;
  }
  @media screen and (max-width:1000px) {
    font-size: 27px!important;
    margin:0px!important;
    padding-right: 18px!important;
    padding-left: 18px!important;
    width: auto;
    margin-bottom: 10px!important;
  }
  :hover {
    background: #000!important;
    color: #fff;
  }
`
const ButtonPrimary = styled(Button)`
  margin-left: 20px;
  background: #000!important;
  border: 2px solid #000;
  color: #fff;
  transition: all 200ms linear;
  border-radius: 0px!important;
  width:100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 35px!important;
  font-family: 'bebas';
  @media screen and (max-width:1500px) {
    font-size: 25px!important;
  }
  @media screen and (max-width:1000px) {
    font-size: 27px!important;
    margin:0px!important;
    padding-right: 18px!important;
    padding-left: 18px!important;
    width: auto;
    margin-bottom: 10px!important;
  }
  :hover {
    background: var(--user-black-light)!important;
    color: #fff;
    border-color: var(--user-black-light);
  }
`

export default MyCart
