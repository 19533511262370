import { useEffect, useMemo, useState } from "react";

const splitArrayIntoChunks = (elements, limit) => {
  let rest = [...elements];
  return () => {
    const currentChunk = rest.slice(0, limit)
    rest = rest.slice(limit)
    return currentChunk;
  };
}

const useVirtualize = (elements, limit) => {
  const [elementsRendered, setElementsRendered] = useState([]);

  const getNextPage = useMemo(
    () => splitArrayIntoChunks(elements, limit),
    [limit, elements]
  )
  
  const loadNextPage = () => {
    const nextPageElements = getNextPage();
    if (nextPageElements && !!nextPageElements.length) {
      setElementsRendered((prev) => [ ...prev, ...nextPageElements])
    }
  }
  
  function handleScroll () {
    const scrolled = window.scrollY;
    const viewportHeight = window.innerHeight;
    const fullHeight = document.getElementById('root').clientHeight;
    if ((scrolled + viewportHeight + 500) < fullHeight) return false;
    loadNextPage()
  }

  // load initial page
  useEffect(
    () => setElementsRendered(getNextPage()),
    [getNextPage]
  );

  // add scroll listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [getNextPage])

  return elementsRendered;
}

export default useVirtualize;