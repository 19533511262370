import React, { useEffect } from 'react'
import LayoutUser from '../../components/layout_user'
import { Container, Header, BoxComponent, DividerComponent, Text, TextSpecial } from './main'
import useHeaderheight from '../../../hooks/useHeaderheight'
import { Grid, Button, useMediaQuery, Box } from '@material-ui/core'
import { useLocation, Link } from 'react-router-dom'
import Item from './items'
import currency from '../../../helpers/currency'
import styled from 'styled-components'
import { companyName, payDayLimitForStore, payDayLimitForBank } from '../../../config'
import { toString, addDays } from '../../../helpers/date'
import Backdream from '../../components/backdream'
import Footer from '../../components/footer'
import openpay from '../../../assets/openpay/logo.png'

const Success = props => {
  const top = useHeaderheight()
  var { state = {} } = useLocation()
  const isMobile = useMediaQuery('(max-width:800px)')
  var payDayLimit = toString(addDays(state.operation_date, payDayLimitForStore))
  var payDayLimitSpei = toString(addDays(state.operation_date, payDayLimitForBank))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <LayoutUser>
      <div style={{ marginTop: top }}>
        <Backdream steps={[{ label: 'Resumen de tu pedido' }]} />
        <Box pt={{ xs: 5, md: 7, lg: 10 }}>
          <Box pt={3} />
          <Container>
            {(state.methodPay === 'spei' || state.methodPay === 'cash') && (
              <BoxComponent>
                <Header>
                  MUCHAS GRACIAS POR TU COMPRA
                </Header>
                <OpenpayLogo src={openpay} />
              </BoxComponent>
            )}
            {(state.methodPay === 'paypal' || state.methodPay === 'card') && (
              <BoxComponent>
                <Header>
                  MUCHAS GRACIAS POR TU COMPRA, TU PAGO HA SIDO PROCESADO
                </Header>
                {state.methodPay === 'card' && (
                  <OpenpayLogo src={openpay} />
                )}
              </BoxComponent>
            )}
            {(state.methodPay === 'deposito') && (
              <BoxComponent>
                <div style={{ backgroundColor: 'red', color: '#fff', fontSize: isMobile ? '30px' : '44px', fontFamily: 'bebas', fontWeight: 'bold', padding: isMobile ? '4px 15px' : '5px 15px', display: 'inline' }}>
                  IMPORTANTE
                </div>
                <Header style={{ textAlign: 'left' }}>
                  <TextSecondarySecondary $bold> No pierdas tu comprobante de pago. </TextSecondarySecondary>
                  <TextSecondarySecondary> Para poder procesar tu compra es necesario que envies tu voucher al correo </TextSecondarySecondary>
                  <TextSecondarySecondary $bold> ventas@asadoresdelnorte.com</TextSecondarySecondary>
                </Header>
              </BoxComponent>
            )}
            <Box pt={3} />
            <BoxComponent>
              <Grid container justify='space-between'>
                <TextSpecial>
                  RESUMEN DE TU PEDIDO
                </TextSpecial>
              </Grid>
            </BoxComponent>
            <DividerComponent />
            <BoxComponent>
              <Grid container justify='space-between'>
                <Text>
                  TU NÚMERO DE ORDEN
                </Text>
                <Text>{state.orderId}</Text>
              </Grid>
            </BoxComponent>
            <DividerComponent />
            <BoxComponent>
              <Grid container justify='space-between'>
                <Text>
                  DETALLE DE PEDIDO
                </Text>
              </Grid>
            </BoxComponent>
            <BoxComponent>
              <Grid container spacing={2}>
                {!!state.items && state.items.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Item {...item} />
                  </Grid>
                ))}
              </Grid>
            </BoxComponent>
            <DividerComponent />
            <BoxComponent>
              {state.methodPay === 'spei' && (
                <Grid container justify='space-between'>
                  <Text>
                    Fecha limite de pago
                  </Text>
                  <Text>
                    {payDayLimitSpei}
                  </Text>
                </Grid>
              )}
              {state.methodPay === 'cash' && (
                <Grid container justify='space-between'>
                  <Text>
                    Fecha limite de pago
                  </Text>
                  <Text>
                    {payDayLimit}
                  </Text>
                </Grid>
              )}
              {state.methodPay === 'cash' && (
                <Grid container justify='space-between'>
                  <Text>
                    Servicio a pagar
                  </Text>
                  <Text>
                    Paynet
                  </Text>
                </Grid>
              )}
              <Grid container justify='space-between'>
                <Text>
                  Subtotal
                </Text>
                <Text>
                  ${currency.formatMoney(state.subTotal)}
                </Text>
              </Grid>
              {!!state.discount && !!state.discount.discountApplied && (
                <Grid container justify='space-between'>
                  <Text>
                    Descuento
                  </Text>
                  <Text>
                    ${currency.formatMoney(state.discount.discountValue)}
                  </Text>
                </Grid>
              )}
              <Grid container justify='space-between'>
                <Text>
                  Envío
                </Text>
                <Text>
                  {!!state.shippingOnDelivery && (
                    <>Pagar costo de envío al recibir pedido.</>
                  )}
                  {!state.shippingOnDelivery && (
                    <>${currency.formatMoney(state.shipping)}</>
                  )}
                </Text>
              </Grid>
              {!!state.tax && (
                <Grid container justify='space-between'>
                  <Text>
                    IVA
                  </Text>
                  <Text>
                    ${currency.formatMoney(state.tax)}
                  </Text>
                </Grid>
              )}
            </BoxComponent>
            <DividerComponent />
            <BoxComponent>
              <Grid container justify='space-between'>
                <Text>
                  Total
                </Text>
                <Text>
                  ${currency.formatMoney(state.totalPrice)}
                </Text>
              </Grid>
            </BoxComponent>
            <DividerComponent />

            {state.methodPay === 'cash' && (
              <>
                <BoxComponent>
                  <img src={state.payment_method.barcode_url} style={{ margin: 'auto', display: 'block' }} />
                  <div style={{ textAlign: 'center' }}>{state.payment_method.reference}</div>
                  <Box mt={2}>
                    <Text style={{ textAlign: 'center', color: 'gray', fontSize: '1.2em', fontFamily: 'sans serif' }}>En caso de no poder leer el código de barras, escribir el código tal como se muestra</Text>
                  </Box>
                </BoxComponent>
                <DividerComponent />
              </>
            )}

            {state.methodPay === 'deposito' && (
              <BoxComponent>
                <Text>
                  DATOS DE TRANSFERENCIA
                </Text>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ShadowBox>
                      <Grid container spacing={2}>
                        <Grid item sm={3}>
                          <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Nombre:</TextSecondary>
                        </Grid>
                        <Grid item sm={9}>
                          <TextSecondary $bold>NOMBRE APELLIDO APELLIDO</TextSecondary>
                        </Grid>
                      </Grid>
                    </ShadowBox>
                  </Grid>
                  <Grid item xs={12}>
                    <ShadowBox>
                      <Grid container spacing={2}>
                        <Grid item sm={3}>
                          <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Clave:</TextSecondary>
                        </Grid>
                        <Grid item sm={9}>
                          <TextSecondary $bold>000000000000</TextSecondary>
                        </Grid>
                      </Grid>
                    </ShadowBox>
                  </Grid>
                  <Grid item xs={12}>
                    <ShadowBox>
                      <Grid container spacing={2}>
                        <Grid item sm={3}>
                          <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Cuenta:</TextSecondary>
                        </Grid>
                        <Grid item sm={9}>
                          <TextSecondary $bold>
                            12345
                          </TextSecondary>
                        </Grid>
                      </Grid>
                    </ShadowBox>
                  </Grid>
                </Grid>
              </BoxComponent>
            )}

            {state.methodPay === 'spei' && (
              <>
                <BoxComponent>
                  <Text>
                    Transferencia desde BBVA
                  </Text>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ShadowBox>
                        <Grid container spacing={2}>
                          <Grid item sm={3}>
                            <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Número de Convenio CIE:</TextSecondary>
                          </Grid>
                          <Grid item sm={9}>
                            <TextSecondary $bold>{state.payment_method.agreement}</TextSecondary>
                          </Grid>
                        </Grid>
                      </ShadowBox>
                    </Grid>
                    <Grid item xs={12}>
                      <ShadowBox>
                        <Grid container spacing={2}>
                          <Grid item sm={3}>
                            <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Referencia:</TextSecondary>
                          </Grid>
                          <Grid item sm={9}>
                            <TextSecondary $bold>{state.payment_method.name}</TextSecondary>
                          </Grid>
                        </Grid>
                      </ShadowBox>
                    </Grid>
                    <Grid item xs={12}>
                      <ShadowBox>
                        <Grid container spacing={2}>
                          <Grid item sm={3}>
                            <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Importe:</TextSecondary>
                          </Grid>
                          <Grid item sm={9}>
                            <TextSecondary $bold>${currency.formatMoney(state.totalPrice)}</TextSecondary>
                          </Grid>
                        </Grid>
                      </ShadowBox>
                    </Grid>
                  </Grid>
                </BoxComponent>
                <BoxComponent>
                  <Text>
                    Transferencia desde cualquier otro banco
                  </Text>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ShadowBox>
                        <Grid container spacing={2}>
                          <Grid item sm={3}>
                            <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Beneficiario:</TextSecondary>
                          </Grid>
                          <Grid item sm={9}>
                            <TextSecondary $bold>{companyName}</TextSecondary>
                          </Grid>
                        </Grid>
                      </ShadowBox>
                    </Grid>
                    <Grid item xs={12}>
                      <ShadowBox>
                        <Grid container spacing={2}>
                          <Grid item sm={3}>
                            <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Banco Destino:</TextSecondary>
                          </Grid>
                          <Grid item sm={9}>
                            <TextSecondary $bold>BBVA Bancomer</TextSecondary>
                          </Grid>
                        </Grid>
                      </ShadowBox>
                    </Grid>
                    <Grid item xs={12}>
                      <ShadowBox>
                        <Grid container spacing={2}>
                          <Grid item sm={3}>
                            <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>CLABE:</TextSecondary>
                          </Grid>
                          <Grid item sm={9}>
                            <TextSecondary $bold>{state.payment_method.clabe}</TextSecondary>
                          </Grid>
                        </Grid>
                      </ShadowBox>
                    </Grid>
                    <Grid item xs={12}>
                      <ShadowBox>
                        <Grid container spacing={2}>
                          <Grid item sm={3}>
                            <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Concepto de Pago:</TextSecondary>
                          </Grid>
                          <Grid item sm={9}>
                            <TextSecondary $bold>{state.payment_method.name}</TextSecondary>
                          </Grid>
                        </Grid>
                      </ShadowBox>
                    </Grid>
                    <Grid item xs={12}>
                      <ShadowBox>
                        <Grid container spacing={2}>
                          <Grid item sm={3}>
                            <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Referencia:</TextSecondary>
                          </Grid>
                          <Grid item sm={9}>
                            <TextSecondary $bold>{state.payment_method.agreement}</TextSecondary>
                          </Grid>
                        </Grid>
                      </ShadowBox>
                    </Grid>
                  </Grid>

                </BoxComponent>
              </>
            )}

            <BoxComponent>
              <Text>
                TUS DATOS
              </Text>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item sm={3}>
                        <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Nombre:</TextSecondary>
                      </Grid>
                      <Grid item sm={9}>
                        <TextSecondary $bold>{state.user.name}</TextSecondary>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item sm={3}>
                        <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Email:</TextSecondary>
                      </Grid>
                      <Grid item sm={9}>
                        <TextSecondary $bold>{state.user.email}</TextSecondary>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item sm={3}>
                        <TextSecondary style={{ textAlign: 'right', color: 'gray' }} error>Dirección:</TextSecondary>
                      </Grid>
                      <Grid item sm={9}>
                        <TextSecondary $bold>
                          {state.user.street_number}
                          , {state.user.suburb}
                          , {state.user.postal_code}
                          , {state.user.city}
                          , {state.user.state}
                        </TextSecondary>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <TextSecondary style={{ color: 'gray' }}>
                    <span style={{ fontWeight: 'bold' }}>Nota:</span>  Se envió esta misma información al correo proporcionado.
                  </TextSecondary>
                </Grid>
              </Grid>
            </BoxComponent>

            <Box pt={{ xs: 5, lg: 10 }} pb={{ xs: 5, lg: 10 }}>
              <Grid container justify='flex-end'>
                <Link to='/'>
                  <ButtonStyled>
                    VOLVER AL INICIO
                  </ButtonStyled>
                </Link>
              </Grid>
            </Box>
            <BoxComponent />
          </Container>
        </Box>
      </div>
      <Footer />
    </LayoutUser>
  )
}

export default Success

const OpenpayLogo = styled.img`
  width: 150px;
  display: block;
  margin: auto;
  margin-top: 3em;
`

const ShadowBox = styled.div`
  background: #f2f2f2;
  padding: 15px;
  border-radius : 10px;
`
const TextSecondary = styled.div`
  font-size: 21px;
  font-family: 'gotham';
  font-weight: ${props => props.$bold ? 'bold' : 'normal'};
  @media screen and (max-width:800px) {
    font-size: 16px;
  }
`
const ButtonStyled = styled(Button)`
  width: 250px;
  padding: 5px 0px!important;
  border: 1px solid #000!important;
  font-family: 'bebas';
  font-weight: bold;
  font-size: 33px;
  border-radius: 0px!important;
  @media (max-width:800px) {
    font-size: 27px;
    width: 200px;
  }
  :hover {
    background: ${props => props.$gray ? 'gray' : '#000'};
    color: #fff;
    border: 1px solid gray!important;
  }
`

const TextSecondarySecondary = styled.div`
  font-family: 'gotham';
  font-size: 19px;
  text-align: left!important;
  font-weight: ${props => props.$bold ? 'bold' : 'normal'};
  line-height: 1.4em;
  display: inline;
  @media screen and (max-width:800px) {
    font-size: 16px;
  }
  @media screen and (max-width:800px) {
    display: inline;
    text-align: left;
  }
  
`
