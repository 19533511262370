import React, { useEffect, useMemo } from 'react'
import useObjectState from '../../../hooks/useObjectState'
import lastPosition from '../../../helpers/last_position'
import View from './view'
import articlesModule from '../../../modules/article'
import { Redirect, useParams } from 'react-router-dom'

const getCategoryBySlug = (slug) => {
  if (!slug) return null
  if (slug.toString().toLowerCase() === 'asadores') return 'steakhouses'
  if (slug.toString().toLowerCase() === 'accesorios') return 'accesories'
  if (slug.toString().toLowerCase() === 'arma-tu-asador') return 'buildyourgrill'
  return null
}

const Products = () => {
  const params = useParams()
  const category = getCategoryBySlug(params.category)
  const [state, setState] = useObjectState({ loading: false, items: [] })

  const loadNextPage = useMemo(() => articlesModule.getListPaginated(1000, { category, doShowAtBuild: false }), [category])

  const handleFetch = async (reset = false) => {
    setState({ loading: true })
    if (reset) setState({ items: [], loading: true })
    var newItems = await loadNextPage()
    newItems = newItems.filter(item => item.isActive && item.category !== 'buildyourgrill')
    console.log('newItems', newItems)
    reset ? setState({ items: newItems, loading: false }) : setState({ items: [...state.items, ...newItems], loading: false })
  }

  // fetch initial data
  useEffect(() => {
    window.scrollTo(0, 0)
    handleFetch(true)
  }, [loadNextPage])

  // pagination with scrolling
  useEffect(_event => {
    const handleScroll = _event => {
      const isInLastPosition = lastPosition(500)
      if (!state.loading && isInLastPosition) {
        handleFetch()
      }
    }
    window.addEventListener('scroll', handleScroll)
    return event => window.removeEventListener('scroll', handleScroll)
  })

  // redirect to build your grill
  if (category === 'buildyourgrill') {
    return (<Redirect to='/arma-tu-asador' />)
  }

  return (
    <View
      items={state.items}
      loading={state.loading}
      category={category}
    />
  )
}

export default Products
