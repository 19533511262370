import React from 'react'
import styled from 'styled-components'
import currencyParser from '../../../helpers/currency'
import CreateInput from '../../components/input'
import { Alert } from '@material-ui/lab'
import { Fade, Grid, Checkbox, FormControlLabel, Box } from '@material-ui/core'
import propTypes, { number, string, bool } from 'prop-types'
import JoditEditor from 'jodit-react'
import { categories, sections } from '../../../constants'

const GeneralInfo = (props) => {
  if (props.currentStep !== 0) return null

  const handleDescriptionChange = value => {
    props.handleChange({ name: 'description', value: value })
  }

  return (
    <Content>
      <Fade in={props.errors.length !== 0}>
        <GroupForm>
          <Alert severity='error'>Algunos campos son necesarios</Alert>
        </GroupForm>
      </Fade>
      <GroupForm>
        <Label>Nombre del producto</Label>
        <TitleInput
          type='text'
          name='title'
          {...props}
        />
      </GroupForm>
      <GroupForm>
        <Label>SKU</Label>
        <TitleInput
          type='text'
          name='sku'
          {...props}
        />
      </GroupForm>
      <GroupForm>
        <Label>Categoria</Label>
        <TitleInput
          disabled={!!props.idEditing}
          type='select'
          name='category'
          {...props}
          options={categories}
          size='medium'
        />
      </GroupForm>

      {props.category === 'steakhouses' && (
        <>
          <Box pb={2} />
          <GroupForm>
            <Label>Descripción</Label>
            <div style={{ border: '1px solid var(--main-blue)', borderRadius: '5px', overflow: 'hidden' }}>
              <JoditEditor
                config={{ height: 250 }}
                onBlur={handleDescriptionChange}
                value={props.description || ''}
              />
            </div>
          </GroupForm>

          <Box>
            <FormControlLabel
              control={<Checkbox checked={props.availableSignature} onChange={event => props.handleChange({ name: event.target.name, value: event.target.checked })} name='availableSignature' />}
              label={<Label style={{ marginTop: '9px' }}>Grabado disponible</Label>}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={<Checkbox checked={props.multiplePrice} onChange={event => props.handleChange({ name: event.target.name, value: event.target.checked })} name='multiplePrice' />}
              label={<Label style={{ marginTop: '9px' }}>Multple tamaño</Label>}
            />
          </Box>

          {!props.multiplePrice && (
            <GroupForm>
              <GroupSections container spacing={2}>
                <Grid item xs={12}>
                  <Label style={{ marginBottom: '0px' }}>Tamaño y precio</Label>
                </Grid>
                <Grid item xs={12}>
                  <TitleInput
                    value={props.price ? currencyParser.toCurrency(props.price) : ''}
                    onChange={({ name, value }) => props.handleChange({ name, value: currencyParser.toNumber(value) })}
                    type='text'
                    name='price'
                    placeholder='Precio'
                    {...props}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TitleInput
                    placeholder='Tamaño del asador'
                    type='text'
                    name='rotisserie'
                    {...props}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TitleInput
                    placeholder='tamaño de la parrilla'
                    type='text'
                    name='grill'
                    {...props}
                  />
                </Grid>
              </GroupSections>
            </GroupForm>
          )}

          {!!props.multiplePrice && (
            <>
              <GroupForm>
                <GroupSections container spacing={2}>
                  <Grid item xs={12}>
                    <Label style={{ marginBottom: '0px' }}>Mediano</Label>
                  </Grid>
                  <Grid item xs={12}>
                    <TitleInput
                      value={props.medium_price ? currencyParser.toCurrency(props.medium_price) : ''}
                      onChange={({ name, value }) => props.handleChange({ name, value: currencyParser.toNumber(value) })}
                      type='text'
                      name='medium_price'
                      placeholder='Precio'
                      {...props}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TitleInput
                      placeholder='Tamaño del asador'
                      type='text'
                      name='medium_rotisserie'
                      {...props}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TitleInput
                      placeholder='tamaño de la parrilla'
                      type='text'
                      name='medium_grill'
                      {...props}
                    />
                  </Grid>
                </GroupSections>
              </GroupForm>

              <GroupForm>
                <GroupSections container spacing={2}>
                  <Grid item xs={12}>
                    <Label style={{ marginBottom: '0px' }}>Grande</Label>
                  </Grid>
                  <Grid item xs={12}>
                    <TitleInput
                      placeholder='Precio'
                      value={props.big_price ? currencyParser.toCurrency(props.big_price) : ''}
                      onChange={({ name, value }) => props.handleChange({ name, value: currencyParser.toNumber(value) })}
                      type='text'
                      name='big_price'
                      {...props}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TitleInput
                      type='text'
                      name='big_rotisserie'
                      placeholder='Tamaño del asador'
                      {...props}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TitleInput
                      type='text'
                      name='big_grill'
                      placeholder='Tamaño de la parrilla'
                      {...props}
                    />
                  </Grid>
                </GroupSections>
              </GroupForm>
            </>
          )}
        </>
      )}
      {(props.category === 'buildyourgrill' || props.category === 'accesories') && (
        <>
          {props.category === 'buildyourgrill' && (
            <GroupForm>
              <Label>Sección</Label>
              <TitleInput
                {...props}
                type='select'
                name='section'
                options={sections}
                size='medium'
              />
            </GroupForm>
          )}
          {props.category === 'accesories' && (
            <>
              <GroupForm>
                <Label>Descripción</Label>
                <div style={{ border: '1px solid var(--main-blue)', borderRadius: '5px', overflow: 'hidden' }}>
                  <JoditEditor
                    config={{ height: 250 }}
                    onBlur={handleDescriptionChange}
                    value={props.description || ''}
                  />
                </div>
              </GroupForm>
              <GroupForm>
                <FormControlLabel
                  control={<Checkbox checked={props.doShowAtBuild} onChange={event => props.handleChange({ name: event.target.name, value: event.target.checked })} name='doShowAtBuild' />}
                  label={<Label style={{ marginTop: '9px' }}>Mostrar en "arma tu asador"</Label>}
                />
              </GroupForm>
              <GroupForm>
                <FormControlLabel
                  control={<Checkbox checked={props.multiplePrice} onChange={event => props.handleChange({ name: event.target.name, value: event.target.checked })} name='multiplePrice' />}
                  label={<Label style={{ marginTop: '9px' }}>Precios múltiples</Label>}
                />
              </GroupForm>
            </>
          )}

          {(props.category !== 'accesories' || props.multiplePrice) && (
            <>
              <GroupForm>
                <Label>Precio mediano</Label>
                <TitleInput
                  value={props.medium_price ? currencyParser.toCurrency(props.medium_price) : ''}
                  onChange={({ name, value }) => props.handleChange({ name, value: currencyParser.toNumber(value) })}
                  type='text'
                  name='medium_price'
                  {...props}
                />
              </GroupForm>
              <GroupForm>
                <Label>Precio grande</Label>
                <TitleInput
                  value={props.big_price ? currencyParser.toCurrency(props.big_price) : ''}
                  onChange={({ name, value }) => props.handleChange({ name, value: currencyParser.toNumber(value) })}
                  type='text'
                  name='big_price'
                  {...props}
                />
              </GroupForm>
            </>
          )}
          {props.category === 'accesories' && !props.multiplePrice && (
            <GroupForm>
              <Label>Precio</Label>
              <TitleInput
                value={props.price ? currencyParser.toCurrency(props.price) : ''}
                onChange={({ name, value }) => props.handleChange({ name, value: currencyParser.toNumber(value) })}
                type='text'
                name='price'
                {...props}
              />
            </GroupForm>
          )}
        </>
      )}
    </Content>
  )
}

const GroupSections = styled(Grid)`
  padding: 10px;
  border-radius: 10px;
  border: 1px dotted #0e3f67;
  width: 100%;
  max-width: 100%;
  margin: auto;
`

GeneralInfo.propTypes = {
  errors: propTypes.array,
  currentStep: propTypes.number,
  price: propTypes.number,
  handleChange: propTypes.func,
  big_price: number,
  medium_price: number,
  description: string,
  category: string,
  idEditing: string,
  doShowAtBuild: bool,
  availableSignature: bool,
  multiplePrice: bool
}

const Content = styled('div')`
  max-width: 600px;
  margin: auto;
`
const GroupForm = styled('div')`
  margin: auto;
  margin-bottom: 20px;
  width: 100%;
`
const Label = styled('div')`
  color: var(--main-blue-dark);
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1.1em;
`
const TitleInput = styled(CreateInput)`
  width: 100%;
  .MuiTextField-root{
    color: red
  }
`

export default GeneralInfo
