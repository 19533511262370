import React, { useEffect } from 'react'
import styled from 'styled-components'
import ItemOnCart from './item'
import { useDispatch, useSelector } from 'react-redux'
import { RemoveItem } from '../../../../../flux/cart'
import { Divider, Grid, Button, Box } from '@material-ui/core'
import { setAlert } from '../../../../../flux/alert'
import Currency from '../../../../../helpers/currency'
import propTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import renderHtml from '../../../../../templateEmail/index'
import api from '../../../../../api'

const Summary = props => {
  const dispatch = useDispatch()
  const items = useSelector(state => state.cart)
  var idDocument = null
  var orderId = null
  const history = useHistory()

  const handleRedirect = (pathname) => event => {
    history.push(pathname)
  }

  // render button paypal as efect
  useEffect(any => {
    if (props.data.methodPay === 'paypal') {
      try {
        window.document.getElementById('render_button').innerHTML = ''
        window.paypal.Buttons(paypalConfig({ ...props })).render('#render_button')
      } catch (error) {
        console.log(error)
      }
    }
  })

  const handleRemoveItem = item => {
    dispatch(setAlert({
      title: 'Quitar item del carrito',
      message: '¿Estas seguro de quitar este item del carrito?',
      action: RemoveItem(item)
    }))
  }

  const paypalConfig = props => {
    return {
      style: { height: 55 },
      createOrder: async function (data, actions) {
        const { id, path, order_id } = await props.saveOperation()
        idDocument = id
        orderId = order_id
        return actions.order.create({
          purchase_units: [{
            amount: { value: props.totalPrice },
            custom_id: path
          }]
        })
      },
      onApprove: (data, actions) => {
        props.startProcess()
        const status = actions.order.capture().then((details) => {
          const asyncOperation = async () => {
            const { id, payer } = details
            props.successOperation({ orderId, id: idDocument, metadata: { id, payer } })
            const template = renderHtml(items, { ...props.data, total: props.totalPrice, orderId: orderId, shipping: props.shipping })
            api.email.send({
              email: props.data.email,
              subject: 'Confirmación de compra',
              html: template
            })
          }
          asyncOperation()
        })
        return status
      },
      onError: (error) => {
        console.log('error:', error)
        props.endProcess()
        props.failedOperation(idDocument)
      },
      onCancel: () => {
        console.log('error:', 'cancel')
        props.endProcess()
        props.failedOperation(idDocument)
      }
    }
  }

  return (
    <Content>
      {items.map(item => (
        <ItemOnCart key={item.id} {...item} handleRemoveItem={handleRemoveItem} />
      ))}
      <Box pt={2} />
      <Divider style={{ backgroundColor: '#000' }} />
      <Group>
        <FlexBetween>
          <span>Subtotal:</span> $ {Currency.formatMoney(props.subTotal)}
        </FlexBetween>
        {props.data.discountApplied && (
          <FlexBetween>
            <span>Descuento:</span>
            <span>
              $ {Currency.formatMoney(props.data.discountValue)}
            </span>
          </FlexBetween>
        )}
        <FlexBetween>
          {!props.data.shippingOnDelivery && (
            <>
              {(!!props.shipping || props.shipping === 0) && (
                <>
                  <span>Envío:</span> $ {Currency.formatMoney(props.shipping)}
                </>
              )}
              {props.shipping === false && (
                <>
                  <span>Envío:</span> Sin calcular
                </>
              )}
            </>
          )}
          {!!props.data.shippingOnDelivery && (
            <>
              <span>Envío:</span> Pagar costo de envío al recibir pedido.
            </>
          )}
        </FlexBetween>
        {!!props.tax && (
          <FlexBetween>
            <span>IVA:</span> $ {Currency.formatMoney(props.tax)}
          </FlexBetween>
        )}
      </Group>
      <Divider style={{ backgroundColor: '#000' }} />
      <Group>
        <FlexBetween> <span>Total:</span> <span>$ {Currency.formatMoney(props.totalPrice)}<section id='legend'> MXN</section></span> </FlexBetween>
      </Group>

      <Grid container spacing={2}>
        {props.currentStep === 0 && (
          <Grid item xs={12} md={6}>
            <ButtonStyled fullWidth onClick={handleRedirect('/my-cart')} variant='outlined' color='primary'>Volver al carrito</ButtonStyled>
          </Grid>
        )}
        {props.currentStep === 0 && (
          <Grid item xs={12} md={6}>
            <ButtonStyled $gray fullWidth onClick={props.handleNext}>Método de pago</ButtonStyled>
          </Grid>
        )}

        {props.currentStep === 1 && (
          <Grid item xs={12} md={6}>
            <ButtonStyled fullWidth onClick={event => props.goToStep(0)} variant='outlined'>Volver a mis datos</ButtonStyled>
          </Grid>
        )}

        {(props.currentStep === 1 && props.data.methodPay === 'paypal') && (
          <Grid item xs={12} md={6}>
            <ButtonStyled $gray style={{ position: 'relative', overflow: 'hidden', textTransform: 'none' }} fullWidth onClick={props.handleNext}>Pagar<ButtonPaypal id='render_button' /></ButtonStyled>
          </Grid>
        )}
        {(props.currentStep === 1 && props.data.methodPay === 'spei') && (
          <Grid item xs={12} md={6}>
            <ButtonStyled fullWidth onClick={props.handlePay} $gray>Generar orden de pago</ButtonStyled>
          </Grid>
        )}
        {(props.currentStep === 1 && props.data.methodPay === 'deposito') && (
          <Grid item xs={12} md={6}>
            <ButtonStyled fullWidth onClick={props.onPayManual} $gray>Generar orden de pago</ButtonStyled>
          </Grid>
        )}
        {(props.currentStep === 1 && props.data.methodPay === 'cash') && (
          <Grid item xs={12} md={6}>
            <ButtonStyled fullWidth onClick={props.handlePay} $gray>Generar recibo de pago</ButtonStyled>
          </Grid>
        )}
        {(props.currentStep === 1 && props.data.methodPay === 'card') && (
          <Grid item xs={12} md={6}>
            <ButtonStyled fullWidth onClick={props.handleNext} $gray>Siguente</ButtonStyled>
          </Grid>
        )}
        {/* credit card form active */}
        {props.currentStep === 2 && (
          <Grid item xs={12} md={6}>
            <ButtonStyled fullWidth onClick={event => props.goToStep(0)} variant='outlined'>Volver a mis datos</ButtonStyled>
          </Grid>
        )}
        {props.currentStep === 2 && (
          <Grid item xs={12} md={6}>
            <ButtonStyled $gray fullWidth onClick={props.onPayWidthCard}>Pagar</ButtonStyled>
          </Grid>
        )}
      </Grid>
    </Content>
  )
}

Summary.propTypes = {
  handleNext: propTypes.func,
  currentStep: propTypes.number,
  goToStep: propTypes.func,
  steps: propTypes.array,
  handlePay: propTypes.func,
  shipping: propTypes.number,
  totalPrice: propTypes.number,
  subTotal: propTypes.number,
}

const ButtonStyled = styled(Button)`
  width: 100%;
  padding: 5px 0px!important;
  border: 1px solid #000!important;
  font-family: 'bebas';
  font-weight: bold;
  font-size: 35px;
  background-color: ${props => props.$gray ? '#000' : '#fff'};
  color: ${props => props.$gray ? '#fff' : '#000'};
  border-radius: 0px!important;
  @media screen and (max-width:1600px){
    font-size: 28px;
  }
  @media screen and (max-width:1350px){
    font-size: 22px;
  }
  @media screen and (max-width:600px){
    font-size: 25px;
  }
  :hover {
    background: ${props => props.$gray ? 'gray' : '#000'};
    color: #fff;
    border: 1px solid gray!important;
  }
`

const Content = styled.div`
  margin-top: 45px;
  background-color: #fff;
  border-radius: 0px;
  width: 45%;
  @media screen and (max-width:700px) {
    width: 100%;
  }
`
const Group = styled.div`
  padding: 20px 0px;
`
const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'bebas';
  font-weight: bold;
  font-size: 35px;
  #legend {
    font-size:.6em;
    display: inline;
  }
  @media screen and (max-width:1600px){
    font-size: 28px;
  }
  @media screen and (max-width:1350px){
    font-size: 22px;
  }
`

const ButtonPaypal = styled.div`
  background: red;
  width: 300px;
  height: 90%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  opacity: 0.001;
  z-index: 10;
  :hover {
    background: #000;
  }
`

export default Summary
