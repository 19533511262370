import { Box, Button, CircularProgress, Container, useMediaQuery, Switch } from '@material-ui/core'
import { array, func } from 'prop-types'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import Layout from '../../components/layout_admin'
import Item from './item'
import Input from '../../components/input'
import monthsWithoutInterestModel from '../../../modules/paymentsCuota'
import useFetch from '../../../hooks/useFetch'
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../flux/notification'

const ViewDiscountCodes = (props) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:600px)')
  const [query, setQuery] = useState('')
  const [monthWithoutInterest, setMonthWithoutInterest] = useState(false);

  // order by useds
  const itemsParsed = useMemo(() => {
    const useds = []
    const notUseds = []
    props.items.map((currentItem) => {
      if (currentItem.isUsed) useds.push(currentItem)
      if (!currentItem.isUsed) notUseds.push(currentItem)
    })
    console.log('notUseds', notUseds)
    return [...notUseds, ...useds]
  }, [props.items])

  // filter items
  let itemsFiltered = [...itemsParsed]
  if (query) {
    const queryParsed = query.toString().toLowerCase()
    itemsFiltered = itemsFiltered.filter((currentItem) => {
      const codeParsed = currentItem.code.toString().toLowerCase()
      return codeParsed.includes(queryParsed)
    })
  }

  const handleMonthsWithoutInterestChange = async (event) => {
    const checked = event.target.checked
    setMonthWithoutInterest(checked)
    if (checked) {
      const response = await monthsWithoutInterestModel.activateMountsWithoutInterest()
      dispatch(setNotification(response))
    } else {
      const response = await monthsWithoutInterestModel.deactivateMountsWithoutInterest()
      dispatch(setNotification(response))
    }
    await new Promise((resolve) => setTimeout(resolve, 500))
    await handlefetchMonthsWithoutInterest()
  };

  // fetch motnhs without
  const handlefetchMonthsWithoutInterest = async () => {
    const status = await monthsWithoutInterestModel.getIsMonthsWithoutInterestActivated()
    setMonthWithoutInterest(status)
  }
  useFetch(handlefetchMonthsWithoutInterest)

  return (
    <Layout>
      <Wrapper>
        <Container maxWidth='md' disableGutters>
          <Box marginBottom="3em" fontWeight="bold">
            <Switch onChange={handleMonthsWithoutInterestChange} checked={monthWithoutInterest} /> Meses sin intereses
          </Box>
          {!props.loading && !!props.items.length && (
            <Box marginTop='2em' display='flex' alignItems='center' justifyContent='space-between' flexDirection={isMobile ? 'column' : 'row'}>
              <Button variant='outlined' color='primary' onClick={props.onCreate}>Crear nuevo</Button>
              <Box marginTop={isMobile ? '1em' : '0em'} width={isMobile ? '100%' : 'auto'}>
                <Input type='text' placeholder='Buscar' size='small' value={query || ''} onChange={(e) => setQuery(e.value)} fullWidth={isMobile} />
              </Box>
            </Box>
          )}
          {props.loading && (
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='60vh'>
              <CircularProgress />
            </Box>
          )}
          {!props.loading && !itemsFiltered.length && (
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='60vh' flexDirection='column'>
              <Box fontSize='1.2em' textAlign='center' color='var(--main-blue-dark)'>
                {!query ? 'Aún no tienes promociones' : 'No se encontraro resultados'}
              </Box>
              {!query && (
                <Box marginTop='4em'>
                  <Button variant='outlined' color='primary' onClick={props.onCreate}>Crear nuevo</Button>
                </Box>
              )}
            </Box>
          )}
          {!props.loading && !!itemsFiltered.length && (
            <>
              <Box marginTop='2em'>
                {!isMobile && (<Item header />)}
                {itemsFiltered.map((currentItem) => (
                  <Item
                    key={currentItem.id}
                    onDelete={() => props.onDelete(currentItem.id)}
                    {...currentItem}
                  />
                ))}
              </Box>
            </>
          )}
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  @media screen and (max-width:1400px) {
    font-size: .9em;
  }
`

ViewDiscountCodes.propTypes = {
  onCreate: func.isRequired,
  items: array.isRequired,
  loading: array.isRequired,
  onDelete: func.isRequired
}

export default ViewDiscountCodes
