import React from 'react'
import styled from 'styled-components'
import { Facebook, Instagram, YouTube, Pinterest, WhatsApp, Email } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'

const FooterStyled = styled.footer`
  padding: 50px 10px;
  border-top: 1px solid gray;
`
const Body = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width:959px) {
    flex-wrap: wrap;
  }
`
const FotterSection = styled.div`
  font-family: 'bebas';
  font-size: 35px;
  text-transform: uppercase;
  line-height: 1em;
  @media screen and (max-width:1600px) {
    font-size: 30px;
  }
  @media screen and (max-width:1300px) {
    font-size: 25px;
  }
  @media screen and (max-width:959px) {
    margin: auto;
    font-size: 25px;
    text-align: center;
    width: 100%;
    margin-bottom: 35px;
    width: 80%;
    :last-of-type {
      margin-bottom: 0px;
    }
  }
`
const IconButtonStyled = styled(IconButton)`
  svg {
    color: #000;
    @media screen and (max-width:959px) {
      width: 28px;
      height: 28px;
    }
    &:hover {
      color: red;
    }
  }
`

const LinkStyled = styled.a`
  color: inherit;
  transition: all 200ms;
  :hover {
    color: red;
  }
`

const Footer = () => {
  return (
    <FooterStyled>
      <Body>
        <FotterSection>
          <div>ASADORES DEL NORTE {new Date().getFullYear()}®</div>
          <div>
            <LinkStyled as={Link} to='/terms'>
              TÉRMINOS Y CONDICIONES
            </LinkStyled>
          </div>
          <div>
            <LinkStyled as={Link} to='/privacity'>
              AVISO DE PRIVACIDAD
            </LinkStyled>
          </div>
        </FotterSection>
        <FotterSection>
          <div>
            <LinkStyled href='tel:8683521850'>T. 868 - 352 - 1850</LinkStyled>
          </div>
          <LinkStyled href='mailto:ventas@asadoresdelnorte.com'>
            ventas@asadoresdelnorte.com
          </LinkStyled>
        </FotterSection>
        <FotterSection>
          <div>SÍGUENOS EN REDES</div>
          <div>
            <a href='https://www.facebook.com/asadoresdelnorte' target='_blank' rel='noreferrer'>
              <IconButtonStyled size='small'>
                <Facebook />
              </IconButtonStyled>
            </a>
            <a href='https://www.instagram.com/asadoresnorte' target='_blank' rel='noreferrer'>
              <IconButtonStyled size='small'>
                <Instagram />
              </IconButtonStyled>
            </a>
            <a href='https://www.youtube.com/user/AsadoresdelNorte' target='_blank' rel='noreferrer'>
              <IconButtonStyled size='small'>
                <YouTube />
              </IconButtonStyled>
            </a>
            <a href='https://www.pinterest.com.mx/asadoresdelnorte/' target='_blank' rel='noreferrer'>
              <IconButtonStyled size='small'>
                <Pinterest />
              </IconButtonStyled>
            </a>
          </div>
        </FotterSection>
        <FotterSection>
          <div>CONTÁCTANOS</div>
          <div>
            <a href='https://wa.me/message/V5E2YBZXZHLHM1' target='_blank' rel='noreferrer'>
              <IconButtonStyled size='small'>
                <WhatsApp />
              </IconButtonStyled>
            </a>
            <a href='mailto:VENTAS@ASADORESDELNORTE.COM'>
              <IconButtonStyled size='small'>
                <Email />
              </IconButtonStyled>
            </a>
          </div>
        </FotterSection>
      </Body>
    </FooterStyled>
  )
}

export default Footer
