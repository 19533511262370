import { createGlobalStyle } from 'styled-components'

import bold from './Gotham-Bold.otf'
import Medium from './Gotham-Book.otf'
import italik from './Gotham-BookIta.otf'
import bebas from './BebasNeue-Bold.otf'

export default createGlobalStyle`
  @font-face {
    font-family: "gotham";
    src: url(${Medium}) format("opentype");
    font-weight: normal;
  }

  @font-face {
    font-family: "gotham";
    src: url(${bold}) format("opentype");
    font-weight: bold;
  }
  
  @font-face {
    font-family: "gotham";
    src: url(${italik}) format("opentype");
    font-style: italic;
  }
  @font-face {
    font-family: "bebas";
    src: url(${bebas}) format("opentype");
  }
`
